import { createSlice } from '@reduxjs/toolkit';
import { createServiceId, sendNotifycationService } from './action';

const id_service = localStorage.getItem('service');

const initialState = {
	id_service: JSON.parse(id_service) || null,
	loading: false,
	error: false,
};

const serviceWorkerReducer = createSlice({
	name: 'serviceWorker',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(createServiceId.pending, sendNotifycationService.pending, () => {
				return {
					isLoading: true,
				};
			})
			.addCase(createServiceId.rejected, sendNotifycationService.rejected, () => {
				return {
					isLoading: false,
					error: true,
				};
			})
			.addCase(createServiceId.fulfilled, (state, action) => {
				if (action?.payload?.data?.id) {
					localStorage.setItem('service', JSON.stringify(action?.payload?.data?.id));
				}
				return {
					...state,
					id_service: action?.payload?.data?.id,
					isLoading: false,
					error: false,
				};
			});
	},
});

export default serviceWorkerReducer.reducer;
