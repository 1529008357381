import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useSelector } from 'react-redux';
import { formatUSD } from '../../commons/formatCost';
import Analysis from '../manager/sale/workFlowManager/Analysis';

const ViewPerformanceWorkFlow = ({ openModal, handleCloseModal }) => {
	const [dateWorkFlow, setDateWorkFlow] = useState(null);
	const performance = useSelector((state) => state.performanceReducer.employeePerformance);
	const [dataPerformance, setDataPerformance] = useState({});
	const dataParse = (data) => {
		if (data) {
			const rs = {
				job_pending: data.job_pending,
				job_incomplete: data.job_incomplete,
				job_complete: data.job_complete,
				cost_jobs: data.cost_jobs,
				bonus: data.bonus,
				kpi: data.kpi,
			};
			return rs;
		} else {
			return null;
		}
	};
	useEffect(() => {
		if (performance?.data) {
			const result = dataParse(performance?.data);
			if (result) {
				setDataPerformance(result);
			}
		}
	}, [performance]);

	return (
		<Sidebar visible={openModal} position='right' onHide={handleCloseModal} className='create__job relative'>
			<div className=''>
				<div
					className='create__job--title flex align-items-center justify-content-between'
					onClick={() => {
						handleCloseModal(false);
					}}
				>
					<h2 className='flex align-items-center'>
						<img src='/images/mobile/left.svg' className='modal__back' alt='' />
						Năng suất công việc
					</h2>
				</div>
				<div className=' field col-12 md:col-12 grid position_bottom justify-contents-around  flex mr-0  ml-0 mb-0'>
					<div className='field col-12 md:col-12 grid pr-0 mb-0'>
						<div className='field col-12 md:col-12 mb-2'>
							<span className='original__link mb-1 block' style={{ fontSize: '13px' }}>
								Tìm kiếm theo ngày
							</span>
							<Calendar
								id=''
								className='w-full mb-2'
								value={dateWorkFlow}
								onChange={(e) => setDateWorkFlow(e.value)}
								selectionMode='range'
								placeholder='dd/mm/yyyy - dd/mm/yyyy'
							/>
						</div>
					</div>
					<div className='field col-12 md:col-12 grid pr-0 mb-0'>
						<div className='field col-12 md:col-12 '>
							<div className='data__performance job_incomplete flex '>
								<span>{dataPerformance?.job_incomplete}</span>
							</div>
						</div>
					</div>
					<div className='field col-12 md:col-12 grid pr-0 mb-0'>
						<div className='field col-12 md:col-12'>
							<div className='job_pending data__performance flex '>
								<span>{dataPerformance?.job_pending}</span>
							</div>
						</div>
					</div>
					<div className='field col-12 md:col-12 grid pr-0 mb-0'>
						<div className='field col-12 md:col-12'>
							<div className='job_complete data__performance flex '>
								<span>{dataPerformance?.job_complete}</span>
							</div>
						</div>
					</div>
					<div className='field col-12 md:col-12 grid pr-0 mb-0'>
						<div className='field col-12 md:col-12'>
							<div className='job_cost data__performance flex '>
								<span>{formatUSD(dataPerformance?.cost_jobs)}</span>
							</div>
						</div>
					</div>
					<div className='field col-12 md:col-12 grid pr-0 mb-0'>
						<div className='field col-12 md:col-12'>
							<div className='job_bonus data__performance flex '>
								<span>{formatUSD(dataPerformance?.bonus)}</span>
							</div>
						</div>
					</div>
					<div className='table__analysis field col-12 md:col-12 mt-0  mb-0 pb-0'>
						<Analysis />
					</div>
					<div className='btn_modal field col-12 md:col-12 grid position_bottom mt-4 ml-0 mr-0'>
						<div className='field col-12 md:col-12 pl-0'>
							<span className='p-float-label'>
								<Button
									label='Hủy bỏ'
									className='p-button-outlined cancel--btn'
									onClick={() => {
										handleCloseModal(false);
									}}
									type='button'
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</Sidebar>
	);
};

export default ViewPerformanceWorkFlow;
