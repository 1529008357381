import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { JobRules } from '../../constants';
import { formatUSD } from '../../commons/formatCost';

const ViewPerformJobOverView = ({ openModal, handleCloseModal, data }) => {
	const dataIsComplete = data?.filter((item) => {
		return item?.status_jobs === JobRules.STATUS_JOBS.COMPLETE;
	});
	const dataIsPending = data?.filter((item) => {
		return item?.status_jobs === JobRules.STATUS_JOBS.PENDING;
	});
	const [totalCost, setTotalCost] = useState(0);
	const [totalPay, setTotalPay] = useState(0);

	useEffect(() => {
		let total_cost = 0;
		for (let i = 0; i < data?.length; i++) {
			total_cost += parseInt(data[i]?.total_cost);
		}
		setTotalCost(total_cost);

		let total_pay = 0;
		for (let i = 0; i < dataIsComplete?.length; i++) {
			total_pay += parseInt(dataIsComplete[i]?.total_cost);
		}
		setTotalPay(total_pay);
	}, [data, dataIsComplete]);

	return (
		<Sidebar visible={openModal} position='right' onHide={handleCloseModal} className='create__job relative'>
			<div>
				<div
					className='create__job--title flex align-items-center justify-content-between'
					onClick={() => {
						handleCloseModal(false);
					}}
				>
					<h2 className='flex align-items-center'>
						<img src='/images/mobile/left.svg' className='modal__back' alt='' />
						Năng suất công việc
					</h2>
				</div>
				<div className='flex flex-column align-items-center justify-content-between'>
					<div className='total__table grid header_total_controller mt-5 p-2'>
						<div className='col-12 md:col-12 total__block w-full'>
							<div>
								<p className='total__title'>Tổng công việc</p>
								<p className='total__count'>{data?.length}</p>
							</div>
						</div>
						<div className='col-12 md:col-12 total__block w-full'>
							<div>
								<p className='total__title'>Đã hoàn thành</p>
								<p className='total__count'>{dataIsComplete?.length}</p>
							</div>
						</div>
						<div className='col-12 md:col-12 total__block w-full'>
							<div>
								<p className='total__title'>Đang xử lý</p>
								<p className='total__count'>{dataIsPending?.length}</p>
							</div>
						</div>
						<div className='col-12 md:col-12 total__block w-full'>
							<div>
								<p className='total__title'>Tổng số tiền</p>
								<p className='total__count'>{formatUSD(totalCost)}</p>
							</div>
						</div>
						<div className='col-12 md:col-12 total__block w-full'>
							<div>
								<p className='total__title'>Tổng số tiền (Job đã hoàn thành )</p>
								<p className='total__count'>{formatUSD(totalPay)}</p>
							</div>
						</div>
					</div>
					<div className='btn_modal field col-12 md:col-12 grid position_bottom mt-7 ml-0 mr-0'>
						<div className='field col-12 md:col-12 pl-0'>
							<span className='p-float-label'>
								<Button
									label='Hủy bỏ'
									className='p-button-outlined cancel--btn'
									onClick={() => {
										handleCloseModal(false);
									}}
									type='button'
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</Sidebar>
	);
};

export default ViewPerformJobOverView;
