import React, { useEffect } from 'react';

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { addCustomerRequest } from '../../redux/sale/action';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRules, LINK_REGEX } from '../../constants';
import { getCountries } from '../../commons/getCountry';
import { AutoComplete } from 'primereact/autocomplete';

import { EMAIL_REGEX } from '../../constants';
import { setIsOpenModalCreateCustomer } from '../../redux/modal/modalSlice';
import { overlay } from '../../commons/overlay';
import { searchDropdown } from '../../commons/searchDropDown';
import { validate } from '../../commons/support';

const CreateCustomer = () => {
	const isOpenCreateCustomer = useSelector((state) => state.modal.isOpenModalCreateCustomer);

	const dispatch = useDispatch();
	const defaultValues = {
		fullname: '',
		email: '',
		country: '',
		city: '',
		infor_reminder: '',
		link: '',
	};
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
	} = useForm({ defaultValues });
	const user = useSelector((state) => state.auth?.user);
	const [countries, setCountries] = React.useState([]);
	const [cities, setCities] = React.useState([]);
	const [filteredCountry, setFilteredCountry] = React.useState(null);
	const [filteredCity, setFilteredCity] = React.useState(null);
	const customer = useSelector((state) => state.sale.customer);

	useEffect(() => {
		if (isOpenCreateCustomer) {
			const getcountries = new getCountries();
			getcountries.get().then((data) => setCountries(data));
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [isOpenCreateCustomer]);

	useEffect(() => {
		if (customer?.data && !customer?.error) {
			dispatch(setIsOpenModalCreateCustomer(false));
			reset();
		}
	}, [customer, reset, dispatch]);

	const onSubmit = (data) => {
		if (Object.keys(errors).length === 0) {
			data.create_by = user?.data?.id_system;
			data.status = CustomerRules.STATUS.PENDING;
			const rs = {};
			Object.keys(data).map((item) => {
				rs[item] = data[item].trim();
			});
			dispatch(addCustomerRequest(rs));
		}
	};

	const handleChangeCountry = (e, field) => {
		setValue('city', '');
		field.onChange(e.value);
		if (countries[e.value]) {
			setCities(countries[e.value]);
		}
	};

	const handleCloseModal = React.useCallback(() => {
		dispatch(setIsOpenModalCreateCustomer(false));
		reset();
	}, [dispatch, reset]);

	return (
		<>
			<Sidebar visible={isOpenCreateCustomer} position='right' onHide={handleCloseModal} className='create__job'>
				<div className='creat__job'>
					<div
						className='create__job--title flex align-items-center justify-content-between'
						onClick={() => {
							dispatch(setIsOpenModalCreateCustomer(false));
							reset();
						}}
					>
						<h2 className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							Tạo khách hàng mới
						</h2>
						<div
							className='flex align-items-center create__job--remove-job cursor-pointer'
							style={{ color: 'white' }}
							onClick={handleCloseModal}
						>
							x
						</div>
					</div>
					<form
						className=' grid modal__creat--job'
						autoComplete='off'
						onSubmit={handleSubmit(onSubmit)}
						onKeyDown={(e) => {
							return e.key !== 'Enter';
						}}
					>
						<div className='field col-12 md:col-12 grid pr-0'>
							<div className='field col-12 md:col-6'>
								<span htmlFor='autocomplete'>
									<span className='warning'>*</span>Tên khách hàng
								</span>
								<Controller
									name='fullname'
									control={control}
									rules={{ required: 'Vui lòng nhập tên khách hàng.', minLength: 3, validate }}
									render={({ field, fieldState }) => (
										<InputText
											id={field.name}
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })}
											placeholder='Nhập tên khách hàng'
										/>
									)}
								/>
								{errors?.fullname && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.fullname.message}
									</span>
								)}
								{errors?.fullname?.type === 'minLength' && (
									<span className='warning' style={{ fontSize: '12px' }}>
										Tên ít nhất 6 ký tự
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span>
									<span className='warning'>*</span>Biệt danh
								</span>
								<span className=''>
									<Controller
										name='infor_reminder'
										control={control}
										rules={{ required: 'Vui lòng nhập biệt danh.', validate }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='disabled'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập tên biệt danh'
											/>
										)}
									/>
								</span>
								{errors?.infor_reminder && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.infor_reminder.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='original__link'>
									<span className='warning'>*</span>Email
								</span>
								<Controller
									name='email'
									control={control}
									rules={{ required: 'Vui lòng nhập email.', pattern: { value: EMAIL_REGEX } }}
									render={({ field, fieldState }) => (
										<InputText
											id={field.name}
											{...field}
											className={classNames({ 'p-invalid': fieldState.invalid })}
											placeholder='Nhập email'
										/>
									)}
								/>
								{errors?.email && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.email.message}
									</span>
								)}
								{errors?.email?.type === 'pattern' && (
									<span className='warning' style={{ fontSize: '12px' }}>
										Email không hợp lệ
									</span>
								)}
							</div>
							<div className='field col-6 md:col-6'>
								<span>
									<span className='warning'>*</span>Link
								</span>
								<span className=''>
									<Controller
										name='link'
										control={control}
										rules={{
											required: 'Vui lòng nhập link khách hàng.',
											validate,
											pattern: { value: LINK_REGEX, message: 'Link khách hàng không hợp lệ.' },
										}}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='disabled'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập link khách hàng'
											/>
										)}
									/>
								</span>
								{errors?.link && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.link.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12'>
								<span htmlFor='original__link'>
									<span className='warning'>*</span>Quốc gia
								</span>
								<Controller
									name='country'
									control={control}
									rules={{ required: 'Vui lòng nhập quốc gia.', validate }}
									render={({ field }) => (
										<AutoComplete
											suggestions={filteredCountry}
											completeMethod={(e) => searchDropdown(e, countries, setFilteredCountry)}
											field=''
											aria-label='Countries'
											id={field.name}
											value={field.value}
											onChange={(e) => handleChangeCountry(e, field)}
											className={errors?.country && 'p-invalid'}
											dropdownAriaLabel='Select name'
											placeholder='Nhập tên quốc gia'
										/>
									)}
								/>
								{errors?.country && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.country.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12'>
								<span htmlFor='cost'>
									<span className='warning'>*</span>Thành phố
								</span>
								{
									<Controller
										name='city'
										control={control}
										rules={{ required: 'Vui lòng nhập thành phố.', validate }}
										render={({ field }) => (
											<AutoComplete
												suggestions={filteredCity}
												completeMethod={(e) => searchDropdown(e, cities, setFilteredCity)}
												field=''
												aria-label='Cities'
												id={field.name}
												value={field.value}
												onChange={(e) => {
													field.onChange(e.value);
												}}
												className={errors?.city && 'p-invalid'}
												dropdownAriaLabel='Select name'
												placeholder='Nhập tên thành phố'
											/>
										)}
									/>
								}
								{errors?.city && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.city.message}
									</span>
								)}
							</div>
						</div>

						<div className='btn_modal field col-12 md:col-12 grid position_bottom'>
							<div className='field col-12 md:col-6'>
								<span className='p-float-label'>
									<Button
										label='Hủy bỏ'
										className='p-button-outlined cancel--btn'
										onClick={() => {
											dispatch(setIsOpenModalCreateCustomer(false));
											reset();
										}}
										type='button'
									/>
								</span>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span className='p-float-label'>
									<Button
										label='Tạo mới'
										className='p-button-outlined p-button-secondary confirm--btn'
										type='submit'
									/>
								</span>
							</div>
						</div>
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default CreateCustomer;
