import { createSlice } from '@reduxjs/toolkit';

import { getEmployeePerformance, jobsStaffPerformance } from './action';

const initialState = {
	employeePerformance: {
		loading: false,
		data: null,
		error: false,
	},

	jobsStaffPerformance: {
		loading: false,
		data: null,
		error: false,
	},
};
const employeePerformanceReducer = createSlice({
	name: 'employeePerformance',
	initialState,
	reducers: {
		resetKpis: (state) => {
			state.employeePerformance.data = null;
		},
	},
	extraReducers: {
		[getEmployeePerformance.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					employeePerformance: {
						loading: true,
					},
				}
			);
		},
		[getEmployeePerformance.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					employeePerformance: {
						loading: false,
						data: action.payload,
						error: false,
					},
				}
			);
		},
		[getEmployeePerformance.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					employeePerformance: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},

		[jobsStaffPerformance.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					jobsStaffPerformance: {
						loading: true,
					},
				}
			);
		},
		[jobsStaffPerformance.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					jobsStaffPerformance: {
						loading: false,
						data: action.payload,
						error: false,
					},
				}
			);
		},
		[jobsStaffPerformance.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					jobsStaffPerformance: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},
	},
});

export const { resetKpis } = employeePerformanceReducer.actions;

export default employeePerformanceReducer.reducer;
