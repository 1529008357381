import axios from 'axios';
import { auth } from './storage';
import jwt_decode from 'jwt-decode';

const DOMAIN_API = process.env.REACT_APP_DEV_API;

const apiService = () => {
	const instance = axios.create({
		baseURL: DOMAIN_API,
		headers: {
			'Content-Disposition': 'multipart/form-data',
			'Content-Type': 'application/x-www-form-urlencoded',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type',
		},
	});
	instance.interceptors.request.use(
		async (config) => {
			const token = auth.get();
			if (token) {
				let default_token = token;
				const decodedToken = jwt_decode(default_token.trim());
				let isRefreshToken = false;
				const refreshToken = async (formData) => {
					try {
						const res = await axios.post(`${DOMAIN_API}/auth/token`, formData, {
							headers: { Authorization: `1TouchAuthorization ${token}` },
						});
						return res.data;
					} catch (err) {
						return err;
					}
				};
				let date = new Date();
				if (decodedToken.exp * 1000 <= date.getTime() && !isRefreshToken) {
					try {
						const formData = {
							_id_activity: decodedToken._id,
						};
						isRefreshToken = true;
						if (isRefreshToken) {
							const refreshtoken = await refreshToken(formData);
							if (refreshtoken?.data?.access_token) {
								isRefreshToken = false;
								default_token = refreshtoken?.data?.access_token;
								localStorage.setItem('access_token', default_token);
							}
						} else {
							localStorage.clear();
						}
					} catch (error) {
						console.log(error);
					}
				}
				config.headers['Authorization'] = '1TouchAuthorization ' + default_token;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	instance.interceptors.response.use(
		(res) => res,
		async (err) => {
			const originalConfig = err.config;
			originalConfig._retry = true;
			return Promise.reject(err);
		}
	);

	return instance;
};

const request = apiService();

export default class ApiService {
	constructor() {
		axios.defaults.baseURL = `${DOMAIN_API}`;
	}
	async get(uri, options = {}) {
		return new Promise((resolve, reject) => {
			request
				.get(`/${uri}`, {
					params: options,
				})
				.then((resp) => {
					resolve(resp?.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	post(uri, data = {}, moreConfigs = {}) {
		return new Promise((resolve, reject) => {
			request
				.post(`/${uri}`, data, moreConfigs)
				.then((resp) => {
					resolve(resp.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	put(uri, data = {}, moreConfigs = {}) {
		return new Promise((resolve, reject) => {
			request
				.put(`/${uri}`, data, moreConfigs)
				.then((resp) => {
					resolve(resp.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	delete(uri, data = {}) {
		return new Promise((resolve, reject) => {
			request
				.delete(`/${uri}`, {
					data,
				})
				.then((resp) => {
					resolve(resp.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
