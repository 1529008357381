import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { EMAIL_REGEX } from '../../constants';
import { editCustomerRequest, deleteCustomerRequest } from '../../redux/sale/action';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../commons/getCountry';
import { searchDropdown } from '../../commons/searchDropDown';
import copy from 'copy-to-clipboard';
import { setIsOpenModalInformationCustomer } from '../../redux/modal/modalSlice';
import { overlay } from '../../commons/overlay';
import { resetEditCustomer } from '../../redux/sale/saleSlice';
import { successToast } from '../../commons/toast';
import Modal from '../dialog/dialog';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import { validate } from '../../commons/support';
import { LINK_REGEX } from '../../constants';

const InformationCustomer = () => {
	const [openModal, setOpenModal] = useState(false);

	const rowdata = useSelector((state) => state.modal.dataModalInformationCustomer);
	const putCustomer = useSelector((state) => state.sale.editcustomer);
	const isOpenInformationCustomer = useSelector((state) => state.modal.isOpenModalInformationCustomer);
	const [cities, setCities] = React.useState(null);
	const [filteredCity, setFilteredCity] = React.useState(null);
	const [countries, setCountries] = React.useState(null);
	const [filteredCountry, setFilteredCountry] = React.useState(null);
	const deleteCustomer = useSelector((state) => state.sale.deletecustomer);

	const dispatch = useDispatch();
	const {
		control,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const resetModal = React.useCallback(
		(isResetCustomer = false) => {
			dispatch(setIsOpenModalInformationCustomer(false));
			reset();
			if (isResetCustomer) {
				setTimeout(() => {
					dispatch(resetEditCustomer());
				}, 500);
			}
		},
		[dispatch, reset]
	);

	useEffect(() => {
		if (deleteCustomer?.data && !deleteCustomer?.error) {
			resetModal(true);
		}
	}, [deleteCustomer, dispatch, resetModal]);

	useEffect(() => {
		if (isOpenInformationCustomer) {
			const getcountries = new getCountries();
			getcountries.get().then((data) => setCountries(data));
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [isOpenInformationCustomer]);

	useEffect(() => {
		if (putCustomer?.data && !putCustomer?.error) {
			resetModal();
		}
	}, [putCustomer, dispatch, resetModal]);

	useEffect(() => {
		if (deleteCustomer?.data && !deleteCustomer?.error) {
			resetModal();
		}
	}, [deleteCustomer, dispatch, resetModal]);

	useEffect(() => {
		if (rowdata?.data) {
			setValue('fullname', rowdata?.data?.fullname);
			setValue('country', rowdata?.data?.information?.address?.country);
			setValue('city', rowdata?.data?.information?.address?.city);
			setValue('email', rowdata?.data?.information?.email);
			setValue('infor_reminder', rowdata?.data?.infor_reminder);
			setValue('link', rowdata?.data?.link);
		}
	}, [rowdata, setValue]);

	const handleChangeCountry = (e, field) => {
		setValue('city', '');
		field.onChange(e.value);
		if (countries[e.value]) {
			setCities(countries[e.value]);
		}
	};
	const onSubmit = (data) => {
		if (data) {
			const obj = {};
			const result = {
				fullname: data.fullname.trim(),
				information: {
					email: data.email.trim(),
					address: {
						country: data.country.trim(),
						city: data.city.trim(),
					},
				},
				infor_reminder: data?.infor_reminder.trim(),
				link: data?.link.trim(),
				create_by: rowdata?.data?.create_by,
				id_system: rowdata?.data?.id_system,
				list_jobs: rowdata?.data?.list_jobs,
				_create_at: rowdata?.data?._create_at,
			};
			for (let item in result) {
				if (result[item] !== rowdata?.data[item]) {
					obj[item] = result[item];
				}

				if (item === 'information') {
					for (let item2 in result.information) {
						const child2 = result[item][item2];
						if (child2 !== rowdata?.data[item][item2]) {
							obj[item2] = child2;
						}
					}
				}
			}

			if (Object?.keys(obj).length > 0) {
				const formData = {
					data: obj,
					result: result,
					index: rowdata?.data?.index - 1,
				};
				dispatch(editCustomerRequest(formData));
			}
		}
	};

	const handleDeleteCustomer = () => {
		const formdata = {};
		formdata.id = rowdata?.data?.id_system;
		formdata.index = rowdata?.data?.index - 1;
		dispatch(deleteCustomerRequest(formdata));
		setOpenModal(false);
	};

	const copyToClipboard = (type) => {
		if (type === 'id_system') {
			copy(rowdata?.data?.id_system);
		}
		if (type === 'create_by') {
			copy(rowdata?.data?.create_by);
		}
		if (type === 'link') {
			copy(rowdata?.data?.link);
		}
		successToast(TOAST_MESSAGE.COPY);
	};

	return (
		<>
			<Modal
				title='Xoá khách hàng'
				content='Bạn có đồng ý xóa khách hàng này không?'
				isOpen={openModal}
				setIsOpen={setOpenModal}
				nameCancel='Hủy bỏ'
				nameConfirm='Đồng ý'
				handleSubmit={handleDeleteCustomer}
			/>
			<Sidebar visible={isOpenInformationCustomer} position='right' onHide={resetModal} className='create__job'>
				<div className='creat__job'>
					<div className='create__job--title flex justify-content-between' onClick={resetModal}>
						<h2 className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							Thông tin khách hàng
						</h2>
						{!rowdata?.error && (
							<img
								src='images/trash.svg'
								alt=''
								className='image__trash'
								onClick={() => setOpenModal(true)}
							/>
						)}
					</div>
					<form className=' grid modal__creat--job no_flex' onSubmit={handleSubmit(onSubmit)}>
						{rowdata?.error ? (
							<span className='notfound'>Thông tin khách hàng không tồn tại</span>
						) : (
							<div className='field col-12 md:col-12 grid pr-0'>
								<div className='field col-12 md:col-6 '>
									<span htmlFor='autocomplete'>
										<span className='warning'>*</span>Tên khách hàng
									</span>
									<span className='p-float-label '>
										<InputText
											defaultValue={rowdata?.data?.fullname}
											onChange={(e) => setValue('fullname', e.target.value)}
											{...register('fullname', {
												required: 'Vui lòng nhập tên khách hàng.',
												validate,
												minLength: 3,
											})}
											className={errors?.fullname && 'p-invalid'}
										/>
									</span>
									{errors?.fullname?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.fullname?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6 '>
									<span htmlFor='autocomplete'>
										<span className='warning'>*</span>Biệt danh
									</span>
									<span className='p-float-label '>
										<InputText
											defaultValue={rowdata?.data?.infor_reminder}
											onChange={(e) => setValue('infor_reminder', e.target.value)}
											{...register('infor_reminder', {
												required: 'Vui lòng nhập biệt danh khách hàng.',
												validate,
											})}
											className={errors?.infor_reminder && 'p-invalid'}
										/>
									</span>
									{errors?.infor_reminder?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.infor_reminder?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6'>
									<span htmlFor='original__link'>
										<span className='warning'>*</span>Email
									</span>
									<span className='p-float-label '>
										<InputText
											defaultValue={rowdata?.data?.information?.email}
											onChange={(e) => setValue('email', e.target.value)}
											{...register('email', {
												required: 'Vui lòng nhập email.',
												pattern: { value: EMAIL_REGEX, message: 'Ký tự không hợp lệ' },
											})}
											className={errors?.email && 'p-invalid'}
										/>
									</span>
									{errors?.email && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.email?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6'>
									<span htmlFor='employees'>
										<span className='warning'>*</span>Link
									</span>
									<span className='p-float-label  relative'>
										<InputText
											defaultValue={rowdata?.data?.link}
											onChange={(e) => setValue('link', e.target.value)}
											{...register('link', {
												required: 'Vui lòng nhập link liên kết.',
												validate,
												pattern: {
													value: LINK_REGEX,
													message: 'Link khách hàng không hợp lệ.',
												},
											})}
											className={errors?.link && 'p-invalid'}
										/>
										<img
											src='images/copy.svg'
											alt='link'
											className='absolute copy__icon copy__name'
											label='Bottom Right'
											onClick={(e) => copyToClipboard(e.target.alt)}
											style={{ right: '5px', top: '0px' }}
										/>
									</span>
									{errors?.link?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.link?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6'>
									<span htmlFor='original__link'>
										<span className='warning'>*</span>Quốc gia
									</span>
									<Controller
										name='country'
										control={control}
										rules={{ required: 'Vui lòng nhập tên quốc gia.', validate }}
										render={({ field }) => (
											<AutoComplete
												suggestions={filteredCountry}
												completeMethod={(e) => searchDropdown(e, countries, setFilteredCountry)}
												field=''
												aria-label='Countries'
												id={field.name}
												value={field.value}
												onChange={(e) => handleChangeCountry(e, field)}
												className={errors?.country && 'p-invalid'}
												dropdownAriaLabel='Select name'
												placeholder='Quốc gia'
											/>
										)}
									/>
									{errors?.country?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.country?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6'>
									<span htmlFor='cost'>
										<span className='warning'>*</span>Thành phố
									</span>
									<span className='p-float-label '>
										<Controller
											name='city'
											control={control}
											rules={{ required: 'Vui lòng nhập tên thành phố.', validate }}
											render={({ field }) => (
												<AutoComplete
													suggestions={filteredCity}
													completeMethod={(e) => searchDropdown(e, cities, setFilteredCity)}
													field=''
													aria-label='Cities'
													id={field.name}
													value={field.value}
													onChange={(e) => {
														field.onChange(e.value);
													}}
													className={errors?.city && 'p-invalid'}
													dropdownAriaLabel='Select name'
													placeholder='Thành phố'
												/>
											)}
										/>
									</span>
									{errors?.city?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.city?.message}
										</span>
									)}
								</div>
							</div>
						)}
						<div className='btn_modal field col-12 md:col-12 grid position_bottom'>
							<div className='field col-6 md:col-6'>
								<span className='p-float-label'>
									<Button
										label='Hủy bỏ'
										className='p-button-outlined cancel--btn'
										type='button'
										onClick={resetModal}
									/>
								</span>
							</div>
							{!rowdata?.error && (
								<div className='field col-6 md:col-6 pr-0'>
									<span className='p-float-label'>
										<Button
											label='Cập nhật'
											className='p-button-outlined p-button-secondary confirm--btn'
											type='submit'
										/>
									</span>
								</div>
							)}
						</div>
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default InformationCustomer;
