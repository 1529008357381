import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { resetPassword } from '../../redux/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import { URL_ROUTER } from '../../routes/routes';
import { DEFAULT_MESSAGES } from '../../commons/Messages/default.message';
import Index from './Index';

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState(['', '']);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({});
	const queryParams = new URLSearchParams(window.location.search);
	const resetpass = useSelector((state) => state.auth?.resetpassword);
	const [haveSeenPwd, setHaveSeenPwd] = useState(false);
	const [haveSeenRePwd, setHaveSeenRePwd] = useState(false);
	const DEFAULT_MIN_LENGTH_PASSWORD = 8;

	useEffect(() => {
		if (resetpass?.error) {
			setErrorMessage([false, resetpass?.data]);
		}
		if (resetpass?.data?.status) {
			setErrorMessage([true, resetpass?.data?.data?.messager]);
			setTimeout(() => {
				navigate(URL_ROUTER.LOGIN);
			}, 10000);
		}
	}, [resetpass, navigate]);

	const onSubmit = (data) => {
		let id = queryParams.get('id');
		let token = queryParams.get('token');
		if (data?.password !== data?.repassword) {
			setErrorMessage([false, DEFAULT_MESSAGES.ERRORS.CHANGE_PASS_ERR]);
		} else {
			if (data && id && token && !resetpass?.data) {
				const dataPost = {
					password: data?.password,
					id: id,
					token: token,
				};
				dispatch(resetPassword(dataPost));
			}
		}
	};

	return (
		<Index title={DEFAULT_MESSAGES.CONTENT}>
			{errorMessage[0] ? (
				<>
					<div className='reset__pasword'>
						<img src='images/reset_pass_success.svg' alt='' className='pb-3' />
						<span>{DEFAULT_MESSAGES.TITLE_CHILD.CHANGE_PASS_SUCCESS}</span>
						<p className='form__message pb-3'>{errorMessage[1]}</p>
					</div>
					<div className='reset__pasword--btn'>
						<button type='submit' onClick={() => navigate(URL_ROUTER.LOGIN)}>
							{DEFAULT_MESSAGES.BUTTON.REDIRECT_LOGIN}
						</button>
					</div>
				</>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} className='forgot__form'>
					<div className='form__password'>
						<p>{DEFAULT_MESSAGES.TITLE_CHILD.NEW_PASSWORD}</p>
						<div className={' form__input ' + (resetpass?.data ? 'form__input__disabled' : '')}>
							<input
								type={haveSeenPwd ? 'text' : 'password'}
								placeholder={DEFAULT_MESSAGES.TITLE_CHILD.NEW_PASSWORD}
								autoComplete='off'
								{...register('password', {
									required: {
										message: DEFAULT_MESSAGES.ERRORS.NEW_PASSWORD.REQUIRED,
									},
									minLength: {
										value: DEFAULT_MIN_LENGTH_PASSWORD,
										message: DEFAULT_MESSAGES.ERRORS.NEW_PASSWORD.LENGTH.replace(
											'$number',
											DEFAULT_MIN_LENGTH_PASSWORD
										),
									},
								})}
								onChange={(e) => {
									setValue('pasword', e.target.value);
									setErrorMessage(['', '']);
								}}
								disabled={resetpass?.data && true}
							/>
							<img
								className='show__password'
								src={haveSeenPwd ? '../../images/closed_eye.png' : '../../images/eye.png'}
								alt=''
								onClick={() => setHaveSeenPwd(!haveSeenPwd)}
							/>
						</div>
						<span className='form__error'>{errors?.password?.message && errors?.password?.message}</span>
					</div>
					<div className='form__password'>
						<p>{DEFAULT_MESSAGES.TITLE_CHILD.RE_NEW_PASSWORD}</p>
						<div className={' form__input ' + (resetpass?.data ? 'form__input__disabled' : '')}>
							<input
								type={haveSeenRePwd ? 'text' : 'password'}
								placeholder={DEFAULT_MESSAGES.TITLE_CHILD.RE_NEW_PASSWORD}
								autoComplete='off'
								{...register('repassword', {
									required: {
										message: DEFAULT_MESSAGES.ERRORS.NEW_PASSWORD.REQUIRED,
									},
									minLength: {
										value: DEFAULT_MIN_LENGTH_PASSWORD,
										message: DEFAULT_MESSAGES.ERRORS.NEW_PASSWORD.LENGTH.replace(
											'$number',
											DEFAULT_MIN_LENGTH_PASSWORD
										),
									},
								})}
								onChange={(e) => {
									setValue('repassword', e.target.value);
									setErrorMessage(['', '']);
								}}
								disabled={resetpass?.data && true}
							/>
							<img
								className='show__password'
								src={haveSeenRePwd ? '../../images/closed_eye.png' : '../../images/eye.png'}
								alt=''
								onClick={() => setHaveSeenRePwd(!haveSeenRePwd)}
							/>
						</div>
						<span className='form__error'>
							{(errors?.repassword?.message && errors?.repassword?.message) ||
								(errorMessage[0] === false && errorMessage[1])}
						</span>
					</div>
					<div className='form__forgotpwd'>
						<p className='form__btn-forgotpwd' onClick={() => navigate(URL_ROUTER.LOGIN)}>
							<img className='pr-2' src='../../images/arrow_back.svg' />
							<span>{DEFAULT_MESSAGES.BUTTON.REDIRECT_LOGIN}</span>
						</p>
					</div>
					<div className='form__btn-submit btn_forgot'>
						<button type='submit'>{DEFAULT_MESSAGES.BUTTON.RESTORE_PASS}</button>
					</div>
				</form>
			)}
		</Index>
	);
};

export default ForgotPassword;
