import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		{/* <Suspense fallback={<Loader isloading={false} />}> */}
		<App />
		{/* </Suspense> */}
	</Provider>
);
// Revert code Huy Bug 10/5/2023
