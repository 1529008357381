import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const Modal = ({
	title,
	content,
	isOpen,
	setIsOpen,
	handleSubmit,
	handleCancel,
	nameCancel,
	nameConfirm,
	nameImage = 'dialog/icon_note.svg',
	ishiddenCancelButton = false,
}) => {
	const handleClose = () => {
		setIsOpen(false);
		handleCancel();
	};

	return (
		<Dialog
			header=''
			visible={isOpen}
			style={{ width: '576px' }}
			modal
			className={`dialog_tolerances ${ishiddenCancelButton && 'hidden_x'}`}
			draggable={false}
			onHide={() => {
				handleClose();
			}}
		>
			<div className='grid pl-2 justify-content-center flex-column'>
				<img src={`/images/${nameImage}`} alt='' />
				<span className='dialog__draft--title text-center'>{title}</span>
				<span className='dialog__draft--content text-center'>{content}</span>
			</div>
			<br />
			<br />
			<div className='grid justify-content-center flex'>
				<Button
					label={nameCancel}
					onClick={() => {
						handleClose();
					}}
					draggable={false}
					className='btn__cancel p-button-raised p-button-secondary p-button-text w-3 btn-cancel'
				/>
				<Button label={nameConfirm} onClick={handleSubmit} className={`btn__submit ml-3 w-3 btn-cfirm`} />
			</div>
		</Dialog>
	);
};

export default Modal;
