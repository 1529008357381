import React from 'react';
import {
	CustomerRules,
	CUSTOMER_REQUEST_REQUEST,
	CUSTOMER_REQUEST_PENDING,
	CUSTOMER_REQUEST_CANCEL,
	UserRules,
	USER_IS_ONLINE,
	USER_IS_STOPPING,
	USER_IS_OFFLINE,
	JobRules,
	JOB_DONE,
	JOB_PENDING,
	JOB_INCOMPLETE,
	PAY_PAID,
	PAY_UNPAY,
	PAY_CANCEL,
	NAME_ROOM,
	NOT_SET_ADMIN,
} from '../../constants';
import { timezoneToDate } from '../../commons/dateTime';
import { formatUSD, formatVND } from '../../commons/formatCost';
import { useSelector } from 'react-redux';

const TableBody = ({ rowData, item }) => {
	const nameGroup = NAME_ROOM.GROUP + '-' + rowData?.id_system;
	const currentUser = useSelector((state) => state.message.currentUser);
	const HTML = () => {
		const value = rowData?.[item];
		switch (item) {
			case 'message':
				return (
					<div
						// eslint-disable-next-line react/no-unknown-property
						count={
							currentUser?.newMessages?.[nameGroup] && currentUser?.newMessages?.[nameGroup] <= 5
								? currentUser?.newMessages?.[nameGroup]
								: '5+'
						}
						className={`icon__mess relative ${currentUser?.newMessages?.[nameGroup] && 'notify_count'}`}
					>
						<img className='icon__mes' alt='mess' src='/images/message/mess_icon--table.svg'></img>
					</div>
				);
			case 'status':
				if (value === CustomerRules.STATUS.PENDING) {
					return (
						<span className='table__body-name table__status btn_pending'>{CUSTOMER_REQUEST_PENDING}</span>
					);
				}
				if (value === CustomerRules.STATUS.REQUEST) {
					return (
						<span className='table__body-name table__status btn_success'>{CUSTOMER_REQUEST_REQUEST}</span>
					);
				}
				if (value === CustomerRules.STATUS.UNREQUEST) {
					return <span className='table__body-name table__status btn_stop'>{CUSTOMER_REQUEST_CANCEL}</span>;
				}
				if (value === UserRules.STATUS.ONLINE) {
					return (
						<span className='table__body-name table__status btn_success flex align-items-center '>
							{USER_IS_ONLINE}
						</span>
					);
				}
				if (value === UserRules.STATUS.OFFLINE) {
					return (
						<span className='table__body-name table__status btn_stop flex align-items-center'>
							{USER_IS_OFFLINE}
						</span>
					);
				}
				if (value === UserRules.STATUS.LEAVE) {
					return (
						<span className='table__body-name table__status btn_pending flex align-items-center'>
							{USER_IS_STOPPING}
						</span>
					);
				}

				if (value === CustomerRules.STATUS_PAY.PAID) {
					return (
						<span className='table__body-name table__status btn_success flex justify-content-center'>
							{PAY_PAID}
						</span>
					);
				}
				if (value === CustomerRules.STATUS_PAY.CANCEL) {
					return (
						<span className='table__body-name table__status btn_stop flex justify-content-center'>
							{PAY_CANCEL}
						</span>
					);
				}
				if (value === CustomerRules.STATUS_PAY.UNPAID) {
					return (
						<span className='table__body-name table__status btn_pending flex justify-content-center'>
							{PAY_UNPAY}
						</span>
					);
				}
				break;
			case 'status_jobs':
				if (value === JobRules.STATUS_JOBS.COMPLETE && rowData.status_update !== NOT_SET_ADMIN) {
					return (
						<span className='table__body-name btn_success table__status flex justify-content-center'>
							{rowData.count_fixed > 0 ? 'Đã Fix ' + rowData.count_fixed : JOB_DONE}
						</span>
					);
				}
				if (value === JobRules.STATUS_JOBS.COMPLETE) {
					return <span className='table__body-name btn_success flex justify-content-center'>{JOB_DONE}</span>;
				}
				if (value === JobRules.STATUS_JOBS.INCOMPLETE) {
					return (
						<span className='table__body-name btn_stop flex justify-content-center'>{JOB_INCOMPLETE}</span>
					);
				}
				if (
					value === JobRules.STATUS_JOBS.PENDING &&
					rowData.fixed_link === NOT_SET_ADMIN &&
					rowData?.finished_link !== NOT_SET_ADMIN
				) {
					return (
						<span className='table__body-name btn_pending flex justify-content-center'>
							{'Đang Fix ' + rowData.count_fixed}
						</span>
					);
				}

				if (value === JobRules.STATUS_JOBS.PENDING && rowData?.finished_link !== NOT_SET_ADMIN) {
					return (
						<span className='table__body-name btn_primary flex justify-content-center'>{JOB_PENDING}</span>
					);
				}
				if (value === JobRules.STATUS_JOBS.PENDING) {
					return (
						<span className='table__body-name btn_pending flex justify-content-center'>{JOB_PENDING}</span>
					);
				}
				break;
			case 'status_editor':
				if (
					value === JobRules.STATUS_EDITOR.COMPLETE ||
					rowData.status_update === JobRules.STATUS_EDITOR.COMPLETE
				) {
					return <span className='table__body-name btn_success flex justify-content-center'>{JOB_DONE}</span>;
				}
				if (value === JobRules.STATUS_EDITOR.INCOMPLETE) {
					return (
						<span className='table__body-name btn_stop flex justify-content-center'>{JOB_INCOMPLETE}</span>
					);
				}
				if (
					value === JobRules.STATUS_EDITOR.PENDING &&
					rowData?.finished_link !== NOT_SET_ADMIN &&
					rowData.fixed_link !== NOT_SET_ADMIN
				) {
					return (
						<span className='table__body-name flex justify-content-center btn_primary'>
							{rowData.count_fixed > 0
								? 'Đang ' + rowData.status_update + ' ' + rowData.count_fixed
								: JOB_PENDING}
						</span>
					);
				}

				if (
					value === JobRules.STATUS_EDITOR.PENDING &&
					rowData?.finished_link !== NOT_SET_ADMIN &&
					rowData.fixed_link === NOT_SET_ADMIN
				) {
					return (
						<span className='table__body-name flex justify-content-center btn_stop'>
							{rowData.count_fixed > 0
								? 'Đang ' + rowData.status_update + ' ' + rowData.count_fixed
								: JOB_PENDING}
						</span>
					);
				}

				if (value === JobRules.STATUS_EDITOR.PENDING && rowData?.finished_link !== NOT_SET_ADMIN) {
					return (
						<span className='table__body-name btn_primary flex justify-content-center'>{JOB_PENDING}</span>
					);
				}
				if (value === JobRules.STATUS_EDITOR.PENDING) {
					return (
						<span className='table__body-name btn_pending flex justify-content-center'>{JOB_PENDING}</span>
					);
				}
				break;
			case '_create_at':
				return <span className='table__body-name '>{timezoneToDate(rowData?._create_at)}</span>;
			case 'start_day':
				return <span className='table__body-name text-bold'>{timezoneToDate(rowData?.start_day)}</span>;
			case 'end_day':
				return <span className='table__body-name text-bold'>{timezoneToDate(rowData?.end_day)}</span>;
			case 'role':
				if (value === UserRules.ROLE.SALER) {
					return <span className='table__body-name text-bold color__green'>{UserRules.ROLE_NAME.SALER}</span>;
				}
				if (value === UserRules.ROLE.EDITOR) {
					return (
						<span className='table__body-name text-bold color__price'>{UserRules.ROLE_NAME.EDITOR}</span>
					);
				}
				if (value === UserRules.ROLE.LEADER_EDITOR) {
					return (
						<span className='table__body-name text-bold color__warning'>
							{' '}
							{UserRules.ROLE_NAME.LEADER_EDITOR}
						</span>
					);
				}
				break;
			case 'id_system':
				return <span className='table__body-name '>{rowData?.[item]}</span>;
			case 'customer_group_job':
				return (
					<span id={rowData?.id_customer} className={`altCustomer table__body-name text-bold ${item}`}>
						<div className='flex flex-column'>
							<span className='customer_name'>{rowData?.[item][0]}</span>
							<span className='group_name_job group__customer'>{rowData?.[item][1]}</span>
						</div>
					</span>
				);
			case 'reminder_saler':
			case 'reminder_staff':
			case 'reminder_editor':
			case 'username':
				return (
					<div className='flex flex-column'>
						<span>{rowData?.[item]}</span>
					</div>
				);
			case 'reminder_customer':
				if (item === 'reminder_saler') {
					return (
						<span id={rowData?.id_saler} className={`altSaler table__body-name text-bold ${item}`}>
							{rowData?.[item] !== NOT_SET_ADMIN && rowData?.[item]}
						</span>
					);
				}
				if (item === 'reminder_customer') {
					if (rowData?.group_name_job) {
						return (
							<span
								id={rowData?.id_customer}
								className={`altCustomer table__body-name text-bold ${item}`}
							>
								<div className='flex flex-column'>
									<span className='customer_name'>{rowData?.[item]}</span>
									<span className='group_name_job group__customer'>{rowData?.group_name_job}</span>
								</div>
							</span>
						);
					} else {
						return (
							<span className='table__body-name'>
								<span
									id={rowData?.id_customer}
									className={`altCustomer table__body-name text-bold ${item} customer_name`}
								>
									{rowData?.[item]?.name &&
										rowData?.[item]?.name !== NOT_SET_ADMIN &&
										rowData?.[item]?.name}
								</span>
								<p
									id={rowData?.id_customer}
									className={`altCustomer table__body-name group__customer ${item}`}
								>
									{rowData?.[item]?.name &&
										rowData?.[item]?.group !== NOT_SET_ADMIN &&
										rowData?.[item]?.group}
								</p>
							</span>
						);
					}
				}
				if (item === 'customer_group_job') {
					return (
						<span id={rowData?.id_customer} className={`altCustomer table__body-name text-bold ${item}`}>
							<div className='flex flex-column'>
								<span className='customer_name'>{rowData?.[item][0]}</span>
								<span className='group_name_job group__customer'>{rowData?.[item][1]}</span>
							</div>
						</span>
					);
				}
				if (item === 'reminder_editor') {
					return (
						<span id={rowData?.id_editor} className={`altEditor table__body-name text-bold ${item}`}>
							{rowData?.[item] !== NOT_SET_ADMIN && rowData?.[item]}
						</span>
					);
				}
				if (item === 'reminder_staff') {
					return (
						<span id={rowData?.staff_is_pay} className={`altStaff table__body-name text-bold ${item}`}>
							{rowData?.[item] !== NOT_SET_ADMIN && rowData?.[item]}
						</span>
					);
				}
				break;
			case 'date':
				return <span className='table__body-name text-bold'>{rowData?.[item]}</span>;
			case 'infor_reminder':
			case 'staff_is_pay':
			case 'id_job':
			case 'id_customer':
			case 'id_editor':
			case 'id_saler':
				return (
					<span className={` table__body-name text-bold ${item}`}>
						{rowData?.[item] !== NOT_SET_ADMIN && rowData?.[item]}
					</span>
				);
			case 'total_cost':
				return <span className='table__body-name text-bold color__name'>{formatUSD(rowData?.[item])}</span>;
			case 'pay_amount':
			case 'revenue':
			case 'pay_employees':
			case 'saler_cost':
				return <span className='table__body-name text-bold color__name'>{rowData?.[item]}</span>;

			case 'editor_cost':
				return <span className='table__body-name text-bold color__price'>{formatVND(rowData?.[item])}</span>;
			case 'quality_img':
				return <span className='table__body-name text-bold text-blue'>{rowData?.[item]}</span>;
			case 'type_models':
				return <span className='table__body-name text-bold text-blue'>{rowData?.[item]}</span>;
			case 'fullname':
				return <span className='table__body-name text-bold color__name'>{rowData?.[item]}</span>;
			case 'index_payment':
				return (
					<span className={`altCustomer table__body-name`}>
						<div className='flex flex-column'>
							<span className='customer_name'>{rowData?.[item][0]}</span>
							<span className='text-bold'>{rowData?.[item][1]}</span>
						</div>
					</span>
				);
			default:
				return <span className='table__body-name'>{rowData?.[item]}</span>;
		}
	};
	return <>{HTML()}</>;
};

export default TableBody;
