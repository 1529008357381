import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataParse } from '../../manager/jobs/dataParse';
import Table from '../../../components/table/Table';
import { table_jobs_overview } from '../../../components/table/header_table';
import {
	setIsOpenModalCreateJob,
	setIsOpenInformationJob,
	setIsOpenModalInformationCustomer,
	setDataModalInformationCustomer,
	setDataModalInformationJob,
	setIsOpenModalInformationUser,
	setDataModalInformationUser,
} from '../../../redux/modal/modalSlice';
import { dashboardJobsRequest } from '../../../redux/overviewJobs/actionJobs';
import { getEmployeeRequest } from '../../../redux/overviewEmployee/actionEmployee';
import { getCustomerRequest } from '../../../redux/sale/action';
import { handleRedirectMessage } from '../../../commons/support';
import { TIMEOUT } from '../../../constants';
import TableMobile from '../../../components/table/TableMobile';
import { Button } from 'primereact/button';
import ViewPerformJobOverView from '../../modal/ViewPerformJobOverView';

const JobsOverview = () => {
	const dispatch = useDispatch();
	const getFilterPage = useSelector((state) => state.auth.filter);
	const jobs = useSelector((state) => state.jobs?.dashboard);
	const employees = useSelector((state) => state.employee?.inforuser);
	const customer = useSelector((state) => state.sale?.getcustomer);
	const [openModal, setOpenModal] = useState(false);
	const isResetPage = useSelector((state) => state.auth.isResetPage);
	const [filter, setFilter] = useState('');

	useEffect(() => {
		if (!isResetPage) {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			dispatch(dashboardJobsRequest(filterString));
		}
	}, [dispatch, filter, getFilterPage]);

	useEffect(() => {
		let interval = setInterval(() => {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			dispatch(dashboardJobsRequest(filterString));
		}, 60000 * 5);

		return () => {
			clearInterval(interval);
		};
	}, [filter, getFilterPage, dispatch]);

	useEffect(() => {
		if (employees?.data) {
			dispatch(setDataModalInformationUser(employees));
			dispatch(setIsOpenModalInformationUser(true));
		}
	}, [employees, dispatch]);

	useEffect(() => {
		if (customer?.data) {
			dispatch(setDataModalInformationCustomer(customer));
		}
	}, [customer, dispatch]);

	const DataFilter = (data) => {
		setFilter(data);
	};

	const handleRowClick = (rowdata) => {
		const el = rowdata?.originalEvent?.target?.closest('td').childNodes[1];
		if (el.classList.contains('altSaler')) {
			//OPEN SALE
			const data = {};
			data.id = el.getAttribute('id');
			dispatch(getEmployeeRequest(data));
		} else if (el.classList.contains('altCustomer')) {
			//OPEN CUSTOMER
			const data = {};
			data.id = el.getAttribute('id');
			dispatch(getCustomerRequest(data));
			setTimeout(() => {
				dispatch(setIsOpenModalInformationCustomer(true));
			}, TIMEOUT);
		} else if (el.classList.contains('altEditor')) {
			//OPEN EDITOR
			const data = {};
			data.id = el.getAttribute('id');
			if (data.id !== 'NOT_SET_BY_ADMIN') {
				dispatch(getEmployeeRequest(data));
			}
		} else if (el.classList.contains('icon__mess')) {
			handleRedirectMessage(rowdata?.data, dispatch);
			//OPEN EDITOR
			// const id = el.getAttribute('id');
		} else {
			dispatch(setIsOpenInformationJob(true));
			dispatch(setDataModalInformationJob(rowdata));
		}
	};

	const handleCreate = () => {
		dispatch(setIsOpenModalCreateJob(true));
	};

	return (
		<>
			<ViewPerformJobOverView
				openModal={openModal}
				handleCloseModal={setOpenModal}
				data={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page) || []}
			/>
			<Table
				dataTable={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page)}
				loading={jobs?.loading}
				DataFilter={DataFilter}
				haveTotalTable={true}
				header={table_jobs_overview}
				handleRowClick={(e) => handleRowClick(e)}
				handleCreate={handleCreate}
				name_btn_add={'Tạo công việc'}
				filter={filter}
				totalRecords={jobs?.total}
				perPage={jobs?.per_page}
				currentPage={jobs?.current_page}
			/>
			<div className='table-mobile'>
				<Button
					label='Hiệu suất'
					className='p-button-outlined p-button-view '
					type='button'
					onClick={() => setOpenModal(true)}
				/>
			</div>
			<TableMobile
				data={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page)}
				handleRedirectMessage={(data) => {
					handleRedirectMessage(data, dispatch);
				}}
				handleRow={(data) => {
					dispatch(setIsOpenInformationJob(true));
					dispatch(setDataModalInformationJob({ data: data }));
				}}
				dataParse={dataParse}
				filter={filter}
			/>
		</>
	);
};

export default JobsOverview;
