import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const settingRequest = createAsyncThunk('getSystem', async (data, { rejectWithValue }) => {
	try {
		const res = await api.get(`setting/`);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const updateRequest = createAsyncThunk('updateSystem', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`setting/${data?.result?.id_system}`, data?.result);
		if (res) {
			successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
		}
		return res;
	} catch (error) {
		errorToast(TOAST_MESSAGE.UPDATE_FAILED);
		return rejectWithValue(error?.response?.data);
	}
});
