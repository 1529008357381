import React, { useEffect, useState } from 'react';

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { EMAIL_REGEX, PHONE_REGEX, UserRules } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { editEmployeeRequest, deleteEmployeeRequest } from '../../redux/overviewEmployee/actionEmployee';
import { setIsOpenModalCreateUser, setIsOpenModalInformationUser } from '../../redux/modal/modalSlice';
import { overlay } from '../../commons/overlay';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import Modal from '../dialog/dialog';
import { validate } from '../../commons/support';
import Avatar from '@mui/material/Avatar';
import { bankNumberFormat } from '../../commons/support';
import { dateAddMoreHours } from '../../commons/support';

const InformationUser = () => {
	const putUser = useSelector((state) => state.employee?.edituser);
	const deleteUser = useSelector((state) => state.employee?.deleteuser);
	const isOpenInformationUser = useSelector((state) => state.modal?.isOpenModalInformationUser);
	const rowdata = useSelector((state) => state.modal?.dataModalInformationUser);
	const [avatar, setAvatar] = useState('/images/default_avatar.jpeg');
	const minDate = new Date();
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch();
	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm();
	useEffect(() => {
		if (rowdata?.data) {
			setValue('fullname', rowdata?.data?.fullname);
			setValue('infor_reminder', rowdata?.data?.infor_reminder);
			setValue('phone', rowdata?.data?.phone);
			setValue('email', rowdata?.data?.email);
			if (rowdata?.data?.address) {
				setValue('address', rowdata?.data?.address);
			}
			if (rowdata?.data?.births) {
				setValue('births', new Date(rowdata?.data?.births));
			}
			if (rowdata?.data?.start_day) {
				setValue('start_day', new Date(rowdata?.data?.start_day));
			}
			if (rowdata?.data?.avatar) {
				setAvatar(rowdata?.data?.avatar);
			}
			if (rowdata?.data?.kpi_saler) {
				setValue('kpi_saler', rowdata?.data?.kpi_saler);
			}
		}
	}, [rowdata]);

	useEffect(() => {
		if (isOpenInformationUser) {
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [isOpenInformationUser]);

	useEffect(() => {
		if (putUser?.data && !putUser?.error) {
			dispatch(setIsOpenModalInformationUser(false));
		}
	}, [putUser, dispatch]);

	useEffect(() => {
		if (deleteUser?.data && !deleteUser?.error) {
			dispatch(setIsOpenModalInformationUser(false));
		}
	}, [deleteUser, dispatch]);

	const onSubmit = (data) => {
		data.births = dateAddMoreHours(data.births);
		data.start_day = dateAddMoreHours(data.start_day);
		const formDataPut = {};
		Object.keys(data).forEach((item) => {
			if (data[item] !== rowdata?.data[item]) {
				Object.assign(formDataPut, { [item]: data[item] });
			}
		});
		if (Object.keys(formDataPut).length > 0) {
			Object.assign(formDataPut, { id_system: rowdata?.data?.id_system });
			const formData = {
				data: data,
				result: formDataPut,
				index: rowdata?.data?.index - 1,
			};
			dispatch(editEmployeeRequest(formData));
		}
	};

	const handleCloseModal = () => {
		reset();
		dispatch(setIsOpenModalInformationUser(false));
	};

	const handleDeleteUser = () => {
		const formdata = {};
		formdata.id = rowdata?.data?.id_system;
		formdata.index = rowdata?.data?.index - 1;
		dispatch(deleteEmployeeRequest(formdata));
		setOpenModal(false);
	};

	const handleCreateNewUser = () => {
		dispatch(setIsOpenModalInformationUser(false));
		setTimeout(() => {
			dispatch(setIsOpenModalCreateUser(true));
		}, 100);
	};

	return (
		<>
			<ConfirmPopup />
			<Modal
				title='Xoá nhân viên'
				content='Bạn có đồng ý xóa nhân viên này không?'
				isOpen={openModal}
				setIsOpen={setOpenModal}
				nameCancel='Hủy bỏ'
				nameConfirm='Đồng ý'
				handleSubmit={handleDeleteUser}
			/>
			<Sidebar visible={isOpenInformationUser} position='right' onHide={handleCloseModal} className='create__job'>
				<div className='creat__job'>
					<div className='create__job--title flex justify-content-between'>
						<h2 className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							<div>
								Thông tin nhân viên
								<p>
									<span className='font-bold'>
										{' '}
										{UserRules.ROLE_NAME[rowdata?.data?.role]} - {rowdata?.data?.id_system}
									</span>
								</p>
							</div>
						</h2>
						{!rowdata?.error && (
							<Button onClick={() => setOpenModal(true)}>
								<img src='images/trash.svg' alt='' className='image__trash' />
							</Button>
						)}
					</div>
					<form
						className=' grid modal__creat--job no_flex'
						autoComplete='off'
						onSubmit={handleSubmit(onSubmit)}
					>
						{rowdata?.error ? (
							<span className='notfound'>Nhân viên không tồn tại.</span>
						) : (
							<div className='field col-12 md:col-12 grid'>
								<div className='field col-12 md:col-12 personal__block '>
									<span className='mb-2'>Ảnh đại diện</span>
									<div className='flex align-items-center'>
										<div>
											<Avatar
												className='p-overlay-badge'
												crossOrigin='anonymous'
												alt=''
												src={avatar}
											/>
										</div>
									</div>
								</div>
								<div className='field col-12 md:col-6'>
									<span className='mb-2'>Tên ngân hàng</span>
									<span className='pl-1 font-bold'>
										<InputText
											autoComplete='off'
											placeholder='Trống'
											value={rowdata?.data?.information_payment?.nameBank || ''}
											disabled
										/>
									</span>
								</div>
								<div className='field col-12 md:col-6'>
									<span className='mb-2'>Chủ tài khoản</span>
									<span className='pl-1 font-bold'>
										<InputText
											autoComplete='off'
											placeholder='Trống'
											value={rowdata?.data?.information_payment?.name_account_payment || ''}
											disabled
										/>
									</span>
								</div>
								<div className='field col-12 md:col-6'>
									<span className='mb-2'>Số tài khoản</span>
									<span className='pl-1 font-bold'>
										<InputText
											autoComplete='off'
											placeholder='Trống'
											value={
												rowdata?.data?.information_payment?.number_account_payment
													? bankNumberFormat(
															rowdata?.data?.information_payment?.number_account_payment
													  )
													: ''
											}
											disabled
										/>
									</span>
								</div>
								<div className='field col-12 md:col-6'>
									<span className='mb-2'>Ngày bắt đầu làm</span>
									<Controller
										name='start_day'
										control={control}
										rules={{ required: 'Chọn ngày bắt đầu làm' }}
										render={({ field, fieldState }) => (
											<Calendar
												maxDate={minDate}
												value={watch('start_day')}
												onChange={(e) => setValue('start_day', e.value)}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												id={field.name}
											/>
										)}
									/>
								</div>
								<div className='field col-12 md:col-6'>
									<span htmlFor='employees'>Trạng thái nhân viên</span>
									<span
										className={
											'p-float-label mt-3 m-0 flex justify-content-between align-items-center btn-user ' +
											(rowdata?.data?.status === UserRules.STATUS.OFFLINE
												? 'btn_stop '
												: rowdata?.data?.status === UserRules.STATUS.ONLINE
												? 'btn_success'
												: 'btn_pending')
										}
									>
										{UserRules._STATUS[rowdata?.data?.status]}
									</span>
								</div>
								<div className='field col-12 md:col-6 create__job--calendar'>
									<span htmlFor='calendar'>Ngày tháng năm sinh</span>
									<span className=' pt-3 cursor__normal font-bold block'>
										<Controller
											name='fullname'
											control={control}
											rules={{ required: false }}
											render={({ field, fieldState }) => (
												<Calendar
													maxDate={minDate}
													value={watch('births')}
													onChange={(e) => setValue('births', e.value)}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													id={field.name}
												/>
											)}
										/>
									</span>
								</div>
								<div className='field col-12 md:col-12 '>
									<span htmlFor='autocomplete'>
										<span className='warning'>*</span>Tên nhân viên
									</span>
									<Controller
										name='fullname'
										control={control}
										rules={{ required: 'Vui lòng nhập tên nhân viên.', minLength: 6, validate }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='off'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập tên nhân viên'
											/>
										)}
									/>
									{errors?.fullname?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.fullname?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-12 '>
									<span htmlFor='autocomplete'>
										<span className='warning'>*</span>Biệt danh
									</span>
									<Controller
										name='infor_reminder'
										control={control}
										rules={{ required: 'Vui lòng nhập biệt danh.', minLength: 1, validate }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='off'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập biệt danh'
											/>
										)}
									/>
									{errors?.infor_reminder?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.infor_reminder?.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6 '>
									<span htmlFor='withoutgrouping'>
										<span className='warning'>*</span>Số điện thoại
									</span>
									<Controller
										name='phone'
										control={control}
										rules={{ required: 'Vui lòng nhập số điện thoại.', pattern: PHONE_REGEX }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='off'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập số điện thoại'
												onKeyPress={(event) => {
													if (!/[0-9]/.test(event.key)) {
														event.preventDefault();
													}
												}}
											/>
										)}
									/>
									{errors?.phone?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.phone?.message}
										</span>
									)}
									{errors?.phone?.pattern && (
										<span className='warning' style={{ fontSize: '12px' }}>
											Số điện thoại không phù hợp
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6'>
									<span htmlFor='original__link'>
										<span className='warning'>*</span>Email
									</span>
									<Controller
										name='email'
										control={control}
										rules={{ required: 'Vui lòng nhập email.', pattern: EMAIL_REGEX }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='off'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập email'
											/>
										)}
									/>
									{errors?.email?.message && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.email?.message}
										</span>
									)}
									{errors?.email?.pattern && (
										<span className='warning' style={{ fontSize: '12px' }}>
											Số điện thoại không phù hợp.
										</span>
									)}
								</div>
								<div className='field col-12 md:col-12'>
									<span htmlFor='original__link'>Địa chỉ</span>
									<Controller
										name='address'
										control={control}
										rules={{ required: false }}
										render={({ field }) => (
											<InputText
												autoComplete='off'
												id={field.name}
												{...field}
												placeholder='Nhập địa chỉ'
											/>
										)}
									/>
								</div>
								{rowdata?.data?.role === UserRules?.ROLE?.SALER && (
									<div className='field col-12 md:col-12'>
										<span htmlFor='original__link'>
											<span className='warning'>*</span>Kpi sales
										</span>
										<span className='p-float-label cursor__normal '>
											<Controller
												name='kpi_saler'
												control={control}
												rules={{
													required:
														UserRules.ROLE_NAME[rowdata?.data?.role] ===
														UserRules.ROLE_NAME.SALER
															? 'Vui lòng nhập Kpi cho sales.'
															: false,
												}}
												render={({ field, fieldState }) => (
													<InputNumber
														autoComplete='off'
														{...field}
														id={field.name}
														onChange={(e) => {
															setValue('kpi_saler', e.value);
														}}
														className={classNames(
															{ 'p-invalid': fieldState.invalid },
															'm-0'
														)}
														placeholder='Nhập Kpi cho sales'
														locale='en-US'
													/>
												)}
											/>
										</span>
										{errors?.kpi_saler?.message && (
											<span className='warning' style={{ fontSize: '12px' }}>
												{errors?.kpi_saler?.message}
											</span>
										)}
									</div>
								)}
								<div className='field col-12 md:col-12'>
									<span className='p-float-label open__modal text-bold pt-0'>
										<span onClick={handleCreateNewUser}>+ Tạo nhân viên mới</span>
									</span>
								</div>
							</div>
						)}

						<div className='btn_modal field col-12 md:col-12 grid position_bottom'>
							<div className={`field col-6 md:col-${rowdata?.error ? '12' : '6'}`}>
								<span className='p-float-label'>
									<Button
										label='Hủy bỏ'
										className='p-button-outlined cancel--btn'
										type='button'
										onClick={handleCloseModal}
									/>
								</span>
							</div>
							{!rowdata?.error && (
								<div className='field col-6 md:col-6'>
									<span className='p-float-label'>
										<Button
											label='Cập nhật'
											className='p-button-outlined p-button-secondary confirm--btn'
											type='submit'
										/>
									</span>
								</div>
							)}
						</div>
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default InformationUser;
