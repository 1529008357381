import React from 'react';
import { useEffect } from 'react';
import { socket } from '../../../_services/socket';

const MenuField = ({ getId, id, setId, userSend, messages, setMessages }) => {
	const items = [
		{
			label: 'Delete',
			icon: 'trash_black.svg',
			event: () => {
				socket.emit('remove-message-by-id', id);
				const result = [...messages]?.map((item) => {
					if (item?.messagesByDate) {
						item.messagesByDate = item?.messagesByDate?.map((mes) => {
							if (mes?._id === getId) {
								mes.content = 'Message has been deleted';
								mes.images = [];
								mes.isDeleted = true;
							}
							return mes;
						});
					}
					return item;
				});
				setMessages(result);
				setId(null);
			},
		},
	];

	const handleOpenMenu = () => {
		setId(id);
		if (id === getId) {
			setId(null);
		}
	};

	useEffect(() => {
		const handleClickOutsideMenu = (e) => {
			const elementChildMenu = document.querySelector(`.message__menu_${id}`);
			if (id === getId) {
				if (!elementChildMenu?.contains(e.target)) {
					setId(null);
				}
			}
		};

		window.addEventListener('mousedown', handleClickOutsideMenu);

		return () => {
			window.removeEventListener('mousedown', handleClickOutsideMenu);
		};
	}, [getId]);

	return (
		<div className='relative message_btn'>
			<img src='images/dots_menu.svg' alt='' className='cursor-pointer' onClick={handleOpenMenu} />
			<div
				className={`message__menu_${id} ${getId !== id && 'hidden'} absolute border-round-md p-2 z-1 ${
					userSend && 'right-0'
				}`}
				style={{ border: '1px solid gray', background: '#fff' }}
			>
				{items?.map((item, index) => {
					return (
						<div key={index} className='flex' onClick={item?.event}>
							<img src={`/images/${item.icon}`} alt='' />
							<span style={{ fontSize: '13px' }}>{item.label}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MenuField;
