import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/table/Table';
import { table_payment_managerment } from '../../../components/table/header_table';
import { getPayStaffRequest } from '../../../redux/payment/actionPay';
import { dataParseManagement } from '../payment/dataParse';
import { setIsOpenModalInformationPayment, setDataModalInformationPayment } from '../../../redux/modal/modalSlice';
import TableMobile from '../../../components/table/TableMobile';

const PaymentManagement = () => {
	const dispatch = useDispatch();
	const paymentStaff = useSelector((state) => state.payment?.getstaff);
	const getFilterPage = useSelector((state) => state.auth.filter);
	const [filter, setFilter] = useState('');

	useEffect(() => {
		const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
		if (filterString) {
			dispatch(getPayStaffRequest(filterString));
		}
	}, [dispatch, filter, getFilterPage]);

	const DataFilter = (data) => {
		if (data) {
			setFilter(data);
		}
	};

	const handleRowClick = (rowdata) => {
		dispatch(setIsOpenModalInformationPayment(true));
		dispatch(setDataModalInformationPayment(rowdata));
	};

	return (
		<>
			<Table
				dataTable={dataParseManagement(
					paymentStaff?.data?.data_pay,
					paymentStaff?.data?.current_page,
					paymentStaff?.data?.per_page
				)}
				loading={paymentStaff?.loading}
				DataFilter={DataFilter}
				haveTotalTable={false}
				header={table_payment_managerment}
				handleRowClick={(e) => handleRowClick(e)}
				name_btn_add={false}
				handleCreate={false}
				totalRecords={paymentStaff?.data?.total}
				perPage={paymentStaff?.data?.per_page}
				currentPage={paymentStaff?.data?.current_page}
			/>
			<TableMobile
				data={dataParseManagement(paymentStaff?.data?.data?.data_pay)}
				handleRow={(data) => {
					dispatch(setIsOpenModalInformationPayment(true));
					dispatch(setDataModalInformationPayment({ data }));
				}}
			/>
		</>
	);
};

export default PaymentManagement;
