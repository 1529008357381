import { NAME_ROOM, UserRules, STRING_ONLY_SPACE, JobRules, NOT_SET_ADMIN, CustomerRules } from '../constants';
import { setIsOpenChat, privateGroup } from '../redux/messages/messageSlice';
import store from '../redux/store';
import { socket } from '../_services/socket';

export const handleOutSide = (className, state, setState) => {
	const handleClickOutSideNav = (e) => {
		const el = document.querySelector(className);
		if (state && !el?.contains(e.target)) {
			setState(false);
		}
	};

	window.addEventListener('mousedown', handleClickOutSideNav);

	return () => {
		window.removeEventListener('mousedown', handleClickOutSideNav);
	};
};

export const handleRedirectMessage = (data, dispatch) => {
	const id_system = data?.id_system;
	const id_Group = NAME_ROOM.GROUP + '-' + id_system;
	const membersMsg = store.getState()?.message?.allMembers;
	const user = store.getState()?.auth?.user;

	const members = membersMsg?.reduce((arr, mem) => {
		if (mem?.role === UserRules.ROLE.ADMIN) {
			arr.push(mem?.id_system);
		}
		return arr;
	}, []);
	if (!members.includes(data?.id_saler)) {
		members.push(data?.id_saler);
	}
	if (data?.id_editor !== UserRules.ROLE.NOT_SET_BY_ADMIN) {
		members.push(data?.id_editor);
	}

	socket.emit('reset-notifications', id_Group, user?.id_system);
	const rs = {
		groupID: id_system,
		priveGroupID: id_Group,
		memberGroup: members,
		members: [data?.id_saler],
	};

	if (data?.reminder_customer && data?.group_name_job) {
		rs.groupName = `${data?.reminder_customer} ( ${data?.group_name_job} )`;
	} else {
		rs.groupName = id_system;
	}

	if (data?.id_editor) {
		rs.members.push(data?.id_editor);
	}

	dispatch(privateGroup(rs));
	dispatch(setIsOpenChat(true));
};

export const removeComma = (str) => {
	return Number(Number(str?.toString()?.replace(/,/g, '')).toFixed(3));
};

export const validate = (value) => {
	if (value !== undefined) {
		const matches = value?.match(STRING_ONLY_SPACE);
		return matches ? 'Ký tự không hợp lệ' : null;
	}
	return null;
};

export const bankNumberFormat = (value) => {
	if (value) {
		var v = value
			?.toString()
			.replace(/\s+/g, '')
			.replace(/[^0-9]/gi, '');
		var matches = v.match(/\d{4,16}/g);
		var match = (matches && matches[0]) || '';
		var parts = [];
		for (let i = 0, len = match.length; i < len; i += 4) {
			parts.push(match.substring(i, i + 4));
		}
		if (parts.length) {
			return parts.join(' ');
		} else {
			return value;
		}
	} else {
		return '';
	}
};

export const dateAddMoreHours = (date) => {
	const day = new Date(date);
	const time_now = new Date();
	const hours = time_now.getHours();
	const minutes = time_now.getMinutes();
	const seconds = time_now.getSeconds();
	day.setHours(hours);
	day.setMinutes(minutes);
	day.setSeconds(seconds);
	return day;
};

export const formatDate = (date) => {
	if (date) {
		const newDate = new Date(date);
		return newDate;
	}
	return date;
};

export const checkScreenIsMobile = () => {
	const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
	if (width < 1113) {
		return true;
	}
	return false;
};

export const checkStatusJob = (value, rowData) => {
	if (value === JobRules.STATUS_JOBS.COMPLETE && rowData?.status_update !== NOT_SET_ADMIN) {
		return 'btn_success';
	}
	if (value === JobRules.STATUS_JOBS.COMPLETE) {
		return 'btn_success';
	}
	if (value === JobRules.STATUS_JOBS.INCOMPLETE) {
		return 'btn_stop';
	}
	if (
		value === JobRules.STATUS_JOBS.PENDING &&
		rowData?.fixed_link === NOT_SET_ADMIN &&
		rowData?.finished_link !== NOT_SET_ADMIN
	) {
		return 'btn_pending';
	}

	if (value === JobRules.STATUS_JOBS.PENDING && rowData?.finished_link && rowData?.finished_link !== NOT_SET_ADMIN) {
		return 'btn_primary';
	}
	if (value === JobRules.STATUS_JOBS.PENDING) {
		return 'btn_pending';
	}
};

export const checkStatus = (value) => {
	if (value === CustomerRules.STATUS.PENDING) {
		return 'btn_pending';
	}
	if (value === CustomerRules.STATUS.REQUEST) {
		return 'btn_success';
	}
	if (value === CustomerRules.STATUS.UNREQUEST) {
		return 'btn_stop';
	}
	if (value === UserRules.STATUS.ONLINE) {
		return 'btn_success';
	}
	if (value === UserRules.STATUS.OFFLINE) {
		return 'btn_stop';
	}
	if (value === UserRules.STATUS.LEAVE) {
		return 'btn_pending';
	}

	if (value === CustomerRules.STATUS_PAY.PAID) {
		return 'btn_success';
	}
	if (value === CustomerRules.STATUS_PAY.CANCEL) {
		return 'btn_stop';
	}
	if (value === CustomerRules.STATUS_PAY.UNPAID) {
		return 'btn_pending';
	}
};

export const checkStatusEditor = (value, rowData) => {
	if (value === JobRules.STATUS_EDITOR.COMPLETE || rowData.status_update === JobRules.STATUS_EDITOR.COMPLETE) {
		return 'btn_success';
	}
	if (value === JobRules.STATUS_EDITOR.INCOMPLETE) {
		return 'btn_stop';
	}

	if (
		value === JobRules.STATUS_EDITOR.PENDING &&
		rowData?.finished_link !== NOT_SET_ADMIN &&
		rowData.fixed_link !== NOT_SET_ADMIN
	) {
		return 'btn_primary';
	}

	if (
		value === JobRules.STATUS_EDITOR.PENDING &&
		rowData?.finished_link !== NOT_SET_ADMIN &&
		rowData.fixed_link === NOT_SET_ADMIN
	) {
		return 'btn_stop';
	}

	if (value === JobRules.STATUS_EDITOR.PENDING && rowData?.finished_link !== NOT_SET_ADMIN) {
		return 'btn_primary';
	}
	if (value === JobRules.STATUS_EDITOR.PENDING) {
		return 'btn_pending';
	}
};
