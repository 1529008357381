import React, { useEffect } from 'react';
import { timeAgo } from '../../../commons/message.common';
import { Image } from 'primereact/image';
import MenuField from './menu';
import { useState } from 'react';
import { UserRules } from '../../../constants';

const Messages = ({ messagesOnRoom, currentUser }) => {
	const [getId, setId] = useState(null);
	const URL = process.env.REACT_APP_API || process.env.REACT_APP_DEV_API;
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		if (messagesOnRoom) {
			setMessages(messagesOnRoom);
		}
	}, [messagesOnRoom]);

	const showMessages = () => {
		if (messages?.length > 0) {
			return messages.map((msg, index) => {
				return (
					<div key={index}>
						<div className='message-data align-right'>
							<p className='message-data-time'>{msg?._id?.date}</p>
						</div>
						{msg.messagesByDate.map((message, index2) => {
							const checkUserSend = message?.from === currentUser?.id_system ? true : false;
							return (
								<li className={` ${checkUserSend && 'clearfix'}`} key={index2}>
									{/* <p className="message-data-alert">Editor đã hoàn thành job 23454.S34568</p> */}
									<div
										className={`msg__by--user flex align-items-center relative ${
											checkUserSend ? 'justify-content-end' : 'justify-content-start'
										}`}
									>
										{checkUserSend ? (
											<>
												<div className='message my-message align-items-end '>
													{message?.content?.length > 0 && <p>{message?.content}</p>}

													<div className='grid  justify-content-end'>
														{message?.images?.length > 0 &&
															message?.images.map((image, index3) => {
																return (
																	<Image
																		src={`${URL}${image}`}
																		preview
																		alt='Image'
																		className='chat__image col-4 md:col-4 p-1 mb-1'
																		key={index3}
																	/>
																);
															})}
													</div>
												</div>
												<div
													className='chat_img my-mess mr-1'
													role={message?.from?.split('.')[1]}
													data-size='small'
												></div>
												{!message?.isDeleted && currentUser?.role === UserRules.ROLE.ADMIN && (
													<div>
														<MenuField
															id={message?._id}
															getId={getId}
															setId={setId}
															userSend={checkUserSend}
															messages={messages}
															setMessages={setMessages}
														/>
													</div>
												)}
												<span className={`message_time`}>{timeAgo(message?.time)}</span>
											</>
										) : (
											<>
												{!message?.isDeleted && currentUser?.role === UserRules.ROLE.ADMIN && (
													<div>
														<MenuField
															id={message?._id}
															getId={getId}
															setId={setId}
															userSend={checkUserSend}
															messages={messages}
															setMessages={setMessages}
														/>
													</div>
												)}
												<div
													className='chat_img your-mess ml-1'
													role={message?.from?.split('.')[1]}
													data-size='small'
												></div>
												<div className='message other-message align-items-start'>
													{message?.content?.length > 0 && <p>{message?.content}</p>}
													<div className='grid  justify-content-start'>
														{message?.images?.length > 0 &&
															message?.images.map((image, index3) => {
																return (
																	<Image
																		src={`${URL}${image}`}
																		preview
																		alt='Image'
																		className='chat__image col-4 md:col-4 p-1 mb-1'
																		key={index3}
																	/>
																);
															})}
													</div>
												</div>
												<span className={`message_time`}>{timeAgo(message?.time)}</span>
											</>
										)}
									</div>
								</li>
							);
						})}
					</div>
				);
			});
		} else {
			return <img src='images/btn_chat.svg' alt='' className='logo_chat'></img>;
		}
	};

	return <>{showMessages()}</>;
};

export default Messages;
