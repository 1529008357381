import React from 'react';
import Loader from './../../commons/loader';
import { useLocation } from 'react-router-dom';
import { URL_ROUTER } from '../../routes/routes';

const Index = (props) => {
	const location = useLocation();
	const { pathname } = location;

	return (
		<div className='login__container'>
			<div className='login__bg'>
				<div className='login__bg--title absolute'>
					<div className='flex align-items-center'>
						<p>Every pixel counts</p>
					</div>
					<span className='login__note'>Chào mừng bạn đến với hệ thống quản lý của One Touch</span>
				</div>
				<img src='/images/bg_login.jpg' alt='' />
			</div>
			<div className={`login__form ${props.className} ${pathname === URL_ROUTER.FORGOT_PASSWORD && 'height-60'}`}>
				<div className='login__title'>
					<p>Hệ thống quản lý</p>
					<p>One Touch</p>
				</div>
				<div className='login__route'>{props.title}</div>
				{props.children}
			</div>
			<Loader />
		</div>
	);
};

export default Index;
