import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import ApiService from '../../_services/apiService';

const api = new ApiService();

export const saleCustomerRequest = createAsyncThunk('Customers', async (filter, { rejectWithValue }) => {
	try {
		const search = typeof filter === 'string' ? filter : '';
		const res = await api.get(`customers${search}`);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response);
	}
});

export const addCustomerRequest = createAsyncThunk('Customer', async (data, { rejectWithValue }) => {
	try {
		const res = await api.post('customers', data);
		if (res) {
			successToast(TOAST_MESSAGE.CREATE_CUSTOMER_SUCCESS);
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response);
	}
});

export const editCustomerRequest = createAsyncThunk('editCustomer', async (data, { rejectWithValue }) => {
	try {
		let res = await api.put(`customers/${data?.result?.id_system}`, data?.data);
		successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
		if (res) {
			res.data = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response);
	}
});

export const deleteCustomerRequest = createAsyncThunk('deleteCustomer', async (data, { rejectWithValue }) => {
	try {
		let res = await api.delete(`customers/${data?.id}`);
		if (res) {
			successToast(TOAST_MESSAGE.DELETE_CUSTOMER_SUCCESS);
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(TOAST_MESSAGE.DELETE_CUSTOMER_FAILED);
		return rejectWithValue(error?.response);
	}
});

export const getCustomerRequest = createAsyncThunk('getCustomer', async (data, { rejectWithValue }) => {
	try {
		let res = await api.get(`customers/${data?.id}`);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response);
	}
});
