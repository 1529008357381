import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../constants';
import { forgotPassword } from '../../redux/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrorAuth } from '../../redux/auth/authSlice';
import { URL_ROUTER } from '../../routes/routes';
import { DEFAULT_MESSAGES } from '../../commons/Messages/default.message';
import Index from './Index';

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const forgotpass = useSelector((state) => state.auth?.forgotpassword);
	const [errorMessage, setErrorMessage] = useState(['', '']);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		clearErrors,
	} = useForm({});

	useEffect(() => {
		if (forgotpass?.error) {
			setErrorMessage([false, forgotpass?.data]);
		}
		if (forgotpass?.data?.status) {
			setErrorMessage([true, forgotpass?.data?.data?.messager]);
		}
	}, [forgotpass]);

	const onSubmit = (data) => {
		if (!errorMessage[0] && data) {
			const dataPost = {
				email: data?.email,
			};
			dispatch(forgotPassword(dataPost));
		}
	};

	const backLoginPage = () => {
		setErrorMessage(['', '']);
		reset();
		dispatch(resetErrorAuth());
		navigate(URL_ROUTER.LOGIN);
	};

	return (
		<Index className={'form__forgot-pwd'} title={'Quên mật khẩu'}>
			<form onSubmit={handleSubmit(onSubmit)} className='forgot__form'>
				<div className='form__email'>
					<p>Email</p>
					<div className={` form__input ${errorMessage[0] && forgotpass?.data && 'form__input__disabled'}`}>
						<input
							type='text'
							placeholder={DEFAULT_MESSAGES.PLACEHOLDER.EMAIL}
							autoComplete='off'
							{...register('email', {
								required: DEFAULT_MESSAGES.ERRORS.EMAIL.REQUIRED,
								pattern: {
									value: EMAIL_REGEX,
									message: DEFAULT_MESSAGES.ERRORS.EMAIL.MATCH,
								},
							})}
							onChange={(e) => {
								setValue('email', e.target.value);
								setErrorMessage(['', '']);
								clearErrors('email');
							}}
							disabled={errorMessage[0] && forgotpass?.data && true}
						/>
					</div>
					{errors?.email?.message && <span className='form__error'>{errors?.email?.message}</span>}

					{errorMessage[0] && (
						<div className='form__message'>
							<img src='../../images/warning-icon.svg' className='pr-2' />
							<span className='form__error'>{errorMessage[1]}</span>
						</div>
					)}
				</div>
				<div className='form__forgotpwd'>
					<p className='form__btn-forgotpwd' onClick={() => backLoginPage()}>
						<img className='pr-2' src='../../images/arrow_back.svg' />
						<span>{DEFAULT_MESSAGES.BUTTON.REDIRECT_LOGIN}</span>
					</p>
				</div>
				<div className='form__btn-submit btn_forgot'>
					<button type='submit'>{DEFAULT_MESSAGES.BUTTON.RESTORE_PASS}</button>
				</div>
			</form>
		</Index>
	);
};

export default ForgotPassword;
