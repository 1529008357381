export const DEFAULT_MESSAGES = {
	TITLE: 'Hệ thống quản lý',
	NAME: 'One Touch',
	WELCOME: 'Chào mừng bạn đến với hệ thống quản lý của One Touch',
	CONTENT: 'Khôi phục mật khẩu',
	EMAIL: 'Email',
	PLACEHOLDER: {
		EMAIL: 'Nhập email của bạn',
		NAME_REGISTER: 'Nhập tên đăng nhập',
		PASSWORD: 'Nhập mật khẩu',
		NEW_PASSWORD: 'Nhập mật khẩu mới',
	},
	ERRORS: {
		EMAIL: {
			REQUIRED: 'Vui lòng nhập tên đăng nhập.',
			MATCH: 'Email không hợp lệ.',
		},
		PASSWORD: {
			REQUIRED: 'Vui lòng nhập mật khẩu.',
		},
		NEW_PASSWORD: {
			REQUIRED: 'Vui lòng nhập mật khẩu mới.',
			LENGTH: 'Mật khẩu phải lớn hơn $number kí tự.',
		},
		NAME_REGISTER: 'Vui lòng nhập tên đăng nhập.',
		LOGIN_ERR: 'Tài khoản hoặc mật khẩu không chính xác.',
		CHANGE_PASS_ERR: 'Mật khẩu xác nhận không khớp với mật khẫu mới.',
	},
	BUTTON: {
		REDIRECT_LOGIN: 'Quay lại trang đăng nhập',
		RESTORE_PASS: 'Khôi phục mật khẩu',
		FORGOT_PASS: 'Quên mật khẩu',
	},
	TITLE_CHILD: {
		LOGIN: ' Đăng nhập',
		NAME_LOGIN: 'Tên đăng nhập',
		PASSWORD: 'Mật khẩu',
		NEW_PASSWORD: 'Nhập mật khẩu',
		RE_NEW_PASSWORD: 'Nhập lại mật khẩu',
		CHANGE_PASS_SUCCESS: 'Cài đặt mật khẩu thành công',
	},
};
