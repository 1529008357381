import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { PHONE_REGEX } from '../../../constants';
import { useDispatch } from 'react-redux';
import { userEditProfile } from '../../../redux/auth/action';
import { resetEditStaff } from '../../../redux/auth/authSlice';
import DotStatus from '../../../commons/dotStatus';
import { Calendar } from 'primereact/calendar';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { errorToast } from '../../../commons/toast';
import { validate, bankNumberFormat } from '../../../commons/support';
import { dateAddMoreHours } from '../../../commons/support';
import { timezoneToDate } from '../../../commons/dateTime';

const PersonalInfor = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const defaultAvatart = 'images/default_avatar.jpeg';
	const editUser = useSelector((state) => state.auth.editUser);

	const [imagePreview, setImagePreview] = useState(null);
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');

	const [errPassword, setErrPassword] = useState('');
	const [errRePassword, setErrRePassword] = useState('');
	const maxDate = new Date();

	const [defaultValues] = useState({
		address: '',
		avatar: '',
		births: null,
		name_account_payment: '',
		email: '',
		id_system: '',
		fullname: '',
		nameBank: '',
		number_account_payment: '',
		payment_method: '',
		role: '',
		start_day: '',
		status: '',
		username: '',
		phone: '',
	});

	const {
		control,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
	} = useForm({ defaultValues });

	const setDefaultValue = React.useCallback(() => {
		if (user?.data) {
			const { data } = user;
			if (data?.avatar) {
				setValue('avatar', data?.avatar);
			}
			if (data?.username) {
				setValue('username', data?.username);
			}
			if (data?.fullname) {
				setValue('fullname', data?.fullname);
			}
			if (data?.phone) {
				setValue('phone', data?.phone);
			}
			if (data?.email) {
				setValue('email', data?.email);
			}
			if (data?.id_system) {
				setValue('id_system', data?.id_system);
			}
			if (data?.role) {
				setValue('role', data?.role);
			}
			if (data?.status) {
				setValue('status', data?.status);
			}
			if (data?.start_day) {
				setValue('start_day', data?.start_day);
			}
			if (data?.address) {
				setValue('address', data?.address);
			}

			if (data?.nameBank) {
				setValue('nameBank', data?.nameBank);
			}
			if (data?.number_account_payment) {
				setValue('number_account_payment', data?.number_account_payment);
			}
			if (data?.payment_method) {
				setValue('payment_method', data?.payment_method);
			}
			if (data?.name_account_payment) {
				setValue('name_account_payment', data?.name_account_payment);
			}
			if (data?.births) {
				setValue('births', new Date(data?.births));
			}
			setValue('password', '');
			setValue('re_password', '');
		}
	}, [user]);
	useEffect(() => {
		if (editUser?.data) {
			setTimeout(() => {
				dispatch(resetEditStaff());
				setDefaultValue();
				setPassword('');
				setRePassword('');
				setErrRePassword('');
			}, 500);
		}
	}, [editUser, dispatch, setDefaultValue]);

	useEffect(() => {
		setDefaultValue();
	}, [setDefaultValue]);

	const onSubmit = (data) => {
		if (password) {
			if (password.length < 8) {
				return setErrPassword('Mật khẩu có ít nhất 8 ký tự.');
			}
			if (!rePassword) {
				return setErrRePassword('Chưa nhập lại mật khẩu.');
			} else if (rePassword !== password) {
				return setErrRePassword('Mật khẩu xác nhận không khớp với mật khẩu mới.');
			}
		}

		if (data?.avatar?.size > 10448576) {
			return errorToast(' Kích thước ảnh quá lớn.');
		}
		const result = {};
		for (const item in user?.data) {
			if (data[item]) {
				if (item === 'births') {
					if (JSON.stringify(data[item]) !== JSON.stringify(user?.data[item])) {
						result[item] = data?.[item];
					}
				} else if (typeof data[item] === 'string' && typeof user?.data[item] === 'string') {
					if (data[item]?.trim() !== user?.data[item]?.trim()) {
						result[item] = data?.[item].trim();
					}
				} else {
					if (data[item] !== user?.data[item]) {
						result[item] = data?.[item];
					}
				}
			}
		}

		if (password) {
			result.password = password;
		}
		if (Object.keys(result).length > 0) {
			if (result.births) {
				result.births = dateAddMoreHours(result.births);
			}
			const formData = new FormData();
			for (const item in result) {
				formData.append([item], result[item]);
			}
			const fn = {
				id: user?.data?.id_system,
				data: formData,
			};
			dispatch(userEditProfile(fn));
		}
	};

	const handleChangeAvatar = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);

		setImagePreview(URL.createObjectURL(file));
		setValue('avatar', file);
	};

	const handleCancel = () => {
		setDefaultValue();
	};
	return (
		<div className='page h-full'>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='grid mt-3 personal_block'>
					<div className='field col-12 md:col-12 grid personal__container relative'>
						<div className='field col-12 md:col-12 flex align-items-center'>
							<div className='relative'>
								<div className='mask__image'>
									<img
										src={imagePreview || watch('avatar') || defaultAvatart}
										alt=''
										className='mr-5 infor_avatar'
									/>
								</div>
								<input type='file' id='avatar' onChange={handleChangeAvatar} />
								<label htmlFor='avatar' className='label_avatar absolute'></label>
							</div>

							<div className='personal__information pt-7 pl-5'>
								<div className='flex align-items-center'>
									<DotStatus />
									<span className='pl-1'>{user?.data?.fullname}</span>
								</div>
								<p>{user?.data?.email}</p>
							</div>
						</div>
						<div className='infor__block grid relative'>
							<div className='field col-12 md:col-6 personal__block'>
								<div className='grid'>
									<div className='field col-12 md:col-12 mb-0 pb-3 pt-3'>
										<p className='infor__title'>Thông tin</p>
									</div>
									<div className='field col-12 md:col-6'>
										<span>Họ và tên</span>
										<Controller
											name='fullname'
											control={control}
											rules={{ required: false }}
											render={({ field, fieldState }) => (
												<InputText
													autoComplete='off'
													id={field.name}
													{...field}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													disabled
													placeholder='Nhập họ và tên'
												/>
											)}
										/>
									</div>
									<div className='field col-12 md:col-6'>
										<span>Tên đăng nhập</span>
										<Controller
											name='username'
											control={control}
											rules={{ required: false }}
											render={({ field, fieldState }) => (
												<InputText
													autoComplete='off'
													id={field.name}
													{...field}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													disabled
													placeholder='Nhập tên đăng nhập'
												/>
											)}
										/>
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span className='warning mb-0'>*</span>
											<span style={{ fontSize: '13px' }}>Ngày sinh</span>
										</div>
										<Controller
											name='births'
											control={control}
											rules={{ required: 'Vui lòng nhập ngày tháng năm sinh.' }}
											render={({ field, fieldState }) => (
												<Calendar
													value={watch('births')}
													onChange={(e) => setValue('births', e.value)}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													id={field.name}
													placeholder={`${
														watch('births') ? timezoneToDate(watch('births')) : 'dd/mm/yyyy'
													}`}
													maxDate={maxDate}
													readOnlyInput
												/>
											)}
										/>
										{errors?.births && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errors?.births?.message}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span className='warning mb-0'>*</span>
											<span style={{ fontSize: '13px' }}>Số điện thoại</span>
										</div>
										<Controller
											name='phone'
											control={control}
											rules={{
												required: 'Vui lòng nhập số điện thoại.',
												pattern: { value: PHONE_REGEX, message: 'Số điện thoại không hợp lệ' },
											}}
											render={({ field, fieldState }) => (
												<InputText
													id={field.name}
													{...field}
													placeholder='Nhập số điện thoại'
													onKeyDown={(event) => {
														const arr = [8];
														for (let i = 48; i <= 57; i++) {
															arr.push(i);
														}
														const numbKeyBoard = event.which;
														if (!arr.includes(numbKeyBoard)) {
															event.preventDefault();
														}
													}}
													className={classNames({ 'p-invalid': fieldState.invalid })}
												/>
											)}
										/>
										{errors?.phone && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errors?.phone?.message}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span className='warning mb-0'>*</span>
											<span style={{ fontSize: '13px' }}>Địa chỉ</span>
										</div>
										<Controller
											name='address'
											control={control}
											rules={{ required: 'Vui lòng nhập địa chỉ.', validate }}
											render={({ field, fieldState }) => (
												<InputText
													autoComplete='off'
													id={field.name}
													{...field}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													placeholder='Nhập địa chỉ'
												/>
											)}
										/>
										{errors?.address && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errors?.address?.message}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span className='warning mb-0'>*</span>
											<span style={{ fontSize: '13px' }}>Tên ngân hàng</span>
										</div>
										<Controller
											name='nameBank'
											control={control}
											rules={{ required: 'Vui lòng nhập tên ngân hàng.', validate }}
											render={({ field, fieldState }) => (
												<InputText
													autoComplete='off'
													id={field.name}
													{...field}
													className={classNames(
														{ 'p-invalid': fieldState.invalid },
														'w-full'
													)}
													placeholder='Nhập tên ngân hàng'
												/>
											)}
										/>
										{errors?.nameBank && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errors?.nameBank?.message}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span className='warning mb-0'>*</span>
											<span style={{ fontSize: '13px' }}>Số tài khoản</span>
										</div>
										<Controller
											name='number_account_payment'
											control={control}
											rules={{
												required: 'Vui lòng nhập số tài khoản.',
												maxLength: { value: 16, message: 'Số tài khoản tối đa 16 chữ số.' },
											}}
											render={({ field, fieldState }) => (
												<InputText
													autoComplete='off'
													id={field.name}
													{...field}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													placeholder='Nhập số tài khoản'
													onKeyDown={(event) => {
														const arr = [8];
														for (let i = 48; i <= 57; i++) {
															arr.push(i);
														}
														const numbKeyBoard = event.which;
														if (!arr.includes(numbKeyBoard)) {
															event.preventDefault();
														}
													}}
												/>
											)}
										/>
										{errors?.number_account_payment && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errors?.number_account_payment?.message}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span className='warning mb-0'>*</span>
											<span style={{ fontSize: '13px' }}>Tên chủ tài khoản</span>
										</div>
										<Controller
											name='name_account_payment'
											control={control}
											rules={{ required: 'Vui lòng nhập tên chủ tài khoản.', validate }}
											render={({ field, fieldState }) => (
												<InputText
													autoComplete='off'
													id={field.name}
													{...field}
													className={classNames({ 'p-invalid': fieldState.invalid })}
													placeholder='Nhập tên chủ tài khoản'
												/>
											)}
										/>
										{errors?.name_account_payment && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errors?.name_account_payment?.message}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-12 mb-0 pb-3 pt-3'>
										<p className='infor__title'>Đổi mật khẩu</p>
									</div>
									<div className='field col-12 md:col-6'>
										<div className='flex align-items-center mb-1'>
											<span style={{ fontSize: '13px' }}>Nhập mật khẩu mới</span>
										</div>
										<InputText
											value={password}
											placeholder='Nhập mật khẩu mới'
											onChange={(e) => {
												setPassword(e.target.value);
												setErrPassword('');
												setErrRePassword('');
											}}
											className={`${errPassword && 'p-invalid'}`}
											type='password'
										/>
										{errPassword && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errPassword}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-6'>
										<div className={`flex align-items-center ${!password && 'mb-1'} `}>
											<span
												style={{
													fontSize: '13px',
													marginBottom: `${password ? '4px' : '10px'}`,
												}}
												className='flex'
											>
												{' '}
												{password && <span className='warning'>*</span>}Nhập lại mật khẩu
											</span>
										</div>
										<InputText
											value={rePassword}
											placeholder='Nhập lại mật khẩu'
											onChange={(e) => {
												setRePassword(e.target.value);
												setErrRePassword('');
											}}
											className={`${errRePassword && 'p-invalid'}`}
											type='password'
										/>
										{errRePassword && (
											<span
												className='warning '
												style={{ fontSize: '13px', marginLeft: '10px', bottom: '100px' }}
											>
												{errRePassword}
											</span>
										)}
									</div>
									<div className='field col-12 md:col-12 flex justify-content-end ishidden_personal_button'>
										<Button
											label='Hủy bỏ'
											className='p-button-outlined button__personal--cancel'
											type='button'
											severity='info'
											onClick={handleCancel}
										/>
										<Button
											label='Lưu'
											className='p-button-outlined p-button-secondary infor__submit ml-2'
											type='submit'
										/>
									</div>
								</div>
							</div>
							<div className='field col-12 md:col-6 personal__block mb-0'>
								<div className=' card__bank relative'>
									<p className='card__name--bank'>{user?.data?.nameBank || ''}</p>
									<p className='card__nameUser--bank'>
										{user?.data?.name_account_payment
											? `Chủ tài khoản: ${user?.data?.name_account_payment}`
											: ''}
									</p>
									<p className='card__number--bank'>
										{user?.data?.number_account_payment ? `stk: ` : ''}
										<span>{bankNumberFormat(user?.data?.number_account_payment) || ''}</span>
									</p>
								</div>
							</div>
							<div className='field col-12 md:col-12 justify-content-end isblock_personal_button'>
								<Button
									label='Hủy bỏ'
									className='p-button-outlined button__personal--cancel'
									type='button'
									severity='info'
									onClick={handleCancel}
								/>
								<Button
									label='Lưu'
									className='p-button-outlined p-button-secondary infor__submit ml-2'
									type='submit'
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PersonalInfor;
