import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { socket } from '../../../_services/socket';
import { UserRules } from '../../../constants';
import { getNotification } from '../../../redux/notification/action';
import { getNotify } from '../../../redux/notification/notificationSlice';
import { useNavigate } from 'react-router';
import { timeAgo } from '../../../commons/message.common';
import { dashboardEmployeeRequest } from '../../../redux/overviewEmployee/actionEmployee';
import { userGetReminderRequest } from '../../../redux/auth/action';
import { useLocation } from 'react-router';
import { URL_ROUTER } from '../../../routes/routes';
import { dashboardJobsRequest } from '../../../redux/overviewJobs/actionJobs';
import { timezoneToDate } from '../../../commons/dateTime';
import { sendNotifycationService } from '../../../redux/pushNotifications/action';
import ApiService from '../../../_services/apiService';
import { resetFilter } from '../../../redux/auth/authSlice';

const api = new ApiService();

const Notification = ({ isOpenNotification, setisOpenNotification }) => {
	const user = useSelector((state) => state.auth.user);
	const notifys = useSelector((state) => state.notification.fetchAllNotification);
	const notifyupdate = useSelector((state) => state.notification.updatenotification);
	const userReminders = useSelector((state) => state.auth.userReminders);
	const location = useLocation();
	const { pathname } = location;
	const id_service = useSelector((state) => state.serviceWorkerReducer.id_service);

	const [notifications, setNotifications] = useState([]);

	const [page, setpage] = useState(10);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const soundUrl = './sound/notifycation.mp3';
	const [audio] = useState(new Audio(soundUrl));
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);

	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);

	useEffect(() => {
		if (user?.data?.id_system && user?.data?.role === UserRules?.ROLE?.ADMIN) {
			dispatch(dashboardEmployeeRequest());
		}
		dispatch(userGetReminderRequest());
	}, [dispatch]);

	const checkNameReminder = (id) => {
		if (userReminders?.data?.data) {
			const reminders = userReminders?.data?.data;

			for (let i = 0; i < reminders.length; i++) {
				if (reminders[i]?._id?.id_system === id) {
					return reminders[i]?._id?.infor_reminder;
				}
			}
		} else {
			return id;
		}
	};

	const handleInfiniteScroll = async () => {
		const element = document.querySelector('.notification__block');
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			const res = await api.get(`notification?page=${page}`);
			const allnotify = notifications.concat(res?.data);
			setNotifications(allnotify);
			if (res.data.length > 0) {
				setpage(page + 10);
			} else {
				return false;
			}
		}
	};

	useEffect(() => {
		const el = document.querySelector('.notification__block');
		el.addEventListener('scroll', handleInfiniteScroll);
		return () => {
			el.removeEventListener('scroll', handleInfiniteScroll);
		};
	});

	useEffect(() => {
		dispatch(getNotification());
	}, [dispatch]);

	useEffect(() => {
		if (notifys?.data) {
			setNotifications(notifys?.data);
		}
	}, [notifys]);

	useEffect(() => {
		if (user?.data) {
			const { id_system } = user.data;
			socket.emit('notifications-of-id-system', id_system);
		}
	}, [user]);

	socket.off('is_created_notify').on('is_created_notify', (payload) => {
		if (payload?.members.includes(user?.data?.id_system)) {
			setPlaying(true);
			const notify = {
				date: timezoneToDate(new Date()),
			};
			if (user?.data?.role === UserRules.ROLE.EDITOR || user?.data?.role === UserRules.ROLE.LEADER_EDITOR) {
				notify.url = URL_ROUTER.DASHBOARD;
			} else {
				notify.url = URL_ROUTER.WORKFLOW_MANAGEMENT;
			}
			if (id_service) {
				dispatch(sendNotifycationService({ id: id_service, ...notify }));
			}
		}
		if (payload && user?.data) {
			const { id_system } = user.data;
			socket.emit('get-members');
			socket.emit('notifications-of-id-system', id_system);
		}
	});

	socket.off('notifications-of-id-system').on('notifications-of-id-system', (payload) => {
		let allnotification = notifications;
		if (notifications[0]?._id !== payload[0]?._id) {
			allnotification = [payload[0], ...notifications];
			if (
				pathname === URL_ROUTER.JOB_OVERVIEW ||
				pathname === URL_ROUTER.WORKFLOW_MANAGEMENT ||
				pathname === URL_ROUTER.DASHBOARD
			) {
				dispatch(dashboardJobsRequest());
				dispatch(resetFilter(true));
			}
		}
		dispatch(getNotify(allnotification));
		setNotifications(allnotification);
	});

	socket.off('is_reset_notify').on('is_reset_notify', (payload) => {
		if (payload && user?.data) {
			const { id_system } = user.data;
			socket.emit('notifications-of-id-system', id_system);
		}
	});

	const handleSeenNotify = (id, id_job, status, index) => {
		const id_system = user?.data?.id_system;
		socket.emit('reset-notify', id, id_system);

		setisOpenNotification(false);
		const newNotifys = [...notifications];
		const memberChecknotifys = newNotifys[index].member_check_notify;
		const news = { ...memberChecknotifys, [id_system]: false };
		const newObject = Object.assign({}, newNotifys[index], {
			member_check_notify: news,
		});
		newNotifys.splice(index, 1, newObject);
		setNotifications(newNotifys);
		let pathname;
		switch (user?.data?.role) {
			case UserRules.ROLE.ADMIN:
				pathname = '/jobs-overview';
				break;
			case UserRules.ROLE.SALER:
				pathname = '/workflow-management';
				break;
			case UserRules.ROLE.EDITOR:
			case UserRules.ROLE.LEADER_EDITOR:
				pathname = '/';
				break;
			default:
				break;
		}

		navigate({
			pathname: pathname,
			search: `?keyword=${id_job}`,
		});
	};

	const showNotifications = () => {
		if (notifications && notifications?.length > 0) {
			return notifications?.map((notify, index) => {
				const arrTittle = notify?.title.split(' ');
				arrTittle.splice(arrTittle?.length - 1, 1, checkNameReminder(arrTittle?.[arrTittle?.length - 1]));
				return (
					<div
						className={`notification_item flex ${
							notify?.member_check_notify?.[user?.data?.id_system] && 'active'
						}`}
						key={index}
						onClick={() => handleSeenNotify(notify?._id, notify?.id_job, notify?.status, index)}
					>
						<div
							className='chat_img pr-2'
							role={
								notify?.id_job?.charAt(notify?.id_job?.length - 2) +
								notify?.id_job?.charAt(notify?.id_job?.length - 1)
							}
						></div>
						<div className='w-full'>
							<p className='notification__name'>{notify?.id_job}</p>
							<div className='notification__i'>
								<p className='notification__note'>{arrTittle.join(' ')}</p>
								{notify?.member_check_notify?.[user?.data?.id_system] && (
									<label className='notification__alert'></label>
								)}
							</div>
							<span className='notify__time'>{timeAgo(notify?._create_at)}</span>
						</div>
					</div>
				);
			});
		} else {
			return <p className='empty_data'>Trống</p>;
		}
	};

	return (
		<div className={`notification__container ${!isOpenNotification && 'hidden'}`}>
			<div className='notification__title'>
				<h5>Thông báo</h5>
			</div>
			<div className='notification__block'>
				{showNotifications()}
				{notifyupdate?.loading && <span className='loadmore'>loading...</span>}
			</div>
		</div>
	);
};

export default Notification;
