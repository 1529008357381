export const dataParse = (data, page, perpage) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				indx: indx,
				fullname: item.fullname,
				job_pending: item.job_pending,
				job_incomplete: item.job_incomplete,
				job_complete: item.job_complete,
				cost_jobs: item.cost_jobs,
				bonus: item.bonus,
				kpi: item.kpi,
				index: index + 1,
			};
			return newobject;
		});
		return result;
	}
};

export const initialDataChart = {
	datasets: [
		{
			data: [0, 0, 0],
			backgroundColor: ['#FF6384', '#FFCE56', '#0061F4'],
			hoverBackgroundColor: ['#FF6384', '#FFCE56', '#0061F4'],
		},
	],
};

export const horizontalOptions = {
	indexAxis: 'y',
	maintainAspectRatio: false,
	aspectRatio: 0.8,
	plugins: {
		legend: {
			labels: {
				color: '#495057',
			},
		},
	},
	scales: {
		x: {
			ticks: {
				color: '#495057',
			},
			grid: {
				color: '#ebedef',
			},
		},
		y: {
			ticks: {
				color: '#495057',
			},
			grid: {
				color: '#ebedef',
			},
		},
	},
};
