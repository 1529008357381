export const dataParse = (data) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index, page, perpage) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				message: '',
				indx: indx,
				reminder_customer: {
					name: item?.infor_reminder?.reminder_customer,
					group: item?.infor?.group_name_job,
				},
				reminder_saler: item?.infor_reminder?.reminder_saler,
				editor_cost: item?.cost?.editor_cost,
				end_day: item?.infor?.end_day,
				type_models: item?.infor?.type_models,
				quality_img: item?.infor?.quality_img,
				status_editor: item?.infor?.status_editor,
				status_jobs: item?.infor?.status_jobs,
				work_notes: item?.infor?.work_notes,
				request_content: item?.infor?.request_content,
				detail_content: item?.infor?.detail_content,
				photo_types: item?.infor?.photo_types,
				org_link: item?.infor?.org_link,
				finished_link: item?.infor?.finished_link,
				status_update: item?.infor?.status_jobs_update,
				status_editor_fix: item?.infor?.status_editor_fix,
				count_fixed: item?.infor?.count_fixed,
				fixed_link: item?.infor?.fixed_link,
				id_customer: item?.infor_id?.id_customer,
				id_saler: item?.infor_id?.id_saler,
				id_editor: item?.infor_id?.id_editor,
				id_system: item?.infor_id?.id_system,
				start_day: item?.infor?.start_day,
				is_approved_by_editor: item?.infor?.is_approved_by_editor,
				index: index + 1,
			};
			return newobject;
		});
		return result;
	}
};
