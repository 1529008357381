import { UserRules } from '../../constants';
import { URL_ROUTER } from '../../routes/routes';

export const defaultRoute = (position) => {
	switch (position) {
		case UserRules.ROLE.ADMIN:
			return URL_ROUTER.JOB_OVERVIEW;
		case UserRules.ROLE.SALER:
			return URL_ROUTER.WORKFLOW_MANAGEMENT;
		case UserRules.ROLE.EDITOR:
			return URL_ROUTER.DASHBOARD;
		default:
			return URL_ROUTER.DASHBOARD;
	}
};
