import { CustomerRules, UserRules } from '../../constants';

const { SALER, EDITOR, LEADER_EDITOR } = UserRules.ROLE;

export const type_status = [
	{ name: 'Hoàn thành', code: 'DONE' },
	{ name: 'Tạm hoãn yêu cầu', code: 'PENDING' },
	{ name: 'Ngưng yêu cầu', code: 'STOP' },
];

export const type_status_jobs = [
	{ name: 'Đã hoàn thành', code: 'COMPLETE' },
	{ name: 'Đang xử lý', code: 'PENDING' },
	{ name: 'Chưa hoàn thành', code: 'INCOMPLETE' },
];

export const type_status_jobs_fixed = [
	{ name: 'Reject 1', code: 'REJECT_1' },
	{ name: 'Reject 2', code: 'REJECT_2' },
	{ name: 'Reject 3', code: 'REJECT_3' },
	{ name: 'Reject 4', code: 'REJECT_4' },
	{ name: 'Reject 5', code: 'REJECT_5' },
];

export const customer_status = [
	{ name: 'Đã hoàn thành', code: CustomerRules.STATUS.DONE },
	// { name: 'Đang yêu cầu', code: CustomerRules.STATUS.REQUEST },
	{ name: 'Đang xử lý yêu cầu', code: CustomerRules.STATUS.PENDING },
	{ name: 'Ngưng yêu cầu', code: CustomerRules.STATUS.UNREQUEST },
];

export const role = [
	{ name: 'Admin', code: 'ADMIN' },
	{ name: 'Leader Editor', code: LEADER_EDITOR },
	{ name: 'Editor', code: EDITOR },
	{ name: 'Saler', code: SALER },
];

export const roleStaff = [
	// { name: 'Admin', code: 'ADMIN' },
	{ name: 'Editor', code: EDITOR },
	{ name: 'Saler', code: SALER },
];
