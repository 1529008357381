import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/table/Table';
import { table_payment } from '../../../components/table/header_table';
import { getPayRequest } from '../../../redux/payment/actionPay';
import { dataParse } from './dataParse';

import TableMobile from '../../../components/table/TableMobile';
const Payment = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth?.user);
	const payment = useSelector((state) => state.payment?.getpay?.data);
	const getFilterPage = useSelector((state) => state.auth.filter);

	const [filter, setFilter] = useState('');
	useEffect(() => {
		let id = user?.data?.id_system;
		if (id) {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			const data_search = [filterString, id];
			dispatch(getPayRequest(data_search));
		}
	}, [dispatch, filter, user, getFilterPage]);

	const DataFilter = (data) => {
		setFilter(data);
	};

	return (
		<>
			<Table
				dataTable={dataParse(payment?.data_pay, payment?.current_page, payment?.per_page)}
				loading={payment?.loading}
				DataFilter={DataFilter}
				haveTotalTable={true}
				header={table_payment}
				name_btn_add={false}
				handleCreate={false}
				totalRecords={payment?.total}
				perPage={payment?.per_page}
				currentPage={payment?.current_page}
			/>
			<TableMobile data={dataParse(payment?.data_pay)} />
		</>
	);
};

export default Payment;
