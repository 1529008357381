import { createSlice } from '@reduxjs/toolkit';

import {
	dashboardEmployeeRequest,
	addEmployeeRequest,
	editEmployeeRequest,
	deleteEmployeeRequest,
	getEmployeeRequest,
} from './actionEmployee';

const initialState = {
	dashboard: {
		loading: false,
		data: [],
		error: false,
		current_page: 1,
		per_page: 25,
		total_page: 0,
		total: 0,
	},
	user: {
		loading: false,
		data: null,
		error: false,
	},
	edituser: {
		loading: false,
		data: null,
		error: false,
	},
	deleteuser: {
		loading: false,
		data: null,
		error: false,
	},
	inforuser: {
		loading: false,
		data: null,
		error: false,
	},
};
const employeeReducer = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		resetEditUser: (state) => {
			Object.assign(
				state,
				{},
				{
					edituser: {
						loading: false,
						data: null,
						error: false,
					},
					deleteuser: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
		resetdeleteUser: (state) => {
			Object.assign(
				state,
				{},
				{
					deleteuser: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
		resetCreateUser: (state) => {
			Object.assign(
				state,
				{},
				{
					user: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
	},
	extraReducers: {
		[dashboardEmployeeRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					dashboard: {
						loading: true,
					},
				}
			);
		},
		[dashboardEmployeeRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					dashboard: {
						loading: false,
						data: action.payload.data_user,
						error: false,
						current_page: action.payload.current_page,
						per_page: action.payload.per_page,
						total_page: action.payload.total_page,
						total: action.payload.total,
					},
				}
			);
		},
		[dashboardEmployeeRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					dashboard: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},
		[addEmployeeRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					user: {
						loading: true,
					},
				}
			);
		},
		[addEmployeeRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					user: {
						loading: false,
						data: action?.payload,
						error: false,
					},
				}
			);
			state.dashboard.data.unshift(action?.payload);
		},
		[addEmployeeRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					user: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},
		[editEmployeeRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					edituser: {
						loading: true,
					},
				}
			);
		},
		[editEmployeeRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					edituser: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data.splice(
				action?.payload?.index > 0 ? action.payload.index : 0,
				1,
				action?.payload?.data
			);
		},
		[editEmployeeRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					edituser: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},
		[deleteEmployeeRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					deleteuser: {
						loading: true,
					},
				}
			);
		},
		[deleteEmployeeRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					deleteuser: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data.splice(action?.payload?.index > 0 ? action.payload.index : 0, 1);
		},
		[deleteEmployeeRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					deleteuser: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},
		[getEmployeeRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					inforuser: {
						loading: true,
					},
				}
			);
		},
		[getEmployeeRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					inforuser: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
		},
		[getEmployeeRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					inforuser: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},
	},
});
export const { resetEditUser, resetCreateUser, resetdeleteUser } = employeeReducer.actions;
export default employeeReducer.reducer;
