import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenModalInformationPayment } from '../../redux/modal/modalSlice';
import copy from 'copy-to-clipboard';
import { formatUSD, formatVND } from '../../commons/formatCost';
import { Dropdown } from 'primereact/dropdown';
import { PayRules, JobRules, UserRules } from '../../constants';
import { updatePayRequest } from '../../redux/payment/actionPay';
import { overlay } from '../../commons/overlay';
import { successToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import { InputText } from 'primereact/inputtext';

const InformationPayment = () => {
	const isOpenModalInformationPayment = useSelector((state) => state.modal.isOpenModalInformationPayment);
	const dataModalInformationPayment = useSelector((state) => state.modal.dataModalInformationPayment?.data);
	const index = useSelector((state) => state.modal.dataModalInformationPayment?.data?.index);
	const user = useSelector((state) => state.auth.user?.data);
	const paymentUpdate = useSelector((state) => state.payment.updatepay);
	const dispatch = useDispatch();
	const [status, setStatus] = useState(null);
	const [listJobs, setListJobs] = useState([]);
	const [idSystems, setidSystems] = useState([]);
	useEffect(() => {
		if (isOpenModalInformationPayment) {
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [isOpenModalInformationPayment]);

	useEffect(() => {
		if (paymentUpdate?.data) {
			dispatch(setIsOpenModalInformationPayment(false));
		}
	}, [dispatch, paymentUpdate]);

	const resetModal = React.useCallback(() => {
		dispatch(setIsOpenModalInformationPayment(false));
	}, [dispatch]);

	useEffect(() => {
		if (dataModalInformationPayment && dataModalInformationPayment?.status) {
			setStatus(dataModalInformationPayment?.status);
			setListJobs(dataModalInformationPayment?.list_id);
			const init = [];
			if (dataModalInformationPayment?.list_id?.length > 0) {
				for (let index = 0; index < dataModalInformationPayment?.list_id?.length; index++) {
					const element = dataModalInformationPayment?.list_id[index];
					init.push(element?.id_system);
				}
			}
			setidSystems(init);
		}
	}, [dataModalInformationPayment]);
	const handleSubmit = (e) => {
		e.preventDefault();
		if (status !== dataModalInformationPayment?.status) {
			const newPay = Object.assign({}, dataModalInformationPayment, {
				status: status,
			});
			const req = {
				data: {
					status: status,
					date: dataModalInformationPayment.date,
					id_staff: dataModalInformationPayment.staff_is_pay,
					id_system: idSystems,
				},
				id: user?.id_system,
				result: {
					index: index,
					data: newPay,
				},
			};
			dispatch(updatePayRequest(req));
		}
	};

	const copyToClipboard = (text) => {
		successToast(TOAST_MESSAGE.COPY);
		copy(text);
	};

	const payDropdown = [
		{ name: 'Thanh toán', code: PayRules.STATUS.PAID },
		{ name: 'Chưa thanh toán', code: PayRules.STATUS.UNPAID },
	];

	return (
		<>
			<ConfirmPopup />
			<Sidebar
				visible={isOpenModalInformationPayment}
				position='right'
				onHide={resetModal}
				className='create__job'
			>
				<div className='creat__job'>
					<div className='create__job--title flex justify-content-between' onClick={resetModal}>
						<h2 className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							Thông tin thanh toán
						</h2>
					</div>
					<form className=' grid modal__creat--job no_flex' onSubmit={handleSubmit}>
						<div className='field col-12 md:col-12 grid pr-0'>
							<div className='field col-12 md:col-6'>
								<span htmlFor='autocomplete'>Biệt danh</span>
								<InputText
									autoComplete='off'
									placeholder='Trống'
									value={dataModalInformationPayment?.reminder_staff || ''}
									className='mt-2'
									disabled
									style={{ color: 'black' }}
								/>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Mã nhân viên</span>
								<span className=' flex cursor__normal justify-content-between relative'>
									<InputText
										autoComplete='off'
										placeholder='Trống'
										value={dataModalInformationPayment?.staff_is_pay || ''}
										className='mt-2'
										disabled
										style={{ color: 'black' }}
									/>
									<img
										src='images/copy.svg'
										alt=''
										label='Bottom Right'
										onClick={() => copyToClipboard(dataModalInformationPayment?.staff_is_pay)}
										className=' copy__icon absolute '
										style={{ right: '0', top: '50%', transform: 'translateY(-30%)' }}
									/>
								</span>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Chức vụ</span>
								<InputText
									autoComplete='off'
									placeholder='Trống'
									value={UserRules.ROLE_NAME[dataModalInformationPayment?.pay_role] || ''}
									className='mt-2'
									disabled
									style={{ color: 'black' }}
								/>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Tháng</span>
								<InputText
									autoComplete='off'
									placeholder='Trống'
									value={dataModalInformationPayment?.date || ''}
									className='mt-2'
									disabled
									style={{ color: 'black' }}
								/>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Số tiền cần thanh toán</span>
								{/* <span className='p-float-label mt-3 flex cursor__normal'>
									<span className='font-bold mt-1 pr-3 block'>
										{formatVND(dataModalInformationPayment?.pay_employees)}
									</span>
								</span> */}
								<InputText
									autoComplete='off'
									placeholder='Trống'
									value={dataModalInformationPayment?.pay_employees || ''}
									className='mt-2'
									disabled
									style={{ color: 'black' }}
								/>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Trạng thái</span>
								<span className='p-float-label cursor__normal mt-2'>
									{dataModalInformationPayment?.status !== PayRules.STATUS.PAID ? (
										<Dropdown
											options={payDropdown}
											optionLabel='name'
											optionValue='code'
											value={status}
											onChange={(e) => {
												setStatus(e.value);
											}}
											placeholder=' Chọn thanh toán '
											className='dropdown__payment'
										/>
									) : (
										<InputText
											autoComplete='off'
											placeholder='Trống'
											value={PayRules.STATUS_NAME.PAID}
											style={{ color: '#3bb900' }}
											disabled
										/>
									)}
								</span>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Số tài khoản</span>
								<span className='p-float-label mt-2 flex cursor__normal justify-content-between relative'>
									<InputText
										autoComplete='off'
										placeholder='Trống'
										value={
											dataModalInformationPayment?.number_account_payment || 'Chưa có thông tin'
										}
										disabled
										style={{ color: 'black' }}
									/>
									<img
										src='images/copy.svg'
										alt=''
										label='Bottom Right'
										onClick={() =>
											copyToClipboard(dataModalInformationPayment?.number_account_payment)
										}
										className=' copy__icon absolute'
										style={{ right: '0', top: '25%' }}
									/>
								</span>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Thông tin Ngân hàng</span>
								<span className='mt-2 flex cursor__normal'>
									<InputText
										autoComplete='off'
										placeholder='Trống'
										value={dataModalInformationPayment?.nameBank || 'Chưa có thông tin'}
										disabled
										style={{ color: 'black' }}
									/>
								</span>
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='autocomplete'>Chủ tài khoản</span>
								<span className='p-float-label mt-2 flex cursor__normal'>
									<InputText
										autoComplete='off'
										placeholder='Trống'
										value={dataModalInformationPayment?.name_account_payment || 'Chưa có thông tin'}
										disabled
										style={{ color: 'black' }}
									/>
								</span>
							</div>
							{/* LIST ID_JOBS */}
							<div className='field col-12 md:col-12'>
								<span htmlFor='autocomplete'>Danh sách công việc</span>
								<div className='p-float-label cursor__normal mt-2'>
									{listJobs?.length > 0 && (
										<ul className='grid job__header'>
											<li className='col-2 md:col-2 text-center'>STT</li>
											<li className='col-4 md:col-4 text-center'>Mã nhân viên</li>
											<li className='col-5 md:col-5 text-center'>Số tiền thanh toán</li>
											<li className='col-1 md:col-1 text-center'>Copy</li>
										</ul>
									)}
									{listJobs?.length > 0 &&
										listJobs?.map((item, index) => {
											return (
												<div key={index} className='grid mt-3 mb-3'>
													<span className='font-bold text-center col-2 md:col-2 block'>
														{index + 1}
													</span>
													<span className='font-bold text-center col-4 md:col-4'>
														{item?.id_job}
													</span>
													<span className='font-bold text-center col-5 md:col-5 color__green'>
														{dataModalInformationPayment?.pay_role ===
															JobRules.ROLE.EDITOR ||
														dataModalInformationPayment?.pay_role ===
															JobRules.ROLE.LEADER_EDITOR
															? formatVND(item?.pay_amount)
															: formatUSD(item?.pay_amount)}
													</span>
													<img
														src='images/copy.svg'
														alt=''
														label='Bottom Right'
														onClick={() => copyToClipboard(item?.id_job)}
														className='cursor-pointer col-1 md:col-1 copy__icon__payments block'
													/>
												</div>
											);
										})}
								</div>
							</div>
						</div>
						<div className='btn_modal field col-12 md:col-12 grid position_bottom'>
							<div className='field col-6 md:col-6'>
								<span className='p-float-label'>
									<Button
										label='Hủy bỏ'
										className='p-button-outlined cancel--btn'
										type='button'
										onClick={resetModal}
									/>
								</span>
							</div>
							<div className='field col-6 md:col-6'>
								<span className='p-float-label'>
									<Button
										label='Cập nhật'
										className='p-button-outlined p-button-secondary confirm--btn'
										type='submit'
									/>
								</span>
							</div>
						</div>
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default InformationPayment;
