import React, { useState, useEffect, useRef } from 'react';
import Index from './rooms/Index';
import { socket } from '../../../_services/socket';
import { getFormattedDate } from '../../../commons/message.common';
import { NAME_ROOM } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenChat, userScrollTop, userViewScrollTop } from '../../../redux/messages/messageSlice';
import { Avatar } from 'primereact/avatar';
import Modal from './Modal';
import { UserRules } from '../../../constants';
import Messages from './Messages';
import { errorToast } from '../../../commons/toast';
import { timezoneToDate } from '../../../commons/dateTime';
import { URL_ROUTER } from '../../../routes/routes';
import { sendNotifycationService } from '../../../redux/pushNotifications/action';
const BoxChat = () => {
	//MEMBER
	const [members, setMembers] = useState([]);
	const [privateMemberMsg, setPrivateMemberMsg] = useState('');
	//GENERAL
	const [currentUser, setCurrentUser] = useState({});
	const [messages, setMessages] = useState('');
	const [currentRoom, setCurrentRoom] = useState(undefined);
	const [namePrivateRoom, setNamePrivateRoom] = useState('');
	const [messagesOnRoom, setMessageOnRoom] = useState([]);
	const [role, setRole] = useState('');
	//GROUP
	const [groups, setGroups] = useState([]);
	const [groups_id, setGroups_id] = useState('');
	const [membersInGroup, setMembersInGroup] = useState([]);
	const [privateGroupMsg, setPrivateGroupMsg] = useState('');

	const [multiPreviewImages, setMultiPreviewImages] = useState([]);
	const [multiImages, setMultiImages] = useState([]);
	const [images, setImages] = useState([]);
	const [scrollBottom, setScrollBottom] = useState(false);
	const id_service = useSelector((state) => state.serviceWorkerReducer.id_service);

	const dispatch = useDispatch();
	const isOpenChat = useSelector((state) => state.message.isOpenChat);
	const [isOpenCreateGroup, setIsOpenCreateGroup] = useState(false);
	const [nameModal, setNameModal] = useState('');
	const [editDataGroup, setEditDataGroup] = useState({});
	const user = useSelector((state) => state.auth.user);
	const userReminders = useSelector((state) => state.auth.userReminders);
	const groupTable = useSelector((state) => state.message.group);
	const [MemberInRoom, setMemberInRoom] = useState('');

	useEffect(() => {
		if (userReminders?.data?.data && userReminders?.data?.data?.length > 0 && groupTable?.members) {
			const rs = [];
			for (const user of groupTable.members) {
				const find = userReminders?.data?.data?.find((el) => {
					return el?._id?.id_system === user;
				});
				if (find) {
					rs.push(find?._id?.infor_reminder);
				}
			}

			if (rs?.length > 1) {
				setMemberInRoom(rs.join('-'));
			} else if (rs?.length === 1) {
				setMemberInRoom(rs[0]);
			}
		}

		if (groupTable?.members?.length === 0) {
			setMemberInRoom('');
		}
	}, [groupTable, userReminders]);

	const checkNameReminder = (id) => {
		if (user?.data?.role !== UserRules?.ROLE?.ADMIN && userReminders?.data?.data) {
			const reminders = userReminders?.data?.data;

			for (let i = 0; i < reminders.length; i++) {
				if (reminders[i]?._id?.id_system === id) {
					return reminders[i]?._id?.infor_reminder;
				}
			}
		} else {
			return id;
		}
	};
	const messageEndRef = useRef(null);
	// scroll bottom
	useEffect(() => {
		scrollToBottom();
	}, [messagesOnRoom, currentRoom]);

	useEffect(() => {
		if (scrollBottom) {
			scrollToBottom();
			setTimeout(() => {
				setScrollBottom(false);
			}, 500);
		}
	}, [scrollBottom]);

	const getFiles = React.useCallback((files) => {
		const result = [];
		const fs = [];
		const multiname = [];

		const randomName = (type) => {
			return Math.random().toString(36).slice(-10) + Date.now() + '.' + type;
		};
		for (const file of files) {
			const type = file.type.split('/')[1];

			const name = randomName(type);
			const newFile = new File([file], name, {
				type: `image/${type}`,
				lastModified: new Date(),
			});
			if (newFile?.size <= 1024 * 1024 * 2) {
				fs.push(newFile);
				multiname.push(name);
				result.push(URL.createObjectURL(file));
			} else {
				return errorToast('Ảnh không được lớn hơn 2mb');
			}
		}

		if (multiImages?.length >= 5 || multiPreviewImages?.length >= 5) {
			return errorToast('Tối đa upload 5 ảnh 1 lần .');
		}

		if (multiname.length > 5) {
			multiname.length = 5;
		}

		if (fs.length > 5) {
			fs.length = 5;
		}

		if (result.length > 5) {
			result.length = 5;
		}

		// const multiImage = multiImages.concat(multiname)
		// const multiImagePreview = multiPreviewImages.concat(result)
		getBase64(fs[0]);
		setMultiImages(multiname);
		setMultiPreviewImages(result);
	});
	const scrollToBottom = () => {
		const elem = document.querySelector('.chat-history');
		elem.scrollTop = elem.scrollHeight;
		messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	//------------------------------
	//join room
	const joinRoom = React.useCallback(
		(room) => {
			socket.emit('join-room', room);
			setCurrentRoom(room);
			setScrollBottom(true);
		},
		[setCurrentRoom]
	);
	// GET NOTIFICATION
	const soundUrl = './sound/mess.mp3';
	const [audio] = useState(new Audio(soundUrl));
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);

	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);

	const OpenNotify = () => {
		const notify = {
			date: timezoneToDate(new Date()),
		};
		if (user?.data?.role === UserRules.ROLE.EDITOR || user?.data?.role === UserRules.ROLE.LEADER_EDITOR) {
			notify.url = URL_ROUTER.DASHBOARD;
		} else {
			notify.url = URL_ROUTER.WORKFLOW_MANAGEMENT;
		}

		if (id_service) {
			dispatch(sendNotifycationService({ id: id_service, ...notify }));
		}
	};

	socket.off('notifications').on('notifications', (room, receiver, members, sender) => {
		if (room !== currentRoom || !isOpenChat) {
			if (room.includes(NAME_ROOM.USER)) {
				// socket.emit('new-notifications', room, receiver);
				const arr = members.filter((member) => member !== sender);
				if (arr.includes(user?.data?.id_system)) {
					setPlaying(true);
					OpenNotify();
				}
			} else if (room.includes(NAME_ROOM.GROUP)) {
				// socket.emit('new-notifications', room, currentUser?.id_system);
				const arr = members.filter((member) => member !== sender);
				if (arr.includes(user?.data?.id_system)) {
					setPlaying(true);
					OpenNotify();
				}
			}
		} else {
			socket.emit('reset-notifications', room, currentUser?.id_system);
		}
	});

	useEffect(() => {
		window.addEventListener(
			'dragover',
			function (e) {
				e.preventDefault();
			},
			false
		);
		window.addEventListener(
			'drop',
			function (e) {
				e.preventDefault();
			},
			false
		);
	});

	//------------------------------
	//GET MSG
	socket.off('room-messages').on('room-messages', (payload) => {
		if (payload?.length === 0) {
			setMessageOnRoom([]);
		} else {
			if (payload?.[0]?._id?.room === currentRoom) {
				setMessageOnRoom(payload);
			}
		}

		if (payload?.length > 0) {
			if (payload?.[0]?.messagesByDate?.[0]?.type === NAME_ROOM.GROUP) {
				socket.emit('groups', user?.data?.id_system);
			}
		}
	});

	//------------------------------
	//SUBMIT MESSAGE

	function getBase64(file) {
		if (file) {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				setImages(reader.result);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		} else {
			return '';
		}
	}
	const handleSubmit = (e) => {
		e?.preventDefault();
		if (messages !== '' || multiImages.length > 0) {
			const time = new Date().getTime();
			const nameRoom = currentRoom;
			const toDayDate = getFormattedDate();
			let allmembers = membersInGroup;
			let type = NAME_ROOM.GROUP;
			if (currentRoom !== groups_id) {
				if (nameRoom?.includes(NAME_ROOM.USER)) {
					allmembers = [privateMemberMsg, currentUser?.id_system];
					type = NAME_ROOM.USER;
					dispatch(userScrollTop(true));
				}
			} else {
				dispatch(userViewScrollTop(true));
			}

			// const fileData = new FormData();
			// for (let i = 0; i < images.length; i++) {
			// 	fileData.append('images', images[i]);
			// }

			if (currentRoom !== groups_id) {
				//MEMBER
				// dispatch(postImagesMessage(fileData));
				setScrollBottom(true);

				setTimeout(() => {
					socket.emit(
						'message-room',
						nameRoom,
						messages,
						currentUser?.id_system,
						time,
						toDayDate,
						allmembers,
						type,
						'',
						privateMemberMsg,
						images,
						multiImages?.[0]
					);
					setMessages('');
					setMultiPreviewImages([]);
					setMultiImages([]);
					setImages([]);
				}, 500);
			} else {
				//GROUP

				const allmember = allmembers;

				let editorReceived = '';
				for (const member of allmember) {
					if (member.includes(UserRules._ROLE.EDITOR)) {
						editorReceived = member;
						break;
					}
				}

				if (!nameRoom) {
					errorToast('Name room not found');
				}

				if (!currentUser?.id_system) {
					errorToast('Cant not check your ID , please contact your admin');
				}

				if (!time) {
					errorToast('Time not found');
				}

				if (!toDayDate) {
					errorToast('Date not found');
				}
				if (!groups_id) {
					errorToast('Group id not found');
				}

				if (allmember?.length === 0) {
					errorToast('members on room id not found');
				}

				setScrollBottom(true);
				setTimeout(() => {
					socket.emit(
						'message-room',
						nameRoom,
						messages,
						currentUser?.id_system,
						time,
						toDayDate,
						allmember,
						NAME_ROOM.GROUP,
						groups_id,
						editorReceived,
						images,
						multiImages?.[0]
					);
					setMessages('');
					setMultiPreviewImages([]);
					setMultiImages([]);
					setImages([]);
				}, 500);
			}
			socket.emit('reset-notifications', nameRoom, currentUser?.id_system);
		}
	};

	const handlePaste = (e) => {
		const clipboardItems = e.clipboardData.items;
		const items = [].slice.call(clipboardItems).filter(function (item) {
			// Filter the image items only
			return /^image\//.test(item.type);
		});
		if (items.length === 0) {
			return;
		}
		e.stopPropagation();
		e.preventDefault();
		const item = items[0];
		const blob = item.getAsFile();
		const imageEle = document.getElementsByClassName('chat-message')?.[0];
		imageEle.src = URL.createObjectURL(blob);
		let file = new File([blob], 'file name', { type: 'image/jpeg', lastModified: new Date().getTime() }, 'utf-8');
		let container = new DataTransfer();
		container.items.add(file);
		const { files } = container;
		getFiles(files);
	};
	//PRESS ENTER TO SUBMIT
	useEffect(() => {
		const submitForm = (event) => {
			const btn = document.querySelector('.btn__sendChat');
			if (btn !== null && event.key === 'Enter') {
				event.preventDefault();
				handleSubmit();
			}
		};
		window.addEventListener('keypress', submitForm);
		return () => {
			window.removeEventListener('keypress', submitForm);
		};
	});
	//------------------------------

	// const handleEditGroup = () => {
	// 	setIsOpenCreateGroup(true);
	// 	setNameModal(NAME_ROOM.EDIT);
	// 	const data = {
	// 		name: namePrivateRoom,
	// 		members: membersInGroup,
	// 		group_id: groups_id,
	// 	};
	// 	setEditDataGroup(data);
	// };

	const replaceName = (id) => {
		if (currentUser?.role === UserRules.ROLE.ADMIN) {
			for (const member of members) {
				if (member?.id_system === id) {
					return member?.fullname;
				}
			}
		}
	};

	const handleFiles = async (e) => {
		const files = e.target.files;
		getFiles(files);
	};

	const handleDrop = function (e) {
		e.preventDefault();
		const clipboardItems = e?.dataTransfer?.items;
		const items = [].slice.call(clipboardItems).filter(function (item) {
			// Filter the image items only
			return /^image\//.test(item.type);
		});
		if (items.length === 0) {
			return;
		}
		const item = items[0];
		const blob = item.getAsFile();
		const imageEle = document.getElementsByClassName('chat-message')?.[0];
		imageEle.src = URL.createObjectURL(blob);
		let file = new File([blob], 'file name', { type: 'image/jpeg', lastModified: new Date().getTime() }, 'utf-8');
		let container = new DataTransfer();
		container.items.add(file);
		const { files } = container;
		getFiles(files);
	};

	const handleDeleteImgPreview = (img, index) => {
		const images = multiPreviewImages.filter((item) => {
			return item !== img;
		});
		multiImages.splice(index, 1);
		setMultiPreviewImages(images);
	};

	return (
		<div className={`chat-container  ${!isOpenChat && 'hidden'}`}>
			{user?.data?.role === UserRules.ROLE.ADMIN && (
				<>
					<div
						className='chat__createGroup hidden'
						onClick={() => {
							setNameModal(NAME_ROOM.CREATE);
							setIsOpenCreateGroup(!isOpenCreateGroup);
							setEditDataGroup({});
						}}
					></div>
					<Modal
						isOpenCreateGroup={isOpenCreateGroup}
						setIsOpenCreateGroup={setIsOpenCreateGroup}
						nameModal={nameModal}
						editDataGroup={editDataGroup}
						setEditDataGroup={setEditDataGroup}
						setMembersInGroup={setMembersInGroup}
					/>
				</>
			)}
			<Index
				members={members}
				setCurrentUser={setCurrentUser}
				setMembers={setMembers}
				currentUser={currentUser}
				currentRoom={currentRoom}
				setCurrentRoom={setCurrentRoom}
				setMessageOnRoom={setMessageOnRoom}
				privateMemberMsg={privateMemberMsg}
				setPrivateMemberMsg={setPrivateMemberMsg}
				privateGroupMsg={privateGroupMsg}
				setPrivateGroupMsg={setPrivateGroupMsg}
				setRole={setRole}
				joinRoom={joinRoom}
				setGroups={setGroups}
				groups={groups}
				membersInGroup={membersInGroup}
				setMembersInGroup={setMembersInGroup}
				setGroups_id={setGroups_id}
				setNamePrivateRoom={setNamePrivateRoom}
			/>

			<div
				className='chat'
				onDragEnter={(e) => {
					e.preventDefault();
				}}
				onDrop={handleDrop}
			>
				<div className={`chat-header ${currentUser.role === UserRules.ROLE_ADMIN && 'active'}`}>
					<div className='chat__close' onClick={() => dispatch(setIsOpenChat(false))}></div>
					{namePrivateRoom && (
						<div className='chat_img' role={namePrivateRoom?.charAt(0) + namePrivateRoom?.charAt(1)}></div>
					)}
					<div className='chat-about'>
						<div className='chat-with'>
							{(currentUser?.role === UserRules.ROLE.ADMIN
								? replaceName(privateMemberMsg)
								: checkNameReminder(privateMemberMsg)) || checkNameReminder(namePrivateRoom)}
						</div>
						<div className='chat-num-messages'>{MemberInRoom}</div>
						<div className='chat-num-messages hidden'>{role}</div>
					</div>
				</div>
				{/* end chat-header */}
				<div className={`chat-history relative`}>
					<ul className='group__message'>
						<Messages messagesOnRoom={messagesOnRoom} currentUser={currentUser} />
					</ul>
					<div ref={messageEndRef} className='footer__msg' />
				</div>

				{/* end chat-history */}
				{
					<form
						onSubmit={handleSubmit}
						onPaste={handlePaste}
						className={`chat-message align-items-end clearfix ${!currentRoom ? 'hidden' : ''}`}
					>
						<div className={`w-full box__chat ${multiPreviewImages.length > 0 && 'pt-1'}`}>
							<textarea
								name='message-to-send m-0'
								id='message-to-send'
								rows={1}
								value={messages}
								onChange={(e) => setMessages(e.target.value)}
							/>
						</div>
						<div className='justify-content-between flex group__chat--btn'>
							<div className='chat__file'>
								<label htmlFor='file_chat'>
									<input
										type='file'
										className='hidden w-0 h-0'
										id='file_chat'
										onChange={handleFiles}
										// multiple
										accept='image/png, image/jpeg, image/jpg '
									/>
								</label>
							</div>
							<div className={`preview__imgs`}>
								{multiPreviewImages.length > 0 &&
									multiPreviewImages.map((image, index) => {
										return (
											<div
												className='div_preview pl-1'
												onClick={() => handleDeleteImgPreview(image, index)}
												key={index}
											>
												<Avatar
													className='p-overlay-badge'
													image={image}
													size='slarge'
												></Avatar>
											</div>
										);
									})}
							</div>
							<button
								className={`${isOpenChat && 'btn__sendChat'} `}
								style={{
									backgroundColor: `${messages?.length > 0 ? '#1890ff' : 'inherit'}`,
									color: `${messages?.length > 0 ? '#fff' : 'inherit'}`,
								}}
							>
								Send
							</button>
						</div>
					</form>
				}
			</div>
		</div>
	);
};

export default BoxChat;
