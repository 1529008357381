import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const getPayRequest = createAsyncThunk('getPays', async (data, { rejectWithValue }) => {
	try {
		const search = typeof data[0] === 'string' ? data[0] : '';
		const res = await api.get(`pays/${data[1]}${search}`);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const updatePayRequest = createAsyncThunk('updatepays', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`pays/${data.id}`, data.data);
		if (res.data) {
			successToast(TOAST_MESSAGE.UPDATE_PAYMENT_SUCCESS);
			return data.result;
		}
	} catch (error) {
		errorToast(TOAST_MESSAGE.UPDATE_PAYMENT_FAILED);
		return rejectWithValue(error?.response?.data);
	}
});

export const getPayStaffRequest = createAsyncThunk('getPayStaffs', async (data, { rejectWithValue }) => {
	try {
		const search = typeof data === 'string' ? data : '';
		const res = await api.get(`pays/payment-staff${search}`);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});
