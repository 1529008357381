import { createSlice } from '@reduxjs/toolkit';

import {
	dashboardJobsRequest,
	addJobsRequest,
	deleteJobsRequest,
	editJobsRequest,
	getJobsRequest,
	getJobsAdminRequest,
	doneJobsRequest,
	editorEditJobsRequest,
	approvedJobsRequest,
	cancelJobsbyEditorRequest,
} from './actionJobs';

const initialState = {
	dashboard: {
		loading: false,
		data: null,
		error: false,
		current_page: 1,
		per_page: 25,
		total_page: 0,
		total: 0,
	},
	addjobs: {
		loading: false,
		data: null,
		error: false,
	},
	editjobs: {
		loading: false,
		data: null,
		error: false,
	},
	deletejobs: {
		loading: false,
		data: null,
		error: false,
	},
	approvedjobs: {
		loading: false,
		data: null,
		error: false,
	},
	canceljobsbyeditor: {
		loading: false,
		data: null,
		error: false,
	},
	getjobs: {
		loading: false,
		data: null,
		error: false,
	},
	donejobs: {
		loading: false,
		data: null,
		error: false,
	},
};
const jobsReducer = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		resetJobRequest: (state) => {
			Object.assign(
				state,
				{},
				{
					donejobs: {
						loading: false,
						data: null,
						error: false,
					},
					addjobs: {
						loading: false,
						data: null,
						error: false,
					},
					editjobs: {
						loading: false,
						data: null,
						error: false,
					},
					deletejobs: {
						loading: false,
						data: null,
						error: false,
					},
					approvedjobs: {
						loading: false,
						data: null,
						error: false,
					},
					canceljobsbyeditor: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
		resetJobCreated: (state) => {
			Object.assign(
				state,
				{},
				{
					addjobs: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
		resetJobdeleted: (state) => {
			Object.assign(
				state,
				{},
				{
					deletejobs: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
	},
	extraReducers: {
		[dashboardJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					dashboard: {
						loading: true,
					},
				}
			);
		},
		[dashboardJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					dashboard: {
						loading: false,
						data: action.payload.data_jobs,
						error: false,
						current_page: action.payload.current_page,
						per_page: action.payload.per_page,
						total_page: action.payload.total_page,
						total: action.payload.total,
					},
				}
			);
		},
		[dashboardJobsRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					dashboard: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},
		[addJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					addjobs: {
						loading: true,
					},
				}
			);
		},
		[addJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					addjobs: {
						loading: false,
						data: action?.payload,
						error: false,
					},
				}
			);
			state?.dashboard?.data?.unshift(action?.payload);
		},
		[addJobsRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					addjobs: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},
		[editJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					editjobs: {
						loading: true,
					},
				}
			);
		},
		[editJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					editjobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data?.splice(
				action?.payload?.index > 0 ? action.payload.index : 0,
				1,
				action?.payload?.data
			);
		},
		[editJobsRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					editjobs: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},
		///// Editor done link
		[editorEditJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					editjobs: {
						loading: true,
					},
				}
			);
		},
		[editorEditJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					editjobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data?.splice(
				action?.payload?.index > 0 ? action.payload.index : 0,
				1,
				action?.payload?.data
			);
		},
		[editorEditJobsRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					editjobs: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},

		[deleteJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					deletejobs: {
						loading: true,
					},
				}
			);
		},
		[deleteJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					deletejobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data?.splice(action?.payload?.index > 0 ? action.payload.index : 0, 1);
		},
		[deleteJobsRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					deletejobs: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},

		[approvedJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					approvedjobs: {
						loading: true,
					},
				}
			);
		},
		[approvedJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					approvedjobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data?.splice(
				action?.payload?.index > 0 ? action.payload.index : 0,
				1,
				action?.payload?.data
			);
		},
		[approvedJobsRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					approvedjobs: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},

		[cancelJobsbyEditorRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					canceljobsbyeditor: {
						loading: true,
					},
				}
			);
		},
		[cancelJobsbyEditorRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					canceljobsbyeditor: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data?.splice(action?.payload?.index > 0 ? action.payload.index : 0, 1);
		},
		[cancelJobsbyEditorRequest.rejected]: (state) => {
			Object.assign(
				state,
				{},
				{
					canceljobsbyeditor: {
						loading: false,
						data: null,
						error: true,
					},
				}
			);
		},

		[getJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					getjobs: {
						loading: true,
					},
				}
			);
		},
		[getJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					getjobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
		},
		[getJobsRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					getjobs: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},
		[getJobsAdminRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					getjobs: {
						loading: true,
					},
				}
			);
		},
		[getJobsAdminRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					getjobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
		},
		[getJobsAdminRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					getjobs: {
						loading: false,
						data: action?.payload,
						error: true,
					},
				}
			);
		},
		[doneJobsRequest.pending]: (state) => {
			Object.assign(
				state,
				{},
				{
					donejobs: {
						loading: true,
					},
				}
			);
		},
		[doneJobsRequest.fulfilled]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					donejobs: {
						loading: false,
						data: action?.payload?.data,
						error: false,
					},
				}
			);
			state.dashboard.data?.splice(
				action?.payload?.index > 0 ? action.payload.index : 0,
				1,
				action?.payload?.data
			);
		},
		[doneJobsRequest.rejected]: (state, action) => {
			Object.assign(
				state,
				{},
				{
					donejobs: {
						loading: false,
						data: action?.payload,
						error: true,
					},
					editjobs: {
						loading: false,
						data: null,
						error: false,
					},
				}
			);
		},
	},
});
export const { resetJobRequest, resetJobCreated, resetJobdeleted } = jobsReducer.actions;
export default jobsReducer.reducer;
