import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FILTER_MESSAGES } from '../../commons/Messages/filter.message';
import { useLocation } from 'react-router-dom';
import { URL_ROUTER } from '../../routes/routes';

const FilterModalMobile = ({
	openModal,
	handleCloseModal,
	dates,
	setDates,
	options,
	status,
	setStatus,
	handleReset,
}) => {
	const location = useLocation();
	const { pathname } = location;

	return (
		<Sidebar visible={openModal} position='right' onHide={handleCloseModal} className='create__job relative'>
			<div className=''>
				<div
					className='create__job--title flex align-items-center justify-content-between'
					onClick={() => {
						handleCloseModal(false);
					}}
				>
					<h2 className='flex align-items-center'>
						<img src='/images/mobile/left.svg' className='modal__back' alt='' />
						Bộ lọc tìm kiếm
					</h2>
				</div>
				<div className=' mt-2 h-full p-2 flex justify-content-between flex-column '>
					<div>
						<div className='w-full'>
							<span className='original__link mb-1 block' style={{ fontSize: '13px' }}>
								Tìm kiếm theo ngày
							</span>
							<Calendar
								id=''
								className='w-full mb-2'
								value={dates}
								onChange={(e) => {
									setDates(e.value);
								}}
								selectionMode='range'
								placeholder='dd/mm/yyyy - dd/mm/yyyy'
							/>
						</div>
						{pathname !== URL_ROUTER.JOB_PERFORMANCE && pathname !== URL_ROUTER.CUSTOMER && (
							<div className='w-full'>
								<span className='original__link mb-1 block' style={{ fontSize: '13px' }}>
									Trạng thái
								</span>
								<Dropdown
									options={options}
									optionLabel='status'
									optionValue='id'
									value={status}
									onChange={(e) => setStatus(e.target.value)}
									placeholder={FILTER_MESSAGES.STATUS}
									className='mr-0'
									style={{ width: 'inherit' }}
								/>
							</div>
						)}
						<div className='w-full flex justify-content-end'>
							<img
								src='images/reset.svg'
								alt=''
								onClick={handleReset}
								className={`mt-3 ${pathname === URL_ROUTER.JOB_PERFORMANCE && 'pl-2'}`}
							/>
						</div>
					</div>

					<div className='btn_modal absolute' style={{ bottom: '10px', left: '10px', right: '10px' }}>
						<Button
							label='Hủy bỏ'
							className='p-button-outlined cancel--btn'
							onClick={() => {
								handleCloseModal(false);
							}}
							type='button'
						/>
					</div>
				</div>
			</div>
		</Sidebar>
	);
};

export default FilterModalMobile;
