import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { userloginRequest } from '../../redux/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast } from '../../commons/toast';
import { URL_ROUTER } from '../../routes/routes';
import { UserRules } from '../../constants';
import { DEFAULT_MESSAGES } from '../../commons/Messages/default.message';
import Index from './Index';

const Login = () => {
	const navigate = useNavigate();
	const { register, handleSubmit, watch } = useForm();
	const [haveSeenPwd, setHaveSeenPwd] = useState(false);
	const [errors, setError] = useState();
	const user = useSelector((state) => state.auth.token);
	const role = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const location = useLocation();
	const { pathname } = location;

	const onSubmit = (e) => {
		e.preventDefault();
		const data = watch();
		const { username, password } = data;

		if (username && password && username !== '' && password !== '') {
			const result = {
				data: data,
			};
			setError();
			dispatch(userloginRequest(result));
		} else {
			const errorData = { username: '', password: '' };
			if (username === '') {
				errorData.username = DEFAULT_MESSAGES.ERRORS.EMAIL.REQUIRED;
			}
			if (password === '') {
				errorData.password = DEFAULT_MESSAGES.ERRORS.PASSWORD.REQUIRED;
			}
			setError(errorData);
		}
	};

	useEffect(() => {
		if (pathname === URL_ROUTER.LOGIN && user?.isAuth) {
			if (role?.data?.role === UserRules.ROLE.ADMIN) {
				navigate(URL_ROUTER.JOB_OVERVIEW);
			} else if (role?.data?.role === UserRules.ROLE.SALER) {
				navigate(URL_ROUTER.WORKFLOW_MANAGEMENT);
			} else if (
				role?.data?.role === UserRules.ROLE.LEADER_EDITOR ||
				role?.data?.role === UserRules.ROLE.EDITOR
			) {
				navigate(URL_ROUTER.DASHBOARD);
			} else {
				navigate(URL_ROUTER.NOT_FOUND);
			}
		}
	}, [pathname, role]);

	useEffect(() => {
		if (user?.error && !user?.data) {
			errorToast(DEFAULT_MESSAGES.ERRORS.LOGIN_ERR);
		}
	}, [user]);

	return (
		<Index title={'Đăng nhập'}>
			<form onSubmit={(e) => handleSubmit(onSubmit(e))}>
				<div className='form__email'>
					<p>Tên đăng nhập</p>
					<div className='form__input'>
						<input
							type='text'
							placeholder='Nhập tên đăng nhập'
							name='username'
							id='username'
							{...register('username', {
								required: { value: true, message: 'Vui lòng nhập tên đăng nhập' },
								maxLength: 55,
							})}
						/>
					</div>
					{errors?.username && <span className='form__error'>{errors?.username}</span>}
				</div>
				<div className='form__password'>
					<p>Mật khẩu</p>
					<div className='form__input'>
						<input
							type={haveSeenPwd ? 'text' : 'password'}
							placeholder='Nhập mật khẩu'
							name='password'
							id='password'
							{...register('password', {
								required: { value: true, message: 'Vui lòng nhập mật khẩu' },
							})}
						/>
						<img
							className='show__password'
							src={haveSeenPwd ? '../../images/closed_eye.png' : '../../images/eye.png'}
							alt=''
							onClick={() => setHaveSeenPwd(!haveSeenPwd)}
						/>
					</div>
					{errors?.password && <span className='form__error'>{errors?.password}</span>}
				</div>
				<div className='form__save--information'>
					<p className='form__btn-forgotpwd' onClick={() => navigate('/forgot-password')}>
						Quên mật khẩu
					</p>
				</div>
				<div className='form__btn-submit'>
					<button type='submit'>Đăng nhập</button>
				</div>
			</form>
			<div className='App'></div>
		</Index>
	);
};

export default Login;
