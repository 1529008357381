import { useState, useEffect } from 'react';
import * as serviceWorker from '../../_services/serviceWorker';
import { sendNotifycationService, createServiceId } from '../../redux/pushNotifications/action';
import { useDispatch, useSelector } from 'react-redux';

const pushNotificationSupported = serviceWorker.isPushNotificationSupported();

export default function usePushNotifications() {
	const dispatch = useDispatch();
	const [userConsent, setSuserConsent] = useState();
	const id_service = useSelector((state) => state.serviceWorkerReducer.id_service);

	const [userSubscription, setUserSubscription] = useState(null);
	const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState();

	const isConsentGranted = userConsent === 'granted';

	useEffect(() => {
		if (pushNotificationSupported && isConsentGranted) {
			onClickSusbribeToPushNotification();
		}
	}, [pushNotificationSupported, isConsentGranted]);

	useEffect(() => {
		if (pushNotificationSupported && userSubscription) {
			sendSubscriptionToPushServer();
		}
	}, [userSubscription, pushNotificationSupported]);

	useEffect(() => {
		if (id_service) {
			setPushServerSubscriptionId(id_service);
		}
	}, [id_service]);

	useEffect(() => {
		if (pushNotificationSupported) {
			serviceWorker.register();
		}
	}, []);
	//if the push notifications are supported, registers the service worker
	//this effect runs only the first render

	useEffect(() => {
		const getExixtingSubscription = async () => {
			const existingSubscription = await serviceWorker.getUserSubscription();
			setUserSubscription(existingSubscription);
		};
		getExixtingSubscription();
	}, []);
	//Retrieve if there is any push notification subscription for the registered service worker
	// this use effect runs only in the first render

	/**
	 * define a click handler that asks the user permission,
	 * it uses the setSuserConsent state, to set the consent of the user
	 * If the user denies the consent, an error is created with the setError hook
	 */
	const AskUserPermission = () => {
		serviceWorker.askUserPermission().then((consent) => {
			setSuserConsent(consent);
		});
	};
	//

	/**
	 * define a click handler that creates a push notification subscription.
	 * Once the subscription is created, it uses the setUserSubscription hook
	 */
	const onClickSusbribeToPushNotification = () => {
		serviceWorker
			.createNotificationSubscription()
			.then(function (subscrition) {
				setUserSubscription(subscrition);
			})
			.catch((err) => {
				console.error(
					"Couldn't create the notification subscription",
					err,
					'name:',
					err.name,
					'message:',
					err.message,
					'code:',
					err.code
				);
			});
	};

	/**
	 * define a click handler that sends the push susbcribtion to the push server.
	 * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
	 */
	const sendSubscriptionToPushServer = () => {
		dispatch(createServiceId({ data: userSubscription }));
	};

	/**
	 * define a click handler that requests the push server to send a notification, passing the id of the saved subscription
	 */
	const sendNotificationService = async (data) => {
		if (pushServerSubscriptionId) {
			dispatch(sendNotifycationService({ id: pushServerSubscriptionId, ...data }));
		}
	};

	return {
		sendNotificationService,
		pushNotificationSupported,
		AskUserPermission,
	};
}
