import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogoutRequest, userChangeStatus } from '../../redux/auth/action';
import { useNavigate } from 'react-router';
import { UserRules } from '../../constants';
import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';

const ToggleMenu = ({ openMenu, setOpenMenu }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	const changeStatus = (status) => {
		let newUser = Object.assign({}, user?.data, {
			status: status,
		});

		const result = {
			id: user?.data?.id_system,
			status: status,
			newUser: newUser,
		};
		dispatch(userChangeStatus(result));
	};

	let items = [
		{
			label: 'Trạng thái',
			icon: () => iconMenu('arrow_right'),
			items: [
				{
					label: 'ONLINE',
					icon: () => iconMenu('online'),
					command: () => {
						const status = UserRules.STATUS.ONLINE;
						changeStatus(status);
					},
				},
				{
					label: 'BUSY',
					icon: () => iconMenu('busy'),
					command: () => {
						const status = UserRules.STATUS.LEAVE;
						changeStatus(status);
					},
				},

				{
					label: 'OFFLINE',
					icon: () => iconMenu('offline'),
					command: () => {
						const status = UserRules.STATUS.OFFLINE;
						changeStatus(status);
					},
				},
			],
		},
		{ separator: true },
		{
			command: () => {
				navigate('/personal-information');
				setOpenMenu(!openMenu);
			},
			template: (item, options) => {
				return (
					<button
						onClick={(e) => options.onClick(e)}
						className={classNames(options.className, 'w-full p-link flex align-items-center')}
					>
						{iconMenu('user')}
						<div className='flex flex-column align'>
							<span className='font-bold'>Thông tin cá nhân</span>
							{/* <span className="text-sm">{user?.data?.role}</span> */}
						</div>
					</button>
				);
			},
		},
		{
			label: 'Thoát',
			icon: () => iconMenu('logout'),
			command: () => {
				dispatch(userLogoutRequest());
			},
		},
	];

	const iconMenu = (image) => {
		return <img src={`images/${image}.svg`} alt='' style={{ paddingRight: '10px' }} />;
	};

	return (
		<div className='header__menu'>
			<Menu model={items} hidden={!openMenu} />
		</div>
	);
};

export default ToggleMenu;
