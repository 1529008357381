import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DatePicker from './DatePicker';
import { useNavigate, useLocation } from 'react-router';
import { dateString } from '../../commons/dateTime';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { resetKpis } from '../../redux/employeePerformance/performanceSlice';
import { getEmployeePerformance } from '../../redux/employeePerformance/action';
import { InputText } from 'primereact/inputtext';
import { URL_ROUTER } from '../../routes/routes';
import { roleStaff } from '../../modules/modal/dropDown';
import { TIMEOUT } from '../../constants';
import { FILTER_MESSAGES } from '../../commons/Messages/filter.message';
import { resetFilter } from '../../redux/auth/authSlice';
import FilterModalMobile from '../../modules/modal/filterModal.mobile';

const Filter = ({
	DataFilter,
	sortBy,
	sortValue,
	setSortBy,
	setSortValue,
	search,
	setsearch,
	dropdown,
	setCurrentLocation,
}) => {
	const queryParams = new URLSearchParams(window.location.search);
	const keywordURL = queryParams.get('keyword');
	const sort_byURL = queryParams.get('sort_by');
	const sortValueURL = queryParams.get('sort_value');
	const statusURL = queryParams.get('status');
	const start_dateURL = queryParams.get('start_date');
	const end_dateURL = queryParams.get('end_date');
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const pageURL = Number(queryParams?.get('page'));
	const perpageURL = Number(queryParams?.get('perpage'));
	const dispatch = useDispatch();
	const isResetFilterByURL = useSelector((state) => state.auth.isResetFilterByURL);
	const [openModalFilter, setOpenModalFilter] = useState(false);

	useEffect(() => {
		if (isResetFilterByURL) {
			handleReset();
			dispatch(resetFilter());
			setTimeout(() => {
				dispatch(resetFilter(false));
			}, 500);
			setCurrentLocation(0);
		}
	}, [isResetFilterByURL]);
	const location = useLocation();
	const { pathname } = location;
	const [dates, setDates] = useState(
		start_dateURL && end_dateURL
			? [new Date(dateString(start_dateURL)), new Date(dateString(end_dateURL))]
			: pathname === URL_ROUTER.PAYMENT_MANAGEMENT || pathname === URL_ROUTER.JOB_PERFORMANCE
			? [new Date(new Date().setDate(1)), new Date(new Date().setMonth(new Date().getMonth() + 1, 0))]
			: undefined
	);
	const [status, setStatus] = useState('');
	const [keyword, setKeyWord] = useState('');
	const [valueKeyword, setValueKeyWord] = useState('');
	const [user, setUser] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (keywordURL) {
			setKeyWord(keywordURL);
			setValueKeyWord(keywordURL);
		}

		if (sort_byURL) {
			setSortBy(sort_byURL);
		}

		if (sortValueURL) {
			setSortValue(sortValueURL);
		}
	}, [keywordURL, sort_byURL, setSortBy, sortValueURL, setSortValue, statusURL]);

	function convertDate(arr) {
		const data = [];
		for (let i = 0; i < arr.length; i++) {
			const date = new Date(arr[i]),
				mnth = ('0' + (date.getMonth() + 1)).slice(-2),
				day = ('0' + date.getDate()).slice(-2);
			data.push([day, mnth, date.getFullYear()].join('-'));
		}
		return data;
	}

	const handleReset = () => {
		if (pathname !== URL_ROUTER.PAYMENT_MANAGEMENT) {
			setDates(null);
		}
		setStatus('');
		setKeyWord('');
		setValueKeyWord('');
		setUser(null);
		setsearch('');
		if (search) {
			setCurrentLocation(0);
		}
		dispatch(resetKpis());
		if (pathname === URL_ROUTER.JOB_PERFORMANCE) {
			dispatch(getEmployeePerformance());
		}
	};

	useEffect(() => {
		let data = {};
		if (dates?.length > 0 && dates[1]) {
			const arr = convertDate(dates);
			data.start_date = arr[0];
			data.end_date = arr[1];
		}

		if (status !== '') {
			data.status = status?.trim();
		}

		if (user) {
			data.role = user?.code;
		}

		data.keyword = valueKeyword;

		if (sortBy !== '' && sortValue !== '' && sortBy) {
			data.sort_by = sortBy;
			data.sort_value = sortValue;
		}

		if (Object.keys(data)?.length > 0) {
			let result = '';
			Object.keys(data).forEach((item) => {
				result += `&${item}=${data[item]}`;
			});
			if (result !== '') {
				if (valueKeyword === '') {
					let removeKey = result.replace('keyword=', '');
					removeKey = result.replace('&keyword=', '');
					result = removeKey;
				}

				const newResult = result.replace('&', '?').trim();
				setsearch(newResult);
			}
		}
	}, [dates, valueKeyword, sortBy, sortValue, status, setsearch, user]);

	const sendFilter = React.useCallback(() => {
		DataFilter(search);
	}, [search, navigate, pathname, DataFilter, pageURL, perpageURL]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			sendFilter();
		}, TIMEOUT);
		return () => clearTimeout(timeout);
	}, [sendFilter]);

	const debouncedSearch = React.useMemo(
		() =>
			debounce((value) => {
				setValueKeyWord(value);
			}, 750),
		[]
	);
	const handleSearchKeyWord = (e) => {
		setKeyWord(e);
		debouncedSearch(e);
		setCurrentLocation(0);
	};

	useEffect(() => {
		const handleClickOutSide = (e) => {
			const el = document.querySelector('.page__filter');
			if (isOpenFilter && !el?.contains(e.target)) {
				setIsOpenFilter(false);
			}
		};
		window.addEventListener('mousedown', handleClickOutSide);

		return () => {
			window.removeEventListener('mousedown', handleClickOutSide);
		};
	}, [isOpenFilter]);

	return (
		<>
			<FilterModalMobile
				openModal={openModalFilter}
				handleCloseModal={setOpenModalFilter}
				dates={dates}
				setDates={(e) => setDates(e)}
				options={dropdown}
				status={status}
				setStatus={setStatus}
				handleReset={handleReset}
			/>
			<div className={`page__filter align-items-center flex grid  `}>
				<Box
					component='form'
					sx={{
						'& .MuiTextField-root': { m: 1, width: '25ch' },
					}}
					noValidate
					autoComplete='off'
					className='filter__search field col-6 md:col-3'
				>
					{pathname !== URL_ROUTER.JOB_PERFORMANCE ? (
						<>
							<InputText
								id='outlined-size-small'
								value={keyword}
								size='small'
								className='filter__input--search'
								onChange={(e) => handleSearchKeyWord(e.target.value)}
								placeholder={FILTER_MESSAGES.SEARCH}
							/>
							<img src='../../images/search.svg' alt='' className='filter__btn--search' />
						</>
					) : (
						<Dropdown
							options={roleStaff}
							optionLabel='name'
							value={user}
							onChange={(e) => setUser(e.value)}
							placeholder='Saler'
							className='filter__user '
						/>
					)}
				</Box>
				<DatePicker dates={dates} setDates={setDates} setCurrentLocation={setCurrentLocation} />
				{pathname !== URL_ROUTER.JOB_PERFORMANCE && pathname !== URL_ROUTER.CUSTOMER ? (
					<Dropdown
						options={dropdown}
						optionLabel='status'
						optionValue='id'
						value={status}
						onChange={(e) => {
							setStatus(e.target.value);
							setCurrentLocation(0);
						}}
						placeholder={FILTER_MESSAGES.STATUS}
						className='ml-2 mr-2 filter__dropdown'
						style={{ width: 'inherit' }}
					/>
				) : (
					<div className='pr-2'></div>
				)}
				<img
					src='images/reset.svg'
					alt=''
					onClick={handleReset}
					className={`filter__reset ${pathname === URL_ROUTER.JOB_PERFORMANCE && 'pl-2'}`}
				/>
				<img
					src='images/mobile/filter.svg'
					alt=''
					onClick={() => setOpenModalFilter(true)}
					className={`filter__mobile ${pathname === URL_ROUTER.JOB_PERFORMANCE && 'pl-2'}`}
				/>
			</div>
		</>
	);
};

export default Filter;
