import React, { useEffect, useState } from 'react';
import { JobRules } from '../../constants';
import { formatUSD } from '../../commons/formatCost';

const HeaderTotal = ({ data }) => {
	const dataIsComplete = data?.filter((item) => {
		return item?.status_jobs === JobRules.STATUS_JOBS.COMPLETE;
	});
	const dataIsPending = data?.filter((item) => {
		return item?.status_jobs === JobRules.STATUS_JOBS.PENDING;
	});
	const [totalCost, setTotalCost] = useState(0);
	const [totalPay, setTotalPay] = useState(0);

	useEffect(() => {
		let total_cost = 0;
		for (let i = 0; i < data?.length; i++) {
			total_cost += parseInt(data[i]?.total_cost);
		}
		setTotalCost(total_cost);

		let total_pay = 0;
		for (let i = 0; i < dataIsComplete?.length; i++) {
			total_pay += parseInt(dataIsComplete[i]?.total_cost);
		}
		setTotalPay(total_pay);
	}, [data, dataIsComplete]);

	return (
		<div className='total__table grid header_total_controller table-pc'>
			<div className='col-12 md:col-2 total__block'>
				<div>
					<p className='total__title'>Tổng công việc</p>
					<p className='total__count'>{data?.length}</p>
				</div>
			</div>
			<div className='col-12 md:col-2 total__block'>
				<div>
					<p className='total__title'>Đã hoàn thành</p>
					<p className='total__count'>{dataIsComplete?.length}</p>
				</div>
			</div>
			<div className='col-12 md:col-2 total__block'>
				<div>
					<p className='total__title'>Đang xử lý</p>
					<p className='total__count'>{dataIsPending?.length}</p>
				</div>
			</div>
			<div className='col-12 md:col-2 total__block'>
				<div>
					<p className='total__title'>Tổng số tiền</p>
					<p className='total__count'>{formatUSD(totalCost)}</p>
				</div>
			</div>
			<div className='col-12 md:col-2 total__block'>
				<div>
					<p className='total__title'>Tổng số tiền (Job đã hoàn thành )</p>
					<p className='total__count'>{formatUSD(totalPay)}</p>
				</div>
			</div>
		</div>
	);
};

export default HeaderTotal;
