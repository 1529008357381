import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataParse } from './dataParse';
import Table from '../../../../components/table/Table';
import { table_work_flowManager } from '../../../../components/table/header_table';
import { dashboardJobsRequest } from '../../../../redux/overviewJobs/actionJobs';
import {
	setIsOpenModalCreateJob,
	setIsOpenInformationJob,
	setDataModalInformationJob,
} from '../../../../redux/modal/modalSlice';
import { getEmployeePerformance } from '../../../../redux/employeePerformance/action';
import { handleRedirectMessage } from '../../../../commons/support';
import TableMobile from '../../../../components/table/TableMobile';
import { Button } from 'primereact/button';
import ViewPerformanceWorkFlow from '../../../modal/ViewPerformanceWorkFlow';

const WorkflowManagement = () => {
	const dispatch = useDispatch();
	const [filter, setFilter] = useState('');
	const jobs = useSelector((state) => state.jobs?.dashboard);
	const [openModal, setOpenModal] = useState(false);
	const isResetPage = useSelector((state) => state.auth.isResetPage);
	const [isFirstTime, setIsFirstTime] = useState(false);
	const getFilterPage = useSelector((state) => state.auth.filter);

	useEffect(() => {
		if (!isResetPage) {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			dispatch(dashboardJobsRequest(filterString));
		}
	}, [dispatch, filter, getFilterPage]);

	useLayoutEffect(() => {
		setTimeout(() => {
			setIsFirstTime(true);
		}, 1000);
	}, []);

	useEffect(() => {
		if (isFirstTime) dispatch(getEmployeePerformance(filter));
	}, [dispatch, filter]);

	useEffect(() => {
		let interval = setInterval(() => {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			dispatch(dashboardJobsRequest(filterString));
			dispatch(getEmployeePerformance());
		}, 60000 * 5);

		return () => {
			clearInterval(interval);
		};
	}, [filter, getFilterPage, dispatch]);

	const DataFilter = (data) => {
		setFilter(data);
	};

	const handleRowClick = (rowdata) => {
		const el = rowdata.originalEvent.target.closest('td').childNodes[1];

		if (el.classList.contains('icon__mess')) {
			handleRedirectMessage(rowdata?.data, dispatch);
		} else {
			dispatch(setIsOpenInformationJob(true));
			dispatch(setDataModalInformationJob(rowdata));
		}
	};
	const handleCreate = () => {
		dispatch(setIsOpenModalCreateJob(true));
	};

	return (
		<>
			<ViewPerformanceWorkFlow
				openModal={openModal}
				handleCloseModal={setOpenModal}
				data={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page) || []}
			/>
			<Table
				dataTable={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page)}
				loading={jobs?.loading}
				DataFilter={DataFilter}
				haveTotalTable={false}
				header={table_work_flowManager}
				handleRowClick={(e) => handleRowClick(e)}
				name_btn_add={'Tạo công việc'}
				handleCreate={handleCreate}
				filter={filter}
				totalRecords={jobs?.total}
				perPage={jobs?.per_page}
				currentPage={jobs?.current_page}
			/>
			<div className='table-mobile'>
				<Button
					label='Hiệu suất'
					className='p-button-outlined p-button-view '
					type='button'
					onClick={() => setOpenModal(true)}
				/>
			</div>
			<TableMobile
				data={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page)}
				handleRedirectMessage={(data) => {
					handleRedirectMessage(data, dispatch);
				}}
				handleRow={(data) => {
					dispatch(setIsOpenInformationJob(true));
					dispatch(setDataModalInformationJob({ data: data }));
				}}
				dataParse={dataParse}
				filter={filter}
			/>
		</>
	);
};

export default WorkflowManagement;
