export const TOAST_MESSAGE = {
	COPY: 'Sao chép thành công',
	UPDATE_LINK_ERR: 'Chưa cập nhật Link hoàn thành',
	UPDATE_SUCCESS: 'Cập nhật thành công',
	CANCEL_JOBS_SUCCESS: 'Từ chối công việc thành công',
	UPDATE_FAILED: 'Cập nhật thất bại',

	UPDATE__PERSONAL_SUCCESS: 'Cập nhật thông tin cá nhân thành công',

	UPDATE__STATUS_SUCCESS: 'Cập nhật trạng thái thành công',
	UPDATE__STATUS_FAILED: 'Cập nhật trạng thái thất bại',

	CREATE_STAFF_SUCCESS: 'Tạo thành viên mới thành công',
	DELETE_STAFF_SUCCESS: 'Xóa thành viên thành công',
	DELETE_STAFF_FAILED: 'Xóa thành viên thất bại',

	CREATE_JOB_SUCCESS: 'Tạo công việc mới thành công',
	DELETE_JOB_SUCCESS: 'Xóa công việc thành công',
	DELETE_JOB_FAILED: 'Xóa công việc thất bại',

	UPDATE_PAYMENT_SUCCESS: 'Thay đổi trạng thái thanh toán thành công',
	UPDATE_PAYMENT_FAILED: 'Thay đổi trạng thái thanh toán thất bại',

	CREATE_CUSTOMER_SUCCESS: 'Tạo khách hàng mới thành công',
	CREATE_CUSTOMER_FAILED: 'Tạo khách hàng mới thất bại',
	DELETE_CUSTOMER_SUCCESS: 'Xóa khách hàng thành công',
	DELETE_CUSTOMER_FAILED: 'Xóa khách hàng thất bại',
};
