import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const dashboardJobsRequest = createAsyncThunk('dashboard', async (filter, { rejectWithValue }) => {
	try {
		const search = typeof filter === 'string' ? filter : '';
		const res = await api.get(`jobs/data/dashboard${search}`);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const getMoreJobsRequest = createAsyncThunk('update_dashboard', async (filter, { rejectWithValue }) => {
	try {
		const search = typeof filter === 'string' ? filter : '';
		const res = await api.get(`jobs/data/dashboard${search}`);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const addJobsRequest = createAsyncThunk('addJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.post('jobs/', data);
		successToast(TOAST_MESSAGE.CREATE_JOB_SUCCESS);
		return res.data;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response?.data);
	}
});

export const editorEditJobsRequest = createAsyncThunk('editorUpdateJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`jobs/update/${data?.result.id_system}`, data?.result);
		if (res) {
			successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
			res.data_user = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response?.data);
	}
});

export const editJobsRequest = createAsyncThunk('updateJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`jobs/${data?.result.id_system}`, data?.result);
		if (res) {
			successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
			res.data_user = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response?.data);
	}
});

export const doneJobsRequest = createAsyncThunk('doneJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`jobs/done/${data?.result.id_system}`, data?.result);
		if (res) {
			successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
			res.data_user = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);

		return rejectWithValue(error?.response?.data);
	}
});

export const deleteJobsRequest = createAsyncThunk('deleteJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.delete(`jobs/${data.id}`);
		if (res) {
			successToast(TOAST_MESSAGE.DELETE_JOB_SUCCESS);
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(TOAST_MESSAGE.DELETE_JOB_FAILED);
		return rejectWithValue(error?.response?.data);
	}
});

export const approvedJobsRequest = createAsyncThunk('approvedJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`jobs/approved-by-editor/${data.id}`);
		if (res) {
			successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
			res.data_user = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response?.data);
	}
});

export const cancelJobsbyEditorRequest = createAsyncThunk('cancelJobsbyEditor', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`jobs/editor-cancel/${data.id}`);
		if (res) {
			successToast(TOAST_MESSAGE.CANCEL_JOBS_SUCCESS);
			res.data_user = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response?.data);
	}
});

export const getJobsRequest = createAsyncThunk('getDataJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.get(`jobs/${data.id}`);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const getJobsAdminRequest = createAsyncThunk('getDataJobs', async (data, { rejectWithValue }) => {
	try {
		const res = await api.get(`jobs/admin/${data.id}`);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});
