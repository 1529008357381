import { formatVND, formatUSD } from '../../../commons/formatCost';
import { UserRules } from '../../../constants';
export const dataParse = (data, page, perpage) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				index_payment: [indx, item?.id_job],
				pay_amount:
					item?.pay_role === UserRules.ROLE.EDITOR || item?.pay_role === UserRules.ROLE.LEADER_EDITOR
						? formatVND(item?.pay_amount)
						: formatUSD(item?.pay_amount),
				reminder_customer: item?.customer_reminder,
				quality_img: item?.quality_img,
				_create_at: item?._create_at,
				status: item?.status,
				rate_sale: item?.rate_sale,
				id_system: item?.id_system,
				id_job: item?.id_job,
				pay_amount_variable: item?.pay_amount,
				nameBank: item?.nameBank,
				number_account_payment: item?.number_account_payment,
				name_account_payment: item?.name_account_payment,
				group_name_job: item?.group_name_job,
			};
			return newobject;
		});
		return result;
	}
};

export const dataParseManagement = (data, page, perpage) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				date: item?.date,
				username: item?.username,
				pay_role: item?.pay_role,
				quality_img: item?.quality_img,
				pay_employees:
					item?.pay_role === UserRules.ROLE.EDITOR || item?.pay_role === UserRules.ROLE.LEADER_EDITOR
						? formatVND(item?.pay_employees)
						: formatUSD(item?.pay_employees),
				status: item?.status,
				staff_is_pay: item?.staff_is_pay,
				list_id: item?.list_id,
				nameBank: item?.nameBank,
				number_account_payment: item?.number_account_payment,
				name_account_payment: item?.name_account_payment,
				reminder_staff: item?.reminder_staff,
				indx: indx,
				index: index + 1,
			};
			return newobject;
		});
		return result;
	}
};
