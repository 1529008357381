import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const getEmployeePerformance = createAsyncThunk('getEmployeePerformance', async (data, { rejectWithValue }) => {
	try {
		let url = 'performance/employee';
		if (data) {
			url = `performance/employee${data}`;
		}
		const res = await api.get(url);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const jobsStaffPerformance = createAsyncThunk('jobsStaffPerformance', async (data, { rejectWithValue }) => {
	try {
		let url;
		if (data) {
			if (typeof data === 'object') {
				data = '';
			}
			url = `performance/staff-performance${data}`;
		} else {
			url = `performance/staff-performance?role=SALER`;
		}
		const res = await api.get(url);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});
