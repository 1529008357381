export const dataParse = (data, page, perpage) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				infor_reminder: item?.infor_reminder,
				fullname: item?.fullname,
				role: item?.role,
				start_day: item?.start_day,
				status: item?.status,
				address: item?.address,
				births: item?.births,
				phone: item?.phone,
				email: item?.email,
				id_system: item?.id_system,
				kpi_saler: item?.kpi_saler,
				avatar: item?.avatar,
				information_payment: {
					nameBank: item?.nameBank,
					number_account_payment: item?.number_account_payment,
					branch: item?.branch,
				},
				index: index + 1,
				indx: indx,
			};
			return newobject;
		});
		return result;
	}
};
