import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const getNotification = createAsyncThunk('getNotification', async (page, { rejectWithValue }) => {
	try {
		const res = await api.get(`notification`);
		return res?.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const updateNotification = createAsyncThunk('updateNotification', async (page, { rejectWithValue }) => {
	try {
		const res = await api.get(`messages?page=${page}`);
		return res?.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});
