export const dataParse = (data, page, perpage) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				indx: indx,
				infor_reminder: item?.infor_reminder,
				fullname: item?.fullname,
				_create_at: item?._create_at,
				information: item?.information,
				list_jobs: item?.list_jobs || {},
				id_system: item?.id_system,
				create_by: item?.create_by,
				link: item?.link,
				index: index + 1,
			};
			return newobject;
		});
		return result;
	}
};
