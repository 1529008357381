import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { jobsStaffPerformance } from '../../../../redux/employeePerformance/action';
import { table_job_performance } from '../../../../components/table/header_table';
import { dataParse } from './dataparse';

import Table from '../../../../components/table/Table';
import TableMobile from '../../../../components/table/TableMobile';

const JobPerformance = () => {
	const dispatch = useDispatch();
	const getFilterPage = useSelector((state) => state.auth.filter);
	const alljobsStaffPerformance = useSelector((state) => state.performanceReducer.jobsStaffPerformance)?.data;
	const [filter, setFilter] = useState('');

	useEffect(() => {
		const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
		dispatch(jobsStaffPerformance(filterString));
	}, [dispatch, filter, getFilterPage]);

	const DataFilter = (data) => {
		if (data) {
			setFilter(data);
		}
	};

	return (
		<>
			<Table
				dataTable={dataParse(
					alljobsStaffPerformance?.data_performance,
					alljobsStaffPerformance?.current_page,
					alljobsStaffPerformance?.per_page
				)}
				loading={alljobsStaffPerformance?.loading}
				DataFilter={DataFilter}
				haveTotalTable={false}
				header={table_job_performance}
				totalRecords={alljobsStaffPerformance?.total}
				perPage={alljobsStaffPerformance?.per_page}
				currentPage={alljobsStaffPerformance?.current_page}
			/>
			<TableMobile data={alljobsStaffPerformance?.data_performance} />
		</>
	);
};

export default JobPerformance;
