import { CustomerRules, UserRules, JobRules, PayRules } from '../../constants';
import { STATUS_MESSAGES } from '../../commons/Messages/status.messe';

export const customer_status = [
	{
		status: STATUS_MESSAGES.STATUS,
		image: '',
		id: '',
	},
	{
		status: STATUS_MESSAGES.REQUESTING,
		image: 'icon_success',
		id: CustomerRules.STATUS.REQUEST,
	},
	{
		status: STATUS_MESSAGES.PAUSE,
		image: 'icon_pending',
		id: CustomerRules.STATUS.PENDING,
	},
	{
		status: STATUS_MESSAGES.STOP,
		image: 'icon_close',
		id: CustomerRules.STATUS.UNREQUEST,
	},
];

export const user_status = [
	{
		status: STATUS_MESSAGES.STATUS,
		dots: '',
		id: '',
	},
	{
		status: STATUS_MESSAGES.USER.ONLINE,
		css_status: {
			status: 'btn_success',
			dots: 'dots_online',
		},
		id: UserRules.STATUS.ONLINE,
	},
	{
		status: STATUS_MESSAGES.USER.OFFLINE,
		css_status: {
			status: 'btn_stop',
			dots: 'dots_offline',
		},
		id: UserRules.STATUS.OFFLINE,
	},
];

export const jobs_status = [
	{
		status: STATUS_MESSAGES.JOBS.NOT_COMPLETED,
		image: 'icon_close',
		id: JobRules.STATUS_JOBS.INCOMPLETE,
	},
	{
		status: STATUS_MESSAGES.JOBS.REQUESTING,
		image: 'icon_pending',
		id: JobRules.STATUS_JOBS.PENDING,
	},
	{
		status: STATUS_MESSAGES.JOBS.SUCCESS,
		image: 'icon_success',
		id: JobRules.STATUS_JOBS.COMPLETE,
	},
];

export const editor_jobs_status = [
	{
		status: STATUS_MESSAGES.STATUS,
		image: '',
		id: '',
	},
	{
		status: STATUS_MESSAGES.JOBS.REQUESTING,
		image: 'icon_pending',
		id: JobRules.STATUS_JOBS.PENDING,
	},
	{
		status: STATUS_MESSAGES.JOBS.SUCCESS,
		image: 'icon_success',
		id: JobRules.STATUS_JOBS.COMPLETE,
	},
];

export const payment_status = [
	{
		status: STATUS_MESSAGES.STATUS,
		image: '',
		id: '',
	},
	{
		status: STATUS_MESSAGES.PAYMENT.PAID,
		image: 'icon_success',
		id: PayRules?.STATUS?.PAID,
	},
	{
		status: STATUS_MESSAGES.PAYMENT.NOT_PAID,
		image: 'icon_pending',
		id: PayRules?.STATUS?.UNPAID,
	},
];
