import { UserRules } from '../constants';

// Functions
export const formatVND = (str) => {
	return str ? str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' đ' : 0 + ' đ';
};
export const formatUSD = (num) => {
	if (num >= 0) {
		return (
			parseFloat(num)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' $'
		);
	}
};

export const calPriceJob = (cost, role) => {
	if (cost) {
		if (role === UserRules.ROLE.SALER) {
			return formatUSD((cost?.total_cost * cost?.rate_saler_in_created) / 100);
		}
		if (role === UserRules.ROLE.ADMIN) {
			return formatUSD(
				(cost?.total_cost * (1 - cost?.rate_saler_in_created / 100) * cost?.exchange_rate_in_created -
					cost?.editor_cost) /
					cost?.exchange_rate_in_created
			);
		}
	}
};

export const formatPercents = (num) => {
	return num > 0
		? parseFloat(num)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' %'
		: '0 %';
};
