import React, { useState, useEffect } from 'react';
import { NAME_ROOM } from '../../constants';
import { checkStatusJob, checkStatus, checkStatusEditor } from '../../commons/support';
import { timezoneToDate } from '../../commons/dateTime';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { URL_ROUTER } from '../../routes/routes';
import Loader from '../../commons/loader';
import { formatUSD, formatVND } from '../../commons/formatCost';
import { getMoreJobsRequest } from '../../redux/overviewJobs/actionJobs';
import { useDispatch } from 'react-redux';
import { resetFilter } from '../../redux/auth/authSlice';

const TableMobile = ({ data, handleRedirectMessage, handleRow, dataParse, filter }) => {
	const [allTweets, setAllTweets] = useState([]);

	const currentUser = useSelector((state) => state.message.currentUser);
	const location = useLocation();
	const { pathname } = location;
	const [page] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [isAddMore, setisAddMore] = useState(true);
	const dispatch = useDispatch();
	const isPageWorker =
		pathname === URL_ROUTER.DASHBOARD ||
		pathname === URL_ROUTER.JOB_OVERVIEW ||
		pathname === URL_ROUTER.WORKFLOW_MANAGEMENT;
	const isResetFilterByURL = useSelector((state) => state.auth.isResetFilterByURL);

	useEffect(() => {
		if (isResetFilterByURL) {
			setCurrentPage(1);
			setTimeout(() => {
				dispatch(resetFilter(false));
			}, 500);
		}
	}, [isResetFilterByURL]);

	useEffect(() => {
		setCurrentPage(1);
	}, [filter]);

	useEffect(() => {
		if (data) {
			const rs = [];
			for (let i = 0; i < data?.length; i++) {
				if (i + 1 <= page) {
					rs.push(data[i]);
				}
			}
			setAllTweets(rs);
		}
	}, [data, pathname]);

	const infinityScroll = () => {
		if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight && screen.width <= 1113) {
			if (!isPageWorker) {
				if (allTweets?.length !== data?.length) {
					setLoading(true);

					const getMoreElement = [];

					for (let i = 0; i < data?.length; i++) {
						if (i + 1 > page && i + 1 < page + 25) {
							getMoreElement.push(data[i]);
						}
					}

					setTimeout(() => {
						setLoading(false);
						setAllTweets([...allTweets, ...getMoreElement]);
					}, 1000);
				}
			} else {
				let getPage = currentPage;
				getPage += 1;
				setCurrentPage(getPage);
				let fill = `?page=${getPage}&limit=${25}`;
				if (filter) {
					fill = fill + filter.replace('?', '&');
				}
				dispatch(getMoreJobsRequest(fill)).then((res) => {
					if (res?.payload?.data_jobs) {
						if (res.payload.data_jobs?.length !== 25) {
							setisAddMore(false);
						}
						const parse = dataParse(
							res.payload.data_jobs,
							res?.payload?.current_page,
							res?.payload?.per_page
						);
						if (isAddMore) {
							setAllTweets([...allTweets, ...parse]);
						}
					}
				});
			}
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', infinityScroll);
		return () => {
			document.removeEventListener('scroll', infinityScroll);
		};
	});

	const showElement = (item) => {
		const nameGroup = NAME_ROOM.GROUP + '-' + item?.id_system;
		switch (pathname) {
			case URL_ROUTER.JOB_OVERVIEW:
				return (
					<>
						{/* <div className={`p-label ${checkStatusJob(item?.status_jobs, item)} data__status`}></div> */}
						<div className={`flex relative w-full `}>
							<div className='flex flex-column justify-content-around w-full'>
								<div className='flex flex-nowrap'>
									{/* <div className={`data_sales `}> */}
									<span className={`data_sales ${checkStatusJob(item?.status_jobs, item)}`}>
										Editor :<span className={`data_sales font-bold `}>{item?.reminder_editor}</span>
									</span>
									<span className={`data_sales flex align-items-center`}>
										Saler :
										<span className={`data_sales font-bold`} style={{ color: 'black' }}>
											{item?.reminder_saler}
										</span>
									</span>
								</div>
								<div className='data_sales '>
									KH :{' '}
									<span className='font-bold ml-1 ' style={{ color: 'black' }}>
										{' '}
										{item?.customer_group_job?.join(' - ')}
									</span>
								</div>
								<div className='flex'>
									<div className='data_date_start'>{timezoneToDate(item?.start_day)}</div>
									<div className='data_date_end'>{timezoneToDate(item?.end_day)}</div>
								</div>
							</div>
							<div>
								<div className='data_cost'>{formatUSD(item?.total_cost)}</div>
								<div className='data_chat'>
									<img
										alt='chat'
										className='chat__mobile'
										src='/images/mobile/chat.svg'
										onClick={() => {
											handleRedirectMessage(item);
										}}
									/>
									<div>
										{!currentUser?.newMessages?.[nameGroup]
											? ''
											: currentUser?.newMessages?.[nameGroup] <= 5
											? currentUser?.newMessages?.[nameGroup]
											: '5+'}
									</div>
								</div>
							</div>
						</div>
					</>
				);
			case URL_ROUTER.EMPLOYEE:
				return (
					<>
						<div className=' flex relative w-full align-items-center'>
							<div className='flex flex-column '>
								<div className={`data_sales mt-1 ${checkStatus(item?.status)}`}>
									Biệt danh : {item?.infor_reminder}
								</div>
								<div className='data_sales mt-2'>Tên nhân viên : {item?.fullname}</div>
								<div className='data_role mt-1'>Chức vụ : {item?.role}</div>
							</div>
							<div>
								<div className='data_date_start staff__day-start'>
									{timezoneToDate(item?.start_day)}
								</div>
							</div>
						</div>
					</>
				);
			case URL_ROUTER.PAYMENT_MANAGEMENT:
				return (
					<>
						{/* <div className={`p-label ${checkStatus(item?.status)} data__status`}></div> */}
						<div className={`flex relative w-full `}>
							<div
								className='flex flex-column justify-content-between pt-0'
								style={{ marginTop: '-25px', marginBottom: '5px' }}
							>
								<div className='flex align-items-center'>
									<div className={`data_role data_sales ${checkStatus(item?.status)}`}>
										Chức vụ: {item?.pay_role}
									</div>
									<div className='data_quality_img pt-0 flex'>
										SL: <span className='font-bold '>{item?.quality_img}</span>
									</div>
								</div>
								<div className='data_sales mt-1'>
									Tên:{' '}
									<span className='font-bold ' style={{ color: 'black' }}>
										{item?.username}
									</span>
								</div>
							</div>
							<div>
								<div className='data_cost'>{item?.pay_employees}</div>
								<div
									className='data_date_start staff__day-start'
									style={{ right: '15px', left: 'inherit', top: '70px' }}
								>
									{item?.date}
								</div>
							</div>
						</div>
					</>
				);
			case URL_ROUTER.JOB_PERFORMANCE:
				return (
					<div className=' flex relative w-full'>
						<div className='flex flex-column justify-content-center'>
							<div className='data_sales mt-1'>
								Tên nhân viên:{' '}
								<span className='text-bold' style={{ color: 'black' }}>
									{item?.fullname}
								</span>
							</div>
							<div className='data_sales mt-1'>
								Doanh thu:{' '}
								<span className='text-bold' style={{ color: '#febd06' }}>
									{item?.cost_jobs}
								</span>
							</div>
							<div className='data_sales mt-1'>
								Bonus:{' '}
								<span className='text-bold' style={{ color: '#1890ff' }}>
									{item?.bonus}
								</span>
							</div>
						</div>
						<div>
							<div className='data_kpi-perform'>{item?.kpi}</div>
							<div className='data_incomplete-perform'>{item?.job_incomplete}</div>
							<div className='data_pending-perform'>{item?.job_pending}</div>
							<div className='data_complete-perform'>{item?.job_complete}</div>
						</div>
					</div>
				);
			case URL_ROUTER.WORKFLOW_MANAGEMENT:
				return (
					<>
						{/* <div className={`p-label ${checkStatusJob(item?.status_jobs, item)} data__status`}></div> */}
						<div className={`flex relative w-full  `}>
							<div className='flex flex-column justify-content-around w-6 '>
								<div
									className={`data_sales ${checkStatusJob(item?.status_jobs, item)} editor__${
										item?.reminder_editor && checkStatusEditor(item?.status_editor, item)
									}`}
								>
									Editor :<span className={`data_sales font-bold `}>{item?.reminder_editor}</span>
									<span className={`data_sales `}></span>
								</div>
								<div className='data_sales '>
									KH :{' '}
									<span className='font-bold ml-1 ' style={{ color: 'black' }}>
										{' '}
										{item?.customer_group_job?.join(' - ')}
									</span>
								</div>
								<div className='flex'>
									{item?.start_day && (
										<div className='data_date_start'>{timezoneToDate(item?.start_day)}</div>
									)}
									{item?.end_day && (
										<div className='data_date_end'>{timezoneToDate(item?.end_day)}</div>
									)}
								</div>
							</div>
							<div>
								<div className='data_cost'>{formatUSD(item?.total_cost)}</div>
								{/* <div className='data_bonus'>
                                    {formatUSD(item?.total_cost / item?.rate_saler_in_created)}
                                </div> */}
								<div className='data_chat'>
									<img
										alt='chat'
										className='chat__mobile'
										src='/images/mobile/chat.svg'
										onClick={() => {
											handleRedirectMessage(item);
										}}
									/>
									<div>
										{!currentUser?.newMessages?.[nameGroup]
											? ''
											: currentUser?.newMessages?.[nameGroup] <= 5
											? currentUser?.newMessages?.[nameGroup]
											: '5+'}
									</div>
								</div>
							</div>
						</div>
					</>
				);
			case URL_ROUTER.CUSTOMER:
				return (
					<>
						<div className=' flex relative w-full'>
							<div className='flex flex-column justify-content-center'>
								<div className='data_sales mt-3'>
									Tên KH : <span>{item?.fullname}</span>
								</div>

								<div className='data_sales mt-3'>Biệt danh : {item?.infor_reminder}</div>
							</div>
							<div>
								<div className='data_date_start staff__day-start'>
									{timezoneToDate(item?._create_at)}
								</div>
							</div>
						</div>
					</>
				);
			case URL_ROUTER.PAYMENT:
				return (
					<>
						{/* <div className={`p-label ${checkStatus(item?.status)} data__status`}></div> */}
						<div className={`flex relative w-full `}>
							<div className='flex align-items-start w-full'>
								<div className={`data_role mt-1 data_sales ${checkStatus(item?.status)}`}>
									KH: {item?.reminder_customer}
								</div>
								<div className='data_quality_img pt-1 mt-2 pl-1' style={{ color: 'black' }}>
									SL:{' '}
									<span>
										{item?.quality_img} - {item?.group_name_job}
									</span>
								</div>
								{/* <div className={`data_sales mt-1 `}>
                                    Sales : <span className="font-bold ml-1 " style={{ color: 'black' }}> {item?.reminder_customer} </span>
                                </div> */}
								<div className='data_date_start staff__day-start' style={{ left: '0px' }}>
									{timezoneToDate(item?._create_at)}
								</div>
							</div>
							<div>
								<div className='data_cost'>{item?.pay_amount}</div>
							</div>
						</div>
					</>
				);
			case URL_ROUTER.DASHBOARD:
				return (
					<>
						{/* <div className={`p-label ${checkStatusJob(item?.status_jobs, item)} data__status`}></div> */}
						<div className={`flex relative w-full `}>
							<div className='flex flex-column justify-content-around w-full'>
								<div className='flex align-items-center'>
									<div className={`data_sales ${checkStatusJob(item?.status_jobs, item)}`}>
										Saler :{' '}
										<span className='font-bold ml-1 ' style={{ color: 'black' }}>
											{' '}
											{item?.reminder_saler}
										</span>
									</div>
									<div className='data_sales '>SL : {item?.quality_img}</div>
								</div>
								<div className={`data_sales`}>
									KH :{' '}
									<span className='font-bold ml-1 ' style={{ color: 'black' }}>
										{' '}
										{item?.reminder_customer?.name}{' '}
										{item?.reminder_customer?.group && `( ${item?.reminder_customer?.group} )`}
									</span>
								</div>
								<div className='flex'>
									{item?.start_day && (
										<div className='data_date_start'>{timezoneToDate(item?.start_day)}</div>
									)}
									{item?.end_day && (
										<div className='data_date_end'>{timezoneToDate(item?.end_day)}</div>
									)}
								</div>
							</div>
							<div>
								<div className='data_cost' style={{ color: '#1890ff' }}>
									{formatVND(item?.editor_cost)}
								</div>
								<div className='data_chat'>
									<img
										alt='chat'
										className='chat__mobile'
										src='/images/mobile/chat.svg'
										onClick={() => {
											handleRedirectMessage(item);
										}}
									/>
									<div>
										{!currentUser?.newMessages?.[nameGroup]
											? ''
											: currentUser?.newMessages?.[nameGroup] <= 5
											? currentUser?.newMessages?.[nameGroup]
											: '5+'}
									</div>
								</div>
							</div>
						</div>
					</>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<Loader isloading={loading} />
			<div className='table-mobile notification__block'>
				{allTweets?.map((item, index) => {
					return (
						<div
							key={index}
							className={`data__item flex p-0 ${item?.index > 0} `}
							onClick={(e) => {
								if (e.target.classList.value !== 'chat__mobile') {
									if (typeof handleRow !== 'undefined') {
										handleRow(item);
									}
								}
							}}
						>
							{showElement(item, index)}
						</div>
					);
				})}
				{allTweets?.length == 0 && <span className='matching-results'>Không có kết quả tìm kiếm</span>}
			</div>
		</>
	);
};

export default TableMobile;
