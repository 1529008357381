import { createAsyncThunk } from '@reduxjs/toolkit';

import { successToast, errorToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const createGroupMsgRequest = createAsyncThunk('createGroup', async (data, { rejectWithValue }) => {
	try {
		const res = await api.post(`group`, data);
		return res?.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const updateGroupMsgRequest = createAsyncThunk('updateGroup', async (data, { rejectWithValue }) => {
	try {
		await api.put(`group/${data.id}`, data.data);
		successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
		return data.data;
	} catch (error) {
		errorToast(TOAST_MESSAGE.UPDATE_FAILED);
		return rejectWithValue(error?.response?.data);
	}
});

export const deleteGroupMsgRequest = createAsyncThunk('deleteGroup', async (id, { rejectWithValue }) => {
	try {
		await api.delete(`group/${id}`);
		return true;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const postImagesMessage = createAsyncThunk('postImageMessage', async (data, { rejectWithValue }) => {
	try {
		const res = await api.post(`group/images`, data);
		return res?.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});
