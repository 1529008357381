export const HEADER_MESSAGES = {
	MESSAGE: 'Message',
	NO: 'STT',
	CUSTOMER: 'KH',
	NAME_CUSTOMER: 'Tên khách hàng',
	EDITOR: 'editor',
	BONUS: 'Bonus',
	TOTAL_COST: 'Total cost',
	DEADLINE: 'Deadline ',
	TYPE: 'Loại',
	COUNT_IMAGE: 'Số lượng ảnh',
	STATUS_EDITOR: 'Trạng thái editor',
	STATUS_CUSTOMER: 'Trạng thái KH',
	STATUS: 'Trạng thái',
	STATUS_JOBS: 'Trạng thái công việc',
	SALER: 'Sales',
	REMINDER: 'Biệt danh',
	DATE_START_RENT: 'Ngày bắt đầu thuê',
	DATE_START_WORKING: 'Ngày bắt đầu làm',
	ID_WORK: 'Mã công việc',
	DATE_CREATED: 'Ngày tạo',
	MONTH: 'Tháng',
	REMINDER_STAFF: 'Biệt danh nhân viên',
	NAME_STAFF: 'Tên nhân viên',
	STAFF: 'Nhân viên',
	POSITION: 'Chức vụ',
	PAYMENT: 'Thanh toán',
	INITIAL_EXPENSES: 'Chi phí ban đầu',
	TOTAL_WORK: 'Tổng số công việc',
	KPI: 'Kpi',
	NAME_BANK: 'Tên ngân hàng',
	NUMBER_BANK: 'Tên ngân hàng',
	NAME_ACCOUNT_HOLDER: 'Tên ngân hàng',
	PENDING: 'Tạm hoãn',
	REQUEST: 'Đang xử lý',
	REQUESTING: 'Chưa hoàn thành',
	DONE: 'Đã hoàn thành',
	REVENUE: 'Doanh thu',
	BONUS_COMPLETE: 'Bonus complete',
};
