import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { CONFIG_DATA } from '../../../commons/enums';
import { SETTINGS_MESSAGE } from '../../../commons/Messages/config.message';
import { useDispatch, useSelector } from 'react-redux';
import { updateRequest, settingRequest } from '../../../redux/admin/action';
import { errorToast } from '../../../commons/toast';
import { NOTIFICATION_ERROR_INPUT_VAILD } from '../../../constants';
const ConfigData = () => {
	const [addNewTypeImage, setAddNewTypeImage] = useState(['']);
	const [addNewTypeDefineImage, setAddNewTypeDefineImage] = useState(['']);
	const [exchangeRate, setExchangeRate] = useState(0);
	const [saleRate, setSaleRate] = useState(0);
	const [valueVaild, setValueVaild] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const setting = useSelector((state) => state.setting?.system);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(settingRequest());
	}, [dispatch]);

	const handleChangeTypeImage = (value, indexChild) => {
		const init = [...addNewTypeImage];
		init[indexChild] = value;
		setAddNewTypeImage(init);
	};
	const handleDeleteTypeImage = (indexChild) => {
		const init = [...addNewTypeImage];
		init.splice(indexChild, 1);
		setAddNewTypeImage(init);
	};
	const handleAddNewTypeImage = () => {
		const init = [...addNewTypeImage, ''];
		setAddNewTypeImage(init);
	};
	// Type Define Image
	const handleChangeTypeDefineImage = (value, indexChild) => {
		const init = [...addNewTypeDefineImage];
		init[indexChild] = value;
		setAddNewTypeDefineImage(init);
	};
	const handleDeleteTypeDefineIamge = (indexChild) => {
		const init = [...addNewTypeDefineImage];
		init.splice(indexChild, 1);
		setAddNewTypeDefineImage(init);
	};
	const handleAddNewTypeDefineImage = () => {
		const init = [...addNewTypeDefineImage, ''];
		setAddNewTypeDefineImage(init);
	};

	// Orther function
	// Notification error
	useEffect(() => {
		if (valueVaild) {
			errorToast(NOTIFICATION_ERROR_INPUT_VAILD);
		}
	}, [valueVaild]);

	// Set value input
	useEffect(() => {
		if (setting?.data) {
			setSaleRate(setting?.data.rate_sale);
			setExchangeRate(setting?.data.exchange_rate);
			setAddNewTypeImage(setting?.data.type_img?.length > 0 ? setting?.data.type_img : ['']);
			// setAddNewTypeFixed(setting?.data.type_fixed?.length > 0 ? setting?.data.type_fixed : ['']);
			setAddNewTypeDefineImage(
				setting?.data.type_define_image?.length > 0 ? setting?.data.type_define_image : ['']
			);
		}
	}, [dispatch, setting]);

	const filterArr = (data) => {
		return data.filter((str) => {
			return str.trim() !== '';
		});
	};
	const handleTypeImage = () => {
		if (addNewTypeImage?.length > 0) {
			const result = filterArr(addNewTypeImage);
			if (result?.length > 0) {
				setAddNewTypeImage(result);
				handleUpdateConfig({ type_img: result });
			} else {
				setValueVaild(true);
			}
		}
	};

	const handleTypeDefineImage = () => {
		if (addNewTypeDefineImage?.length > 0) {
			const result = filterArr(addNewTypeDefineImage);
			if (result?.length > 0) {
				setAddNewTypeDefineImage(result);
				handleUpdateConfig({ type_define_image: result });
			} else {
				setValueVaild(true);
			}
		}
	};

	const handleSaleRate = () => {
		if (saleRate > CONFIG_DATA.DEFAULT_MIN_SALE_RATE) {
			handleUpdateConfig({ rate_sale: saleRate });
		}
	};

	const handleExchangeRate = () => {
		if (exchangeRate > CONFIG_DATA.DEFAULT_MIN_EXCHANGE_RATE) {
			handleUpdateConfig({ exchange_rate: exchangeRate });
		}
	};
	const handleUpdateConfig = (formDataPut) => {
		if (Object.keys(formDataPut).length > 0) {
			Object.assign(formDataPut, { id_system: user?.data?.id_system });
			const formData = {
				data: formDataPut,
				result: formDataPut,
				index: setting,
			};
			setValueVaild(false);
			dispatch(updateRequest(formData));
		} else {
			errorToast(NOTIFICATION_ERROR_INPUT_VAILD);
		}
	};
	return (
		<div className='card page pt-3'>
			<TabView>
				<TabPanel header='Loại ảnh'>
					<div className='grid col-12 ml-2 border__left flex-column'>
						{addNewTypeImage?.map((itemChild, indexChild) => {
							return (
								<div className='ml-2 mt-3 ' key={indexChild}>
									{indexChild === 0 && (
										<div>
											<span className='modal__name--title pb-2 block'>Phân loại ảnh</span>
											<span className='modal__name--introduce pb-4 block'>
												Các dạng phân loại ảnh mà khách hàng sử dụng
											</span>
										</div>
									)}
									<div className='flex align-items-center'>
										<div className='pb-0 modal__name--group'>
											<InputText
												value={itemChild}
												className='w-full'
												onChange={(e) => handleChangeTypeImage(e.target.value, indexChild)}
												placeholder='Nhập loại ảnh'
											/>
										</div>
										{addNewTypeImage.length > 1 && (
											<div className='pb-0 ml-3'>
												<div className='field calendar mb-0  pl-3 pr-3'>
													<div
														className='btn__payment--delete flex'
														onClick={() => handleDeleteTypeImage(indexChild)}
													>
														<div className='modal__name--icon__delete'></div>
														<img src='/images/icon-close-circle.svg' />
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							);
						})}
						{addNewTypeImage && addNewTypeImage?.length > 0 && (
							<div className='grid input__group--left col-12 max-h-4-rem ml-1'>
								<div className='col-4 p-0 pt-4'>
									<span
										className='modal__name--create-btn color-primary cursor-pointer w-max p-0'
										onClick={() => handleAddNewTypeImage()}
									>
										<span className='text-lg color-primary'>+</span> Tạo mới
									</span>
								</div>
							</div>
						)}
					</div>
					<div className='flex col-12 justify-content-end'>
						<Button label='Cập nhật' onClick={() => handleTypeImage()} />
					</div>
				</TabPanel>
				<TabPanel header='Định dạng ảnh'>
					<div className='grid col-12 ml-2 border__left flex-column'>
						{addNewTypeDefineImage?.map((itemChild, indexChild) => {
							return (
								<div className='ml-2 mt-3 ' key={indexChild}>
									{indexChild === 0 && (
										<div>
											<span className='modal__name--title pb-2 block'>Định dạng ảnh</span>
											<span className='modal__name--introduce pb-4 block'>
												Các định dạng ảnh thường được sử dụng trong công việc
											</span>
										</div>
									)}
									<div className='flex align-items-center'>
										<div className='pb-0 modal__name--group'>
											<InputText
												value={itemChild}
												className='w-full'
												onChange={(e) =>
													handleChangeTypeDefineImage(e.target.value, indexChild)
												}
												placeholder='Nhập loại ảnh'
											/>
										</div>
										{addNewTypeDefineImage.length > 1 && (
											<div className='pb-0 ml-3'>
												<div className='field calendar mb-0  pl-3 pr-3'>
													<div
														className='btn__payment--delete flex'
														onClick={() => handleDeleteTypeDefineIamge(indexChild)}
													>
														<div className='modal__name--icon__delete'></div>
														<img src='/images/icon-close-circle.svg' />
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							);
						})}
						{addNewTypeDefineImage && addNewTypeDefineImage?.length > 0 && (
							<div className='grid input__group--left col-12 max-h-4-rem ml-1'>
								<div className='col-4 p-0 pt-4'>
									<span
										className='modal__name--create-btn color-primary cursor-pointer w-max p-0'
										onClick={() => handleAddNewTypeDefineImage()}
									>
										<span className='text-lg color-primary'>+</span> Tạo mới
									</span>
								</div>
							</div>
						)}
					</div>
					<div className='flex col-12 justify-content-end'>
						<Button label='Cập nhật' onClick={() => handleTypeDefineImage()} />
					</div>
				</TabPanel>
				<TabPanel header='Số tiền quy đổi'>
					<div className='grid col-12 ml-2 border__left flex-column'>
						<div className='ml-2 mt-3 '>
							<span className='modal__name--title pb-2 block'>Số tiền</span>
							<span className='modal__name--introduce pb-3 block'>
								Tiêu chuẩn quy đổi từ 1 USD sang VND
							</span>
							<div className='col-12 pl-0'>
								<div className='pb-0 modal__name--group'>
									<InputNumber
										id='editor_cost'
										inputId='currency-vn'
										value={exchangeRate}
										onChange={(e) => setExchangeRate(e.value)}
										mode='currency'
										currency='VND'
										locale='vi-VN'
										useGrouping={true}
										className='w-full'
										placeholder='Nhập số tiền quy đổi từ USD sang VND'
										min={CONFIG_DATA.DEFAULT_MIN_EXCHANGE_RATE}
									/>
								</div>
								{exchangeRate < CONFIG_DATA.DEFAULT_MIN_EXCHANGE_RATE && (
									<span className='form__error'>{SETTINGS_MESSAGE.MIN_EXCHANGE_RATE}</span>
								)}
							</div>
						</div>
						<div className='flex col-12 justify-content-end'>
							<Button label='Cập nhật' onClick={() => handleExchangeRate()} />
						</div>
					</div>
				</TabPanel>
				<TabPanel header='Tỉ lệ hoa hồng'>
					<div className='grid col-12 ml-2 border__left flex-column'>
						<div className='ml-2 mt-3 '>
							<span className='modal__name--title pb-2 block'>Tỉ lệ</span>
							<span className='modal__name--introduce pb-3 block'>
								Tỉ lệ hoa hồng mà Saler - người tạo công việc, đơn hàng nhận được sau khi tạo thành công
							</span>
							<div className='col-12 pl-0'>
								<div className='pb-0 modal__name--group'>
									<InputNumber
										id='rate_sale'
										value={saleRate}
										onChange={(e) => {
											setSaleRate(e.value);
										}}
										suffix='%'
										className='w-full'
										max={CONFIG_DATA.DEFAULT_MAX_SALE_RATE}
										min={CONFIG_DATA.DEFAULT_MIN_SALE_RATE}
									/>
								</div>
								{saleRate > CONFIG_DATA.DEFAULT_MAX_SALE_RATE && (
									<span className='form__error'>{SETTINGS_MESSAGE.MAX_SALE_RATE}</span>
								)}
							</div>
						</div>
						<div className='flex col-12 justify-content-end'>
							<Button label='Cập nhật' onClick={() => handleSaleRate()} />
						</div>
					</div>
				</TabPanel>
			</TabView>
		</div>
	);
};
export default ConfigData;
