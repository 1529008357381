import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/table/Table';
import { dataParse } from './dataParse';
import { dashboardJobsRequest } from '../../../redux/overviewJobs/actionJobs';
import { table_dashboard } from '../../../components/table/header_table';
import { setIsOpenInformationJob, setDataModalInformationJob } from '../../../redux/modal/modalSlice';
import { handleRedirectMessage } from '../../../commons/support';
import TableMobile from '../../../components/table/TableMobile';

const Dashboard = () => {
	const dispatch = useDispatch();
	const getFilterPage = useSelector((state) => state.auth.filter);
	const [filter, setFilter] = useState('');
	const jobs = useSelector((state) => state.jobs?.dashboard);
	const isResetPage = useSelector((state) => state.auth.isResetPage);

	useEffect(() => {
		if (!isResetPage) {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			dispatch(dashboardJobsRequest(filterString));
		}
	}, [dispatch, filter, getFilterPage]);

	useEffect(() => {
		let interval = setInterval(() => {
			dispatch(dashboardJobsRequest(filter));
		}, 60000 * 5);

		return () => {
			clearInterval(interval);
		};
	}, [filter, dispatch]);

	const DataFilter = (data) => {
		setFilter(data);
	};

	const handleRowClick = (rowdata) => {
		const el = rowdata.originalEvent.target.closest('td').childNodes[1];

		if (el.classList.contains('icon__mess')) {
			handleRedirectMessage(rowdata?.data, dispatch);
		} else {
			dispatch(setIsOpenInformationJob(true));
			dispatch(setDataModalInformationJob(rowdata));
		}
	};

	return (
		<>
			<Table
				dataTable={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page)}
				loading={jobs?.loading}
				DataFilter={DataFilter}
				haveTotalTable={false}
				header={table_dashboard}
				handleRowClick={(e) => handleRowClick(e)}
				name_btn_add={false}
				handleCreate={false}
				filter={filter}
				totalRecords={jobs?.total}
				perPage={jobs?.per_page}
				currentPage={jobs?.current_page}
			/>
			<TableMobile
				data={dataParse(jobs?.data, jobs?.current_page, jobs?.per_page)}
				handleRedirectMessage={(data) => {
					handleRedirectMessage(data, dispatch);
				}}
				handleRow={(data) => {
					dispatch(setIsOpenInformationJob(true));
					dispatch(setDataModalInformationJob({ data: data }));
				}}
				dataParse={dataParse}
				filter={filter}
			/>
		</>
	);
};

export default Dashboard;
