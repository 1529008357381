import React, { useEffect } from 'react';
import {
	useSelector,
	// useDispatch
} from 'react-redux';
import jwt_decode from 'jwt-decode';
import {
	// useLocation,
	useNavigate,
} from 'react-router-dom';
import { defaultRoute } from './components/PrivateRoute/defaultRoute';
import { URL_ROUTER } from './routes/routes';

const Notfound = () => {
	const profile = useSelector((state) => state.auth?.user);
	const user = useSelector((state) => state.auth.token);
	// const location = useLocation()
	// const { pathname } = location
	const navigate = useNavigate();
	const decodedToken = () => {
		if (user?.data?.access_token) {
			const tokenDecode = jwt_decode(user?.data?.access_token);
			return tokenDecode.role;
		}
		return '';
	};
	const role = profile?.data?.role || decodedToken();
	const route = defaultRoute(role);

	useEffect(() => {
		if (role) {
			navigate(route);
		} else {
			navigate(URL_ROUTER.LOGIN);
		}
	});

	return (
		<div
			style={{ textAlign: 'center' }}
			className='page flex justify-content-center align-items-center flex-column p-8'
		>
			<img src='/images/notfound.png' alt='' />
		</div>
	);
};

export default Notfound;
