import React, { useEffect, useState } from 'react';
import Table from '../../../components/table/Table';
import { table_employee_overview } from '../../../components/table/header_table';
import { dashboardEmployeeRequest } from '../../../redux/overviewEmployee/actionEmployee';
import { useDispatch, useSelector } from 'react-redux';
import { dataParse } from '../admin/dataParse';
import {
	setIsOpenModalCreateUser,
	setIsOpenModalInformationUser,
	setDataModalInformationUser,
} from '../../../redux/modal/modalSlice';
import TableMobile from '../../../components/table/TableMobile';

const EmployeeOverview = () => {
	const dispatch = useDispatch();
	const employees = useSelector((state) => state.employee.dashboard);
	const getFilterPage = useSelector((state) => state.auth.filter);
	const [filter, setFilter] = useState('');
	const isResetPage = useSelector((state) => state.auth.isResetPage);
	useEffect(() => {
		if (!isResetPage) {
			const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
			dispatch(dashboardEmployeeRequest(filterString));
		}
	}, [dispatch, filter, getFilterPage]);

	const DataFilter = (data) => {
		setFilter(data);
	};

	const handleRowClick = (rowData) => {
		dispatch(setIsOpenModalInformationUser(true));
		dispatch(setDataModalInformationUser(rowData));
	};

	const handleCreate = () => {
		dispatch(setIsOpenModalCreateUser(true));
	};

	return (
		<>
			<Table
				dataTable={dataParse(employees?.data, employees?.current_page, employees?.per_page)}
				loading={employees?.loading}
				DataFilter={DataFilter}
				haveTotalTable={false}
				header={table_employee_overview}
				handleRowClick={handleRowClick}
				name_btn_add={'Tạo nhân viên'}
				handleCreate={handleCreate}
				totalRecords={employees?.total}
				perPage={employees?.per_page}
				currentPage={employees?.current_page}
			/>
			<TableMobile
				data={dataParse(employees?.data)}
				handleRow={(data) => {
					dispatch(setIsOpenModalInformationUser(true));
					dispatch(setDataModalInformationUser({ data: data }));
				}}
			/>
		</>
	);
};

export default EmployeeOverview;
