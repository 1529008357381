export const dataParseCustomer = (data) => {
	if (Array.isArray(data)) {
		const result = data.map((item) => {
			const newobject = {
				name: item?.fullname,
				id_system: item?.id_system,
				create_by: item?.create_by,
				email: item?.information?.email,
				phone: item?.information?.phone,
				status: item?.status,
			};
			return newobject;
		});
		return result;
	}
};

export const dataParseEditor = (data) => {
	if (Array.isArray(data)) {
		const result = data.map((item) => {
			const newobject = {
				name: item?.fullname,
				id_system: item?.id_system,
				create_by: item?.create_by,
				email: item?.email,
				phone: item?.phone,
				status: item?.status,
			};
			return newobject;
		});
		return result;
	}
};

export const dataParse = (data, page, perpage) => {
	if (Array.isArray(data)) {
		const result = data.map((item, index) => {
			let indx = index + 1;
			if (page && perpage) {
				indx = (page - 1) * perpage + (index + 1);
			}
			const newobject = {
				message: '',
				indx: indx,
				customer_group_job: item?.infor_reminder?.customer_group_job,
				reminder_saler: item?.infor_reminder?.reminder_saler,
				reminder_editor: item?.infor_reminder?.reminder_editor,
				total_cost: item?.cost?.total_cost,
				start_day: item?.infor?.start_day,
				end_day: item?.infor?.end_day,
				status_jobs: item?.infor?.status_jobs,
				saler_cost: item?.cost?.saler_cost,
				editor_cost: item?.cost?.editor_cost,
				admin_cost: item?.cost?.admin_cost,
				work_notes: item?.infor?.work_notes,
				quality_img: item?.infor?.quality_img,
				request_content: item?.infor?.request_content,
				detail_content: item?.infor?.detail_content,
				photo_types: item?.infor?.photo_types,
				org_link: item?.infor?.org_link,
				finished_link: item?.infor?.finished_link,
				status_editor: item?.infor?.status_editor,
				type_models: item?.infor?.type_models,
				type_pay: item?.infor?.type_pay,
				id_customer: item?.infor_id?.id_customer,
				id_saler: item?.infor_id?.id_saler,
				id_editor: item?.infor_id?.id_editor,
				id_system: item?.infor_id?.id_system,
				group_name_job: item?.infor?.group_name_job,
				status_update: item?.infor?.status_jobs_update,
				status_editor_fix: item?.infor?.status_editor_fix,
				count_fixed: item?.infor?.count_fixed,
				fixed_link: item?.infor?.fixed_link,
				reminder_customer: item?.infor_reminder?.reminder_customer,
				rate_saler_in_created: item?.cost?.rate_saler_in_created,
				exchange_rate_in_created: item?.cost?.exchange_rate_in_created,
				is_approved_by_editor: item?.infor?.is_approved_by_editor,
				index: index + 1,
			};
			return newobject;
		});

		return result;
	}
};
