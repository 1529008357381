import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { saleCustomerRequest } from '../../redux/sale/action';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenModalCreateJob, setIsOpenModalCreateCustomer } from '../../redux/modal/modalSlice';
import { addJobsRequest } from '../../redux/overviewJobs/actionJobs';
import { itemCustomerTemplate } from '../modal/TemplateDropDown';
import { overlay } from '../../commons/overlay';
import { getEmployeePerformance } from '../../redux/employeePerformance/action';
import { useLocation } from 'react-router';
import { resetJobCreated } from '../../redux/overviewJobs/jobsSlice';
import { settingRequest } from '../../redux/admin/action';
import { validate, dateAddMoreHours } from '../../commons/support';
import { URL_ROUTER } from '../../routes/routes';
import { LINK_REGEX } from '../../constants';

const CreateJobs = () => {
	const defaultValues = {
		end_day: null,
		quality_img: '',
		org_link: '',
		photo_types: '',
		total_cost: '',
		editor_cost: '',
		request_content: '',
		detail_content: '',
		work_notes: '',
		type_models: '',
		group_name_job: '',
	};
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues });
	const addjobs = useSelector((state) => state.jobs.addjobs);
	const user = useSelector((state) => state.auth?.user);
	const location = useLocation();
	const { pathname } = location;
	let minDate = new Date();
	const isOpenCreateJob = useSelector((state) => state.modal.isOpenModalCreateJob);
	const setting = useSelector((state) => state.setting?.system);
	const dispatch = useDispatch();
	const [customers, setCustomer] = useState([]);
	useEffect(() => {
		if (isOpenCreateJob) {
			dispatch(settingRequest());
			dispatch(saleCustomerRequest('?paging_ignored=true'))
				.unwrap()
				.then((data) => {
					setCustomer(data?.data);
				})
				.catch((e) => console.log(e));
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [dispatch, isOpenCreateJob]);

	useEffect(() => {
		if (addjobs?.data && !addjobs?.error) {
			handleCloseModal();
			if (pathname === URL_ROUTER.WORKFLOW_MANAGEMENT) {
				dispatch(getEmployeePerformance());
			}
			dispatch(setIsOpenModalCreateJob(false));
		}
		if (addjobs?.error) {
			dispatch(setIsOpenModalCreateJob(true));
		}
	}, [addjobs]);

	useEffect(() => {
		if (isOpenCreateJob) {
			dispatch(saleCustomerRequest());
		} else {
			setTimeout(() => {
				dispatch(resetJobCreated());
			}, 500);
		}
	}, [dispatch, isOpenCreateJob]);

	const onSubmit = (data) => {
		if (Object.keys(errors).length === 0) {
			data.end_day = dateAddMoreHours(data.end_day);
			data.id_customer = data.nameCustomer.id_system;
			data.request_content = data.request_content.trim();
			data.detail_content = data.detail_content.trim();
			data.work_notes = data.work_notes.trim();
			data.org_link = data.org_link.trim();
			dispatch(addJobsRequest(data));
		}
	};

	const handleCloseModal = React.useCallback(() => {
		dispatch(setIsOpenModalCreateJob(false));
		reset();
	}, [dispatch, reset]);

	const handleCreateNewCustomer = () => {
		dispatch(setIsOpenModalCreateJob(false));
		setTimeout(() => {
			dispatch(setIsOpenModalCreateCustomer(true));
		}, 100);
	};

	return (
		<>
			<Sidebar visible={isOpenCreateJob} position='right' className='create__job' onHide={() => {}}>
				<div className='creat__job'>
					<div
						className='create__job--title flex align-items-center justify-content-between'
						onClick={handleCloseModal}
					>
						<h2 className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							Tạo công việc mới
						</h2>
						<div
							className=' align-items-center create__job--remove-job cursor-pointer'
							style={{ color: 'white' }}
							onClick={handleCloseModal}
						>
							x
						</div>
					</div>
					<form className=' grid modal__creat--job pr-2' onSubmit={handleSubmit(onSubmit)}>
						<div className='field col-12 md:col-12 grid pr-0'>
							<div className='field col-12 md:col-12'>
								<span htmlFor='autocomplete'>
									<span className='warning'>*</span>
									<span className='feild-form'>Tìm thông tin khách hàng</span>
								</span>
								<span className=''>
									<Controller
										name='nameCustomer'
										control={control}
										rules={{ required: 'Vui lòng chọn khách hàng.' }}
										render={({ field, fieldState }) => (
											<Dropdown
												options={customers?.data_customer}
												optionLabel='infor_reminder'
												itemTemplate={itemCustomerTemplate}
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												className={classNames(
													{ 'p-invalid': fieldState.invalid },
													'create__job_type'
												)}
												placeholder='Chọn khách hàng'
											/>
										)}
									/>
								</span>
								{errors?.nameCustomer && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.nameCustomer.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12 create_new_customer'>
								<p
									onClick={handleCreateNewCustomer}
									style={{ width: 'max-content' }}
									className='create_new_customer--btn'
								>
									<img
										className='icon__modal pr-1'
										alt='create-job'
										src='../../images/icon_plus.svg'
									/>
									Tạo khách hàng mới
								</p>
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='type_models'>
									<span className='warning'>*</span>Loại ảnh
								</span>
								<span className=''>
									<Controller
										name='type_models'
										control={control}
										rules={{ required: 'Vui lòng Chọn loại ảnh.' }}
										render={({ field, fieldState }) => (
											<Dropdown
												options={setting.data?.type_img}
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												className={classNames(
													{ 'p-invalid': fieldState.invalid },
													'create__job_type'
												)}
												placeholder='Chọn ảnh'
											/>
										)}
									/>
								</span>
								{errors?.type_models && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.type_models.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6 '>
								<div>
									<span htmlFor='withoutgrouping'>
										<span className='warning'>*</span>Số lượng
									</span>
								</div>
								<Controller
									name='quality_img'
									control={control}
									rules={{ required: 'Vui lòng nhập số lượng.' }}
									render={({ field, fieldState }) => (
										<InputNumber
											value={field.value}
											onValueChange={(e) => field.onChange(e.value)}
											mode='decimal'
											min={1}
											className={classNames({ 'p-invalid': fieldState.invalid })}
											placeholder='Điền số lượng ảnh'
										/>
									)}
								/>
								{errors?.quality_img && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.quality_img.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6 create__job--calendar'>
								<span htmlFor='calendar'>
									<span className='warning'>*</span>Deadline
								</span>
								<span className='relative'>
									<Controller
										name='end_day'
										control={control}
										rules={{ required: 'Vui lòng chọn ngày hết hạn.' }}
										render={({ field, fieldState }) => (
											<Calendar
												minDate={minDate}
												id={field.name}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												placeholder='Chọn ngày hết hạn'
												readOnlyInput={true}
											/>
										)}
									/>
									<img src='/images/calendar.svg' alt='' className='calendar__image' />
								</span>
								{errors?.end_day && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.end_day.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='group_name_job'>
									<span className='warning'>*</span>Nhóm công việc
								</span>
								<span className=''>
									<Controller
										name='group_name_job'
										control={control}
										rules={{ required: 'Vui lòng Nhập tên nhóm công việc.', validate }}
										render={({ field, fieldState }) => (
											<InputText
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập loại nhóm công việc'
											/>
										)}
									/>
								</span>
								{errors?.group_name_job && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.group_name_job.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='original__link'>
									<span className='warning'>*</span>Link ảnh gốc
								</span>
								<span className=''>
									<Controller
										name='org_link'
										control={control}
										rules={{
											required: 'Vui lòng nhập link ảnh gốc.',
											pattern: { value: LINK_REGEX, message: 'Link ảnh gốc không hợp lệ.' },
										}}
										render={({ field, fieldState }) => (
											<InputText
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập link ảnh'
											/>
										)}
									/>
								</span>
								{errors?.org_link && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.org_link.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='original__link'>
									<span className='warning'>*</span> Định dạng file
								</span>
								<span className=''>
									<Controller
										name='photo_types'
										control={control}
										rules={{ required: 'Vui lòng chọn định dạng file ảnh.' }}
										render={({ field, fieldState }) => (
											<Dropdown
												options={setting.data?.type_define_image}
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												className={classNames(
													{ 'p-invalid': fieldState.invalid },
													'create__job_type'
												)}
												placeholder='Chọn định dạng file'
											/>
										)}
									/>
								</span>
								{errors?.photo_types && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.photo_types.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='cost'>
									<span className='warning'>*</span> Chi phí ban đầu ($)
								</span>
								<span className=''>
									<Controller
										name='total_cost'
										control={control}
										rules={{ required: 'Vui lòng nhập chi phí.' }}
										render={({ field, fieldState }) => (
											<InputNumber
												onChange={(e) => field.onChange(e.value)}
												mode='decimal'
												min={1}
												minFractionDigits={2}
												maxFractionDigits={5}
												className={classNames({ 'p-invalid': fieldState.invalid }, 'w-full')}
												placeholder='Nhập chi phí'
											/>
										)}
									/>
								</span>
								{errors?.total_cost && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.total_cost.message}
									</span>
								)}
							</div>
							{user?.data?.role === 'ADMIN' && (
								<div className='field col-12 md:col-6'>
									<span>
										<span htmlFor='cost' style={{ height: '24px' }}>
											Chi phí Editor (VND)
										</span>
									</span>
									<Controller
										name='editor_cost'
										control={control}
										render={({ field, fieldState }) => (
											<InputNumber
												id='editor_cost'
												inputId='currency-vn'
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												locale='en-US'
												useGrouping={true}
												className={`${classNames({
													'p-invalid': fieldState.invalid,
												})} w-full`}
												placeholder='Chi phí'
											/>
										)}
									/>
								</div>
							)}
							<div className='field col-12 md:col-12'>
								<span htmlFor='employees' className='pb-2'>
									<span className='warning'>*</span> Nội dung yêu cầu
								</span>
								<span className=''>
									<Controller
										name='request_content'
										control={control}
										rules={{ required: 'Vui lòng nhập nội dung yêu cầu.', validate }}
										render={({ field, fieldState }) => (
											<InputTextarea
												autoResize
												id={field.name}
												onChange={(e) => {
													field.onChange(e.target.value);
												}}
												className={classNames(
													{ 'p-invalid': fieldState.invalid },
													'create__job_area'
												)}
												placeholder='Nhập nội dung yêu cầu'
											/>
										)}
									/>
								</span>
								{errors?.request_content && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.request_content.message}
									</span>
								)}
							</div>
							{user?.data?.role === 'ADMIN' && (
								<div className='field col-12 md:col-12'>
									<span htmlFor='employees' className='pb-2'>
										Nội dung chi tiết
									</span>
									<span className=''>
										<Controller
											name='detail_content'
											control={control}
											render={({ field, fieldState }) => (
												<InputTextarea
													autoResize
													id={field.name}
													onChange={(e) => {
														field.onChange(e.target.value);
													}}
													className={classNames(
														{ 'p-invalid': fieldState.invalid },
														'create__job_area'
													)}
													placeholder='Nhập nội dung chi tiết'
												/>
											)}
										/>
									</span>
								</div>
							)}
							<div className='field col-12 md:col-12'>
								<span className='pb-2'>
									<span>
										<span className='warning'>*</span>Ghi chú của khách hàng
									</span>
								</span>
								<Controller
									name='work_notes'
									control={control}
									rules={{ required: 'Vui lòng nhập lưu ý của khách hàng.', validate }}
									render={({ field, fieldState }) => (
										<InputTextarea
											autoResize
											id={field.name}
											className={classNames(
												{ 'p-invalid': fieldState.invalid },
												'create__job_area'
											)}
											placeholder='Nhập ghi chú của khách hàng'
											onChange={(e) => {
												field.onChange(e.target.value);
											}}
										/>
									)}
								/>
								{errors?.work_notes && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.work_notes.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12 '></div>
						</div>
						<div className='btn_modal field col-12 md:col-12 grid'>
							<div className='field col-6 md:col-6'>
								<span className=''>
									<Button
										label='Hủy bỏ'
										className='p-button-outlined cancel--btn'
										onClick={handleCloseModal}
										type='button'
									/>
								</span>
							</div>
							<div className='field col-6 md:col-6 pr-0'>
								<span className=''>
									<Button
										label='Tạo mới'
										className='p-button-outlined p-button-secondary confirm--btn'
										type='submit'
									/>
								</span>
							</div>
						</div>
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default CreateJobs;
