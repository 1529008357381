import React, { useState, useEffect } from 'react';
import Table from '../../../../components/table/Table';
import { table_customer_management } from '../../../../components/table/header_table';
import { saleCustomerRequest } from '../../../../redux/sale/action';
import { useDispatch, useSelector } from 'react-redux';
import { dataParse } from './dataParse';
import {
	setIsOpenModalCreateCustomer,
	setIsOpenModalInformationCustomer,
	setDataModalInformationCustomer,
} from '../../../../redux/modal/modalSlice';
import TableMobile from '../../../../components/table/TableMobile';

const CustomerManager = () => {
	const dispatch = useDispatch();
	const getFilterPage = useSelector((state) => state.auth.filter);
	const customers = useSelector((state) => state.sale.customers.data);
	const [filter, setFilter] = useState('');

	useEffect(() => {
		const filterString = filter + (getFilterPage !== null ? (filter ? '&' : '?') + `${getFilterPage}` : '');
		dispatch(saleCustomerRequest(filterString));
	}, [dispatch, filter, getFilterPage]);

	const DataFilter = (data) => {
		setFilter(data);
	};

	const handleRowClick = (rowData) => {
		dispatch(setIsOpenModalInformationCustomer(true));
		dispatch(setDataModalInformationCustomer(rowData));
	};
	const handleCreate = () => {
		dispatch(setIsOpenModalCreateCustomer(true));
	};

	return (
		<>
			<Table
				dataTable={dataParse(customers?.data_customer, customers?.current_page, customers?.per_page)}
				loading={customers?.loading}
				DataFilter={DataFilter}
				haveTotalTable={false}
				header={table_customer_management}
				handleRowClick={handleRowClick}
				name_btn_add={'Tạo khách hàng mới'}
				handleCreate={handleCreate}
				totalRecords={customers?.total}
				perPage={customers?.per_page}
				currentPage={customers?.current_page}
			/>
			<TableMobile
				data={dataParse(customers?.data_customer)}
				handleRow={(data) => {
					dispatch(setIsOpenModalInformationCustomer(true));
					dispatch(setDataModalInformationCustomer({ data: data }));
				}}
			/>
		</>
	);
};

export default CustomerManager;
