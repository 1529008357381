export const STATUS_MESSAGES = {
	STATUS: 'Trạng thái',
	REQUESTING: 'Đang yêu cầu',
	PAUSE: 'Tạm hoãn yêu cầu',
	STOP: 'Ngừng yêu cầu',
	USER: {
		ONLINE: 'Đang hoạt động',
		BUSY: 'Tạm ngưng hoạt động',
		OFFLINE: 'Không hoạt động',
	},
	JOBS: {
		SUCCESS: 'Đã hoàn thành',
		REQUESTING: 'Đang xử lý',
		NOT_COMPLETED: 'Chưa hoàn thành',
	},
	PAYMENT: {
		PAID: 'Đã thanh toán',
		NOT_PAID: 'Chưa thanh toán',
		CANCELED: 'Đã hủy',
	},
};
