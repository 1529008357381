import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const createServiceId = createAsyncThunk('createServiceId', async (data, { rejectWithValue }) => {
	try {
		const res = await api.post(`subscription`, data);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response);
	}
});

export const sendNotifycationService = createAsyncThunk(
	'sendNotifycationService',
	async (data, { rejectWithValue }) => {
		try {
			const { id, date, url } = data;
			const rs = { date, url };
			const res = await api.post(`subscription/${id}`, rs);
			return res;
		} catch (error) {
			return rejectWithValue(error?.response);
		}
	}
);
