import React, { useEffect, useState } from 'react';
import { PayRules, UserRules } from '../../constants';
import { useSelector } from 'react-redux';
import { formatUSD, formatVND } from '../../commons/formatCost';

const TotalTable = ({ data }) => {
	const user = useSelector((state) => state.auth.user);
	const dataIsPay = data?.filter((item) => {
		return item?.status === PayRules.STATUS.PAID;
	});
	const dataIsUnPay = data?.filter((item) => {
		return item?.status === PayRules.STATUS.UNPAID;
	});
	const [pay, setPay] = useState(0);
	const [unPaid, setUnPaid] = useState(0);

	useEffect(() => {
		let countPaid = 0;
		for (let i = 0; i < dataIsPay?.length; i++) {
			countPaid += parseInt(dataIsPay[i]?.pay_amount_variable);
		}
		setPay(countPaid);

		let countUnPaid = 0;
		for (let i = 0; i < dataIsUnPay?.length; i++) {
			countUnPaid += parseInt(dataIsUnPay[i]?.pay_amount_variable);
		}
		setUnPaid(countUnPaid);
	}, [dataIsPay, dataIsUnPay]);

	return (
		<div className='total__table grid paymment__container'>
			<div className='col-6 md:col-2 total__block'>
				<div>
					<p className='total__title'>Tổng công việc</p>
					<p className='total__count'>{data?.length}</p>
				</div>
			</div>
			<div className='col-6 md:col-2 total__block'>
				<div>
					<p className='total__title'>Đã thanh toán</p>
					<p className='total__count'>{dataIsPay?.length}</p>
				</div>
			</div>
			<div className='col-6 md:col-2 total__block'>
				<div>
					<p className='total__title'>Chưa thanh toán </p>
					<p className='total__count'>{dataIsUnPay?.length}</p>
				</div>
			</div>
			<div className='col-6 md:col-2 total__block'>
				<div>
					<p className='total__title'>Tiền chưa thanh toán</p>
					<p className='total__count'>
						{user?.data?.role === UserRules.ROLE.EDITOR || user?.data?.role === UserRules.ROLE.LEADER_EDITOR
							? formatVND(unPaid)
							: formatUSD(unPaid)}
					</p>
				</div>
			</div>
			<div className='col-6 md:col-2 total__block'>
				<div>
					<p className='total__title'>Tiền đã thanh toán</p>
					<p className='total__count'>
						{user?.data?.role === UserRules.ROLE.EDITOR || user?.data?.role === UserRules.ROLE.LEADER_EDITOR
							? formatVND(pay)
							: formatUSD(pay)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default TotalTable;
