export const auth = {
	set: (token) => {
		localStorage.setItem('access_token', JSON.stringify(token));
	},
	get: () => {
		const user = localStorage.getItem('access_token');
		if (user) return user;
	},
	role: () => {
		const role = localStorage.getItem('role');
		if (role) return role;
	},
	remove: () => {
		localStorage.removeItem('access_token');
	},
	getId: () => {
		const user = localStorage.getItem('access_token');
		if (user) return JSON.parse(user).user._id;
	},
	getUser: () => {
		const user = localStorage.getItem('access_token');
		if (user) return JSON.parse(user).user;
	},
};
