import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { navButtons } from './navButtons';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkIsActive } from '../../../libs/muiDrawer';
import { useSelector } from 'react-redux';
import { resetFilter } from '../../../redux/auth/authSlice';
import { useDispatch } from 'react-redux';

const Navigation = ({ open, getBtnNavIsOpen }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const [activeRoute, setActiveRoute] = useState([]);
	const user = useSelector((state) => state?.auth?.user);

	const handleRedirect = (isRederect, route, indexElParent, item) => {
		dispatch(resetFilter());
		if (isRederect && route) {
			navigate(route);
			setActiveRoute([...activeRoute, indexElParent]);
			dispatch(resetFilter(true));
		} else {
			if (!activeRoute.includes(indexElParent)) {
				setActiveRoute([...activeRoute, indexElParent]);
			} else {
				const newArr = activeRoute.filter((item) => item !== indexElParent);
				setActiveRoute(newArr);
			}
		}
		if ((open && !item) || item?.name_image[0] === 'setting') {
			getBtnNavIsOpen(false);
		} else {
			getBtnNavIsOpen(true);
		}
	};

	const showNavChild = (elements) => {
		return elements.map((item, index) => {
			return (
				<ListItemButton
					key={index}
					onClick={() => handleRedirect(item.isRederect, item.route)}
					className={`nav_btnChild ${location.pathname === item.route && 'active'}`}
				>
					<p key={index} className='nav__name--childNav'>
						{item.name}
					</p>
				</ListItemButton>
			);
		});
	};
	return (
		<>
			<List>
				{navButtons.map((item, index) => (
					<ListItem
						key={index}
						disablePadding
						sx={{ display: 'block' }}
						className={`
						${checkIsActive(item.navChild, location.pathname) && !open && 'nav__icon-active'} 
						${item.name_image[0] === 'setting' && 'absolute bottom-0'}
						`}
					>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
							className={`nav__button 
                                    ${!item.haveModal && open && 'nav__btn-active '} 
                                    ${!item.role.includes(user?.data?.role) && 'hidden'}
                                `}
							onClick={() => handleRedirect(item.isRederect, item.route, index, item)}
						>
							<img
								src={`../../images/${
									checkIsActive(item.navChild, location.pathname)
										? item.name_image[1]
										: item.name_image[0]
								}.svg`}
								alt=''
								className='nav__icon '
							/>
							<ListItemText
								primary={item.name}
								sx={{ opacity: open ? 1 : 0 }}
								className={`nav__name 
										${open ? 'enable' : 'disabled'}
										${!item.isRederect && !item.haveModal && item.name !== 'Cài đặt' && !activeRoute.includes(index) && 'icon_arrow-up'}
                                            ${
												activeRoute.includes(index) &&
												item.name !== 'Cài đặt' &&
												'icon_arrow-down'
											}
                                        `}
							/>
						</ListItemButton>
						{open && !item.isisRederect && item.navChild && (
							<div
								className={`nav__container--nav-child 
                    ${activeRoute.includes(index) && 'active'}
                    `}
							>
								{item.name_image[0] !== 'setting' && showNavChild(item.navChild)}
							</div>
						)}
					</ListItem>
				))}
			</List>
		</>
	);
};

export default Navigation;
