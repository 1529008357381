import React, { useEffect, useState } from 'react';

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { addEmployeeRequest } from '../../redux/overviewEmployee/actionEmployee';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import copy from 'copy-to-clipboard';
import { role } from './dropDown';
import { EMAIL_REGEX, PHONE_REGEX } from '../../constants';
import { setIsOpenModalCreateUser } from '../../redux/modal/modalSlice';
import { overlay } from '../../commons/overlay';
import { UserRules } from '../../constants';
import { InputNumber } from 'primereact/inputnumber';
import { successToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import { validate, dateAddMoreHours } from '../../commons/support';

const CreateUser = () => {
	let maxDate = new Date();
	const dispatch = useDispatch();
	const [createSuccess, setCreateSuccess] = useState(true);
	const isOpenCreateUser = useSelector((state) => state.modal.isOpenModalCreateUser);
	const [defaultValues] = useState({
		fullname: '',
		username: '',
		password: '',
		births: null,
		start_day: null,
		phone: '',
		email: '',
		role: '',
		address: '',
		infor_reminder: '',
		kpi_saler: '',
	});
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm({ defaultValues });
	const user = useSelector((state) => state.auth?.user);
	const employee = useSelector((state) => state.employee?.user);
	const randomPass = Math.random().toString(36).slice(-8);
	const [password, setPassword] = useState(randomPass);
	const roleSelected = watch()?.role?.code;

	useEffect(() => {
		if (isOpenCreateUser) {
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [isOpenCreateUser]);

	useEffect(() => {
		if (password) {
			setValue('password', password);
		}
	}, [password, setValue]);

	const onSubmit = (data) => {
		data.start_day = dateAddMoreHours(data.start_day);
		data.births = dateAddMoreHours(data.births);
		if (Object.keys(errors).length === 0) {
			if (data?.role?.code !== UserRules.ROLE.SALER) {
				delete data.kpi_saler;
			}
			data.create_by = user?.data.id_system;
			data.role = data.role?.code;
			data.password = password;
			dispatch(addEmployeeRequest(data));
		}
	};

	useEffect(() => {
		if (employee?.data && !employee?.error) {
			setCreateSuccess(false);
		}
		if (employee?.error) {
			setCreateSuccess(true);
		}
	}, [employee, reset, dispatch]);

	const copyToClipboard = (type) => {
		if (type === 'password') {
			copy(password);
		} else {
			copy(watch().username);
		}
		successToast(TOAST_MESSAGE.COPY);
	};

	const handleCloseModal = React.useCallback(() => {
		dispatch(setIsOpenModalCreateUser(false));
		reset();
		setCreateSuccess(true);
		setPassword(randomPass);
	}, [dispatch, reset]);

	return (
		<>
			<Sidebar visible={isOpenCreateUser} position='right' onHide={handleCloseModal} className='create__job'>
				<div className='creat__job'>
					<div
						className='create__job--title flex align-items-center justify-content-between'
						onClick={() => {
							dispatch(setIsOpenModalCreateUser(false));
							reset();
							setCreateSuccess(true);
						}}
					>
						<h2 className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							Tạo nhân viên mới
						</h2>
						<div
							className='flex align-items-center create__job--remove-job cursor-pointer'
							style={{ color: 'white' }}
							onClick={handleCloseModal}
						>
							x
						</div>
					</div>
					<form
						className=' grid modal__creat--job no_flex'
						autoComplete='off'
						onSubmit={handleSubmit(onSubmit)}
						onKeyDown={(e) => {
							return e.key !== 'Enter';
						}}
					>
						<div className='field col-12 md:col-12 grid pr-0'>
							<div className='field col-12 md:col-12 pr-0'>
								<span>
									<span className='warning'>*</span>Tên nhân viên
								</span>
								<span className=''>
									<Controller
										name='fullname'
										control={control}
										rules={{ required: 'Vui lòng nhập tên nhân viên.', minLength: 6, validate }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='off'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập tên nhân viên'
											/>
										)}
									/>
								</span>
								{errors?.fullname && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.fullname.message}
									</span>
								)}
								{errors?.fullname?.type === 'minLength' && (
									<span className='warning' style={{ fontSize: '12px' }}>
										Tên nhân viên ít nhất 6 ký tự.
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12 pr-0'>
								<span>
									<span className='warning'>*</span>Tên Biệt danh
								</span>
								<span className=''>
									<Controller
										name='infor_reminder'
										control={control}
										rules={{ required: 'Vui lòng nhập biệt danh.', validate }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='disabled'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập tên biệt danh'
											/>
										)}
									/>
								</span>
								{errors?.infor_reminder && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.infor_reminder.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12 pr-0'>
								<span>
									<span className='warning'>*</span>Tên đăng nhập
								</span>
								<span className='relative'>
									<Controller
										name='username'
										control={control}
										rules={{ required: 'Vui lòng nhập tên đăng nhập.', minLength: 6, validate }}
										render={({ field, fieldState }) => (
											<InputText
												onKeyPress={(event) => {
													if (event.key === ' ') {
														event.preventDefault();
													}
												}}
												autoComplete='off'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập tên đăng nhập'
											/>
										)}
									/>
									<img
										src='images/copy.svg'
										alt=''
										label='Bottom Left'
										className='copy__icon absolute copy__name'
										onClick={() => copyToClipboard('name')}
									/>
								</span>
								{errors?.username && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.username.message}
									</span>
								)}
								{errors?.username?.type === 'minLength' && (
									<span className='warning' style={{ fontSize: '12px' }}>
										Tên đăng nhập ít nhất 6 ký tự
									</span>
								)}
							</div>
							<div className='field col-12 md:col-12 pr-0'>
								<span htmlFor='autocomplete'>Mật khẩu mặc định</span>
								<span className='flex justify-content-between relative'>
									<InputText
										readOnly={true}
										id='password'
										name='password'
										defaultValue={password}
										className={'readonly-class disabled'}
									/>
									<img
										src='images/copy.svg'
										alt=''
										label='Bottom Left'
										className='copy__icon absolute copy__pwd'
										onClick={() => copyToClipboard('password')}
									/>
								</span>
							</div>
							<div className='field col-12 md:col-6 create__user--calendar'>
								<span htmlFor='calendar'>
									<span className='warning'>*</span>Ngày tháng năm sinh
								</span>
								<span className='relative'>
									<Controller
										name='births'
										control={control}
										rules={{ required: 'Vui lòng chọn ngày tháng năm sinh.' }}
										render={({ field, fieldState }) => (
											<Calendar
												readOnlyInput
												maxDate={maxDate}
												id={field.name}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												placeholder='dd/mm/yyyy'
											/>
										)}
									/>
									<img src='/images/calendar.svg' alt='' className='calendar__image' />
								</span>
								{errors?.births && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.births.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6 create__user--calendar pr-0'>
								<span htmlFor='calendar'>
									<span className='warning'>*</span>Ngày bắt đầu làm
								</span>
								<span className='relative'>
									<Controller
										name='start_day'
										control={control}
										rules={{ required: 'Vui lòng chọn ngày bắt đầu làm việc.' }}
										render={({ field, fieldState }) => (
											<Calendar
												readOnlyInput
												maxDate={maxDate}
												id={field.name}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												value={field.value}
												onChange={(e) => field.onChange(e.value)}
												placeholder='dd/mm/yyyy'
												hourFormat='12'
											/>
										)}
									/>
									<img src='/images/calendar.svg' alt='' className='calendar__image' />
								</span>
								{errors?.start_day && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.start_day.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6 '>
								<span htmlFor='withoutgrouping'>
									<span className='warning'>*</span>Số điện thoại
								</span>
								<span className=''>
									<Controller
										name='phone'
										control={control}
										rules={{
											required: 'Vui lòng nhập số điện thoại.',
											pattern: { value: PHONE_REGEX },
										}}
										render={({ field, fieldState }) => (
											<InputText
												onKeyPress={(event) => {
													if (!/[0-9]/.test(event.key)) {
														event.preventDefault();
													}
												}}
												autoComplete='disabled'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập số điện thoại'
											/>
										)}
									/>
								</span>
								{errors?.phone && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.phone.message}
									</span>
								)}
								{errors?.phone?.type === 'pattern' && (
									<span className='warning' style={{ fontSize: '12px' }}>
										Số điện thoại không hợp lệ.
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='employees'>
									<span className='warning'>*</span>Chức vụ
								</span>
								<span className=''>
									<Controller
										name='role'
										control={control}
										rules={{ required: 'Vui lòng chọn chức vụ.' }}
										render={({ field, fieldState }) => (
											<Dropdown
												options={role}
												optionLabel='name'
												value={field.value}
												onChange={(e) => {
													field.onChange(e.value);
												}}
												emptyMessage='Không có dữ liệu'
												className={classNames(
													{ 'p-invalid': fieldState.invalid },
													'create__role_type'
												)}
												placeholder='Chọn chức vụ'
											/>
										)}
									/>
								</span>
								{errors?.role && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.role.message}
									</span>
								)}
							</div>
							<div className='field col-12 md:col-6'>
								<span htmlFor='original__link'>
									<span className='warning'>*</span>Email
								</span>
								<span className=''>
									<Controller
										name='email'
										control={control}
										rules={{ required: 'Vui lòng nhập email.', pattern: { value: EMAIL_REGEX } }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='disabled'
												id={field.name}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập email'
											/>
										)}
									/>
								</span>
								{errors?.email && (
									<span className='warning' style={{ fontSize: '12px' }}>
										{errors?.email.message}
									</span>
								)}
								{errors?.email?.type === 'pattern' && (
									<span className='warning' style={{ fontSize: '12px' }}>
										Email không hợp lệ.
									</span>
								)}
							</div>

							<div className='field col-12 md:col-6 pr-0'>
								<span htmlFor='original__link' className=' pb-1 block'>
									Địa chỉ nhân viên
								</span>
								<span className=''>
									<Controller
										name='address'
										control={control}
										rules={{ required: false }}
										render={({ field, fieldState }) => (
											<InputText
												autoComplete='disabled'
												id={field.name}
												value={field.value}
												{...field}
												className={classNames({ 'p-invalid': fieldState.invalid })}
												placeholder='Nhập địa chỉ nhân viên'
											/>
										)}
									/>
								</span>
							</div>
							{roleSelected === UserRules?.ROLE?.SALER && (
								<div className='field col-12 md:col-6'>
									<span htmlFor='original__link'>
										{' '}
										<span className='warning'>*</span>KPI Saler ($)
									</span>
									<span className='p-float-label cursor__normal'></span>
									<span className=''>
										<Controller
											name='kpi_saler'
											control={control}
											rules={{
												required: {
													value: watch('role')?.code === UserRules.ROLE.SALER ? true : false,
													message: 'Vui lòng nhập kpi cho sales.',
												},
											}}
											render={({ field, fieldState }) => (
												<InputNumber
													value={field.value}
													onValueChange={(e) => field.onChange(e.value)}
													mode='currency'
													className={`${classNames({
														'p-invalid': fieldState.invalid,
													})} w-full`}
													placeholder='Nhập kpi sales'
													currency='USD'
													locale='en-US'
													minFractionDigits={0}
												/>
											)}
										/>
									</span>
									{errors?.kpi_saler && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.kpi_saler?.message}
										</span>
									)}
								</div>
							)}
						</div>
						{createSuccess && (
							<div className='btn_modal field col-12 md:col-12 grid position_bottom pr-0'>
								<div className='field col-6 md:col-6'>
									<span className=''>
										<Button
											label='Hủy bỏ'
											className='p-button-outlined cancel--btn'
											type='button'
											onClick={() => {
												dispatch(setIsOpenModalCreateUser(false));
												reset();
												setCreateSuccess(true);
											}}
										/>
									</span>
								</div>
								<div className='field col-6 md:col-6 pr-0'>
									<span className=''>
										<Button
											label='Tạo mới'
											className='p-button-outlined p-button-secondary confirm--btn'
											type='submit'
										/>
									</span>
								</div>
							</div>
						)}
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default CreateUser;
