import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '../../commons/toast';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import ApiService from '../../_services/apiService';

const api = new ApiService();
export const dashboardEmployeeRequest = createAsyncThunk('getEmployee', async (filter, { rejectWithValue }) => {
	try {
		const search = typeof filter === 'string' ? filter : '';
		const res = await api.get(`users/data/dashboard${search}`);
		return res.data;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});

export const addEmployeeRequest = createAsyncThunk('addEmployee', async (data, { rejectWithValue }) => {
	try {
		const res = await api.post('users/create', data);
		successToast(TOAST_MESSAGE.CREATE_STAFF_SUCCESS);
		return res.data;
	} catch (error) {
		errorToast(error?.response?.data?.message);
		return rejectWithValue(error?.response?.data);
	}
});

export const editEmployeeRequest = createAsyncThunk('updateEmployee', async (data, { rejectWithValue }) => {
	try {
		const res = await api.put(`users/${data?.result.id_system}`, data?.result);
		if (res) {
			successToast(TOAST_MESSAGE.UPDATE_SUCCESS);
			res.data_user = data?.result;
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(TOAST_MESSAGE.UPDATE_FAILED);
		return rejectWithValue(error?.response?.result);
	}
});

export const deleteEmployeeRequest = createAsyncThunk('deleteEmployee', async (data, { rejectWithValue }) => {
	try {
		const res = await api.delete(`users/${data.id}`);
		if (res) {
			successToast(TOAST_MESSAGE.DELETE_STAFF_SUCCESS);
			res.index = data?.index;
		}
		return res;
	} catch (error) {
		errorToast(TOAST_MESSAGE.DELETE_STAFF_FAILED);
		return rejectWithValue(error?.response?.result);
	}
});

export const getEmployeeRequest = createAsyncThunk('getDataEmployee', async (data, { rejectWithValue }) => {
	try {
		const res = await api.get(`users/${data.id}`);
		return res;
	} catch (error) {
		return rejectWithValue(error?.response?.data);
	}
});
