import { HEADER_MESSAGES } from '../../commons/Messages/header.message';

export const table_work_flowManager = [
	{
		name: HEADER_MESSAGES.MESSAGE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NO,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.CUSTOMER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.EDITOR,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.BONUS,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.TOTAL_COST,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.DEADLINE,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.STATUS_EDITOR,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.STATUS_JOBS,
		haveSort: true,
	},
];

export const table_dashboard = [
	{
		name: HEADER_MESSAGES.MESSAGE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NO,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.CUSTOMER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.SALER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.BONUS,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.DEADLINE,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.TYPE,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.COUNT_IMAGE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.STATUS,
		haveSort: true,
	},
];

export const table_customer_management = [
	{
		name: HEADER_MESSAGES.NO,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.REMINDER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NAME_CUSTOMER,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.DATE_START_RENT,
		haveSort: true,
	},
];

export const table_payment = [
	{
		name: HEADER_MESSAGES.NO,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.BONUS,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.CUSTOMER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.COUNT_IMAGE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.DATE_CREATED,
		haveSort: true,
	},

	{
		name: HEADER_MESSAGES.STATUS,
		haveSort: true,
	},
];

export const table_payment_managerment = [
	{
		name: HEADER_MESSAGES.MONTH,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NAME_STAFF,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.POSITION,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.COUNT_IMAGE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.PAYMENT,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.STATUS,
		haveSort: true,
	},
];

export const table_jobs_overview = [
	{
		name: HEADER_MESSAGES.MESSAGE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NO,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.CUSTOMER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.SALER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.EDITOR,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.INITIAL_EXPENSES,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.DATE_CREATED,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.DEADLINE,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.STATUS,
		haveSort: true,
	},
];

export const table_employee_overview = [
	{
		name: HEADER_MESSAGES.REMINDER,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NAME_STAFF,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.POSITION,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.DATE_START_WORKING,
		haveSort: true,
	},
	{
		name: HEADER_MESSAGES.STATUS,
		haveSort: true,
	},
];

export const table_total_work_flowManager = [
	{
		name: HEADER_MESSAGES.TOTAL_WORK,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.DONE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.BONUS,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.KPI,
		haveSort: false,
	},
];

export const table_personal_information = [
	{
		name: HEADER_MESSAGES.PAYMENT,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NAME_BANK,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NUMBER_BANK,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.NAME_ACCOUNT_HOLDER,
		haveSort: false,
	},
];

export const table_performance = [
	{
		name: HEADER_MESSAGES.PENDING,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.REQUEST,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.DONE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.REVENUE,
		unit: '(Vnd)',
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.BONUS,
		unit: '(Vnd)',
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.KPI,
		unit: '(%)',
		haveSort: false,
	},
];

export const table_job_performance = [
	{
		name: HEADER_MESSAGES.NO,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.STAFF,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.REQUESTING,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.REQUEST,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.DONE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.REVENUE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.BONUS_COMPLETE,
		haveSort: false,
	},
	{
		name: HEADER_MESSAGES.KPI,
		haveSort: false,
	},
];
