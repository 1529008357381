import React, { useEffect, useState } from 'react';
import './App.scss';
import { routes } from './routes/routes';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './modules/layout/Header';
import {
	useSelector,
	// useDispatch
} from 'react-redux';
import Login from './modules/auth/Login';
import ForgotPassword from './modules/auth/ForgotPassword';
import ResetPassword from './modules/auth/ResetPassword';
import { Navigate } from 'react-router';
import BtnMess from './modules/layout/messages/BtnMess';
import Modal from './modules/modal/Modal';
import 'primeicons/primeicons.css';
import Loader from './commons/loader';
import { URL_ROUTER } from './routes/routes';
import { addLocale, locale } from 'primereact/api';
import jwt_decode from 'jwt-decode';
import Notfound from './404';

function App() {
	const user = useSelector((state) => state.auth.token);
	const decodedToken = () => {
		if (user?.data?.access_token) {
			const tokenDecode = jwt_decode(user?.data?.access_token);
			return tokenDecode.role;
		}
		return '';
	};
	const profile = useSelector((state) => state.auth?.user);
	const role = profile?.data?.role || decodedToken();
	const [userIsAuth, setUserIsAuth] = useState(false);

	const notFound = () => {
		return <Notfound />;
	};

	locale('vi');
	addLocale('vi', {
		dateFormat: 'dd/mm/yy',
		firstDayOfWeek: 1,
		dayNames: ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'],
		dayNamesShort: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
		dayNamesMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
		monthNames: [
			'tháng 1',
			'tháng 2',
			'tháng 3',
			'tháng 4',
			'tháng 5',
			'tháng 6',
			'tháng 7',
			'tháng 8',
			'tháng 9',
			'tháng 10',
			'tháng 11',
			'tháng 12',
		],
		monthNamesShort: [
			'thg 1',
			'thg 2',
			'thg 3',
			'thg 4',
			'thg 5',
			'thg 6',
			'thg 7',
			'thg 8',
			'thg 9',
			'thg 10',
			'thg 11',
			'thg 12',
		],
	});

	useEffect(() => {
		setTimeout(() => {
			setUserIsAuth(user?.isAuth);
		}, 300);
	}, [user?.isAuth]);

	const logoutWorker = new Worker('./logoutWorker.js');
	logoutWorker.onmessage = function (ev) {
		if (ev && ev.data === 'wakeup') {
			logoutWorker.terminate();
			window.location.reload();
		}
	};

	return (
		<BrowserRouter>
			{user?.isAuth && <Header />}
			<Routes>
				{user?.isAuth ? (
					routes.map((route, index) => (
						<Route
							key={index}
							path={route.path}
							element={route?.role.includes(role) ? <route.main /> : notFound()}
						/>
					))
				) : (
					<>
						<Route path='*' element={<Navigate to={URL_ROUTER.LOGIN} replace />} />
					</>
				)}
				<Route path={URL_ROUTER.LOGIN} element={<Login />} />
				<Route path={URL_ROUTER.FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route path={URL_ROUTER.RESET_PASSWORD} element={<ResetPassword />} />
				<Route path={URL_ROUTER.NOT_FOUND} element={<Notfound />} />
			</Routes>
			{userIsAuth && (
				<>
					<Modal />
					<BtnMess />
				</>
			)}
			<Loader />
		</BrowserRouter>
	);
}

export default App;
