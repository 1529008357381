import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Analysis from './Analysis';
import { useSelector } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { UserRules } from '../../../../constants';
import { formatUSD } from '../../../../commons/formatCost';
const TableTotal = ({ setDateWorkFlow, dateWorkFlow }) => {
	const dataParse = (data) => {
		if (data) {
			const rs = {
				job_pending: data.job_pending,
				job_incomplete: data.job_incomplete,
				job_complete: data.job_complete,
				cost_jobs: data.cost_jobs,
				bonus: data.bonus,
				kpi: data.kpi,
			};
			return rs;
		} else {
			return null;
		}
	};

	const performance = useSelector((state) => state.performanceReducer.employeePerformance);
	const dataTable = dataParse(performance?.data);
	const user = useSelector((state) => state.auth.user);
	const headerTable = (name) => {
		return (
			<div className=''>
				<span className=''>{name}</span>
			</div>
		);
	};

	const bodyTable = (name) => {
		return (
			<div>
				<span className='table__body-name'>{name}</span>
			</div>
		);
	};
	const bodyTablePrice = (price) => {
		return (
			<div>
				<span className='table__body-name'>{formatUSD(price)}</span>
			</div>
		);
	};
	return (
		<div className='grid table__container table-pc'>
			<div className='table__analysis field col-12 md:col-8 mt-0 mb-0 pb-0 flex flex-column justify-content-between'>
				<p className='chart__title mb-3'>Thống kê công việc</p>
				<Calendar
					id=''
					className='w-4 mb-2'
					value={dateWorkFlow}
					onChange={(e) => setDateWorkFlow(e.value)}
					selectionMode='range'
					placeholder='dd/mm/yyyy - dd/mm/yyyy'
				/>
				<DataTable
					value={[dataTable]}
					responsiveLayout='stack'
					breakpoint='1113px'
					className='table__total--workflow--management field col-12 md:col-12 m-0'
				>
					<Column
						body={() => bodyTable(dataTable?.job_incomplete)}
						header={() => headerTable('Chưa hoàn thành')}
						className='p-0'
					/>
					<Column body={() => bodyTable(dataTable?.job_pending)} header={() => headerTable('Đang xử lý')} />
					<Column
						body={() => bodyTable(dataTable?.job_complete)}
						header={() => headerTable('Đã hoàn thành')}
					/>
					<Column body={() => bodyTablePrice(dataTable?.cost_jobs)} header={() => headerTable('Doanh thu')} />
					<Column body={() => bodyTablePrice(dataTable?.bonus)} header={() => headerTable('Bonus')} />
					{user?.data?.role !== UserRules?.ROLE?.ADMIN && (
						<Column body={() => bodyTable(dataTable?.kpi)} header={() => headerTable('KPI')} />
					)}
				</DataTable>
			</div>

			<div className='table__analysis field col-12 md:col-4 mt-0  mb-0 pb-0'>
				<Analysis />
			</div>
		</div>
	);
};

export default TableTotal;
