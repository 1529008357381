import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import copy from 'copy-to-clipboard';
import { setIsOpenInformationJob } from '../../redux/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import {
	UserRules,
	NOT_SET_ADMIN,
	NAME_ROOM,
	JobRules,
	NOTIFICATION_ERROR_INPUT_VAILD,
	LINK_REGEX,
	EDITOR_INVAILD,
} from '../../constants';
import { InputText } from 'primereact/inputtext';
import { dashboardEmployeeRequest } from '../../redux/overviewEmployee/actionEmployee';
import {
	approvedJobsRequest,
	deleteJobsRequest,
	editJobsRequest,
	doneJobsRequest,
	editorEditJobsRequest,
	cancelJobsbyEditorRequest,
} from '../../redux/overviewJobs/actionJobs';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { calPriceJob } from '../../commons/formatCost';
import { itemUserTemplate } from '../modal/TemplateDropDown';
import { overlay } from '../../commons/overlay';
import { getEmployeePerformance } from '../../redux/employeePerformance/action';
import { useLocation } from 'react-router';
import { resetJobCreated, resetJobRequest } from '../../redux/overviewJobs/jobsSlice';
import { successToast, errorToast } from '../../commons/toast';
import { URL_ROUTER } from '../../routes/routes';
import { type_status_jobs } from './dropDown';
import { settingRequest } from '../../redux/admin/action';
import { socket } from '../../_services/socket';
import { classNames } from 'primereact/utils';
import Modal from '../dialog/dialog';
import { TOAST_MESSAGE } from '../../commons/Messages/toast.message';
import { validate } from '../../commons/support';
import { timeISOToDate } from '../../commons/dateTime';
import { formatVND } from '../../commons/formatCost';
const InformationJobs = () => {
	const dispatch = useDispatch();
	let minDate = new Date();
	const [typeFile, setTypeFile] = useState(false);
	const [typeModels, setTypeModels] = useState(false);
	const [typePay, setTypePay] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openModalApproved, setOpenModalApproved] = useState(false);
	const [isOpenNoteEditor, setisOpenNoteEditor] = useState(false);
	const location = useLocation();
	const { pathname } = location;
	const [selectEditor, setSelectEditor] = useState(null);
	const [selectStatus, setSelectStatus] = useState(null);
	const [selectStatusUpdate, setSelectStatusUpdate] = useState(null);
	const [dropdownStatusUpdate, setDropdownStatusUpdate] = useState([]);

	const user = useSelector((state) => state.auth.user);
	const isOpenInformationJob = useSelector((state) => state.modal.isOpenInformationJob);
	const rowdata = useSelector((state) => state.modal?.dataModalInformationJob);
	const deletejobs = useSelector((state) => state.jobs?.deletejobs);
	const updatejobs = useSelector((state) => state.jobs?.editjobs);
	const donejobs = useSelector((state) => state.jobs?.donejobs);
	const setting = useSelector((state) => state.setting?.system);
	const currentUser = useSelector((state) => state.message.currentUser);
	const roleUser = user?.data?.role;

	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	const employees = useSelector((state) => state.employee?.dashboard);

	useEffect(() => {
		if (isOpenInformationJob) {
			dispatch(settingRequest());
			overlay.disable();
		} else {
			overlay.enable();
		}
	}, [dispatch, isOpenInformationJob]);

	useEffect(() => {
		if (rowdata?.data?.reminder_editor && employees.data && isOpenInformationJob) {
			const getEditor = employees.data.find((item) => {
				return item.infor_reminder === rowdata?.data?.reminder_editor;
			});
			if (getEditor) {
				setSelectEditor(getEditor);
				setValue('id_editor', getEditor?.id_system);
			}
		}
		if (
			(roleUser === UserRules.ROLE.LEADER_EDITOR || roleUser === UserRules.ROLE.EDITOR) &&
			isOpenInformationJob &&
			!rowdata?.data?.is_approved_by_editor
		) {
			dispatch(setIsOpenInformationJob(false));
			setOpenModalApproved(true);
		}
	}, [rowdata, isOpenInformationJob, employees.data, setValue]);

	useEffect(() => {
		if (rowdata?.data?.photo_types && setting.data?.type_define_image) {
			for (let item of setting.data.type_define_image) {
				if (item.trim() === rowdata?.data?.photo_types) {
					setTypeFile(item);
					break;
				}
			}
		}
		if (rowdata?.data?.type_models && setting.data?.type_img) {
			for (let item of setting.data.type_img) {
				if (item.trim() === rowdata?.data?.type_models) {
					setTypeModels(item);
					break;
				}
			}
		}
		if (rowdata?.data?.type_pay && setting.data?.type_pay) {
			for (let item of setting.data.type_pay) {
				if (item.trim() === rowdata?.data?.type_pay) {
					setTypePay(item);
					break;
				}
			}
		}

		if (rowdata?.data?.status_jobs) {
			for (let item of type_status_jobs) {
				if (
					item.code.trim() === rowdata?.data?.status_jobs ||
					rowdata?.data?.status_jobs === JobRules.STATUS_JOBS.COMPLETE
				) {
					setSelectStatus(item);
					break;
				}
			}
		}
		let status_fixed = ['Fix'];
		setDropdownStatusUpdate([JobRules.STATUS_JOBS_NAME.COMPLETE, ...status_fixed]);
	}, [rowdata, setting, setValue]);

	useEffect(() => {
		if (deletejobs?.data) {
			if (pathname === URL_ROUTER.WORKFLOW_MANAGEMENT) {
				dispatch(getEmployeePerformance());
			}
			dispatch(setIsOpenInformationJob(false));
		}
	}, [deletejobs]);

	useEffect(() => {
		if (
			isOpenInformationJob &&
			roleUser !== UserRules.ROLE.LEADER_EDITOR &&
			roleUser !== UserRules.ROLE.EDITOR &&
			roleUser !== UserRules.ROLE.SALER
		) {
			let keyword = '?keyword=Editor';
			dispatch(dashboardEmployeeRequest(keyword));
		}
	}, [dispatch, isOpenInformationJob, roleUser, user.data]);

	const handleCloseModal = React.useCallback(() => {
		dispatch(setIsOpenInformationJob(false));
		reset();
		setSelectStatus(null);
		setSelectStatusUpdate(null);
		setSelectEditor(null);
		setValue('note_fixed', '');
	}, [dispatch, setSelectEditor, reset]);

	useEffect(() => {
		if (updatejobs?.data && !updatejobs?.error) {
			handleCloseModal();
			if (pathname === URL_ROUTER.WORKFLOW_MANAGEMENT) {
				dispatch(getEmployeePerformance());
			}
			setTimeout(() => {
				dispatch(resetJobRequest());
			}, 500);
		}

		setTimeout(() => {
			dispatch(resetJobCreated());
		}, 500);
	}, [updatejobs]);

	useEffect(() => {
		if (donejobs?.data && !donejobs?.error) {
			if (pathname === URL_ROUTER.WORKFLOW_MANAGEMENT) {
				dispatch(getEmployeePerformance());
			}
			handleCloseModal();
			setTimeout(() => {
				dispatch(resetJobRequest());
			}, 500);
		}
	}, [donejobs]);

	const handleApprovedJob = () => {
		const formdata = {};
		formdata.id = rowdata?.data?.id_system;
		formdata.index = rowdata?.data?.index - 1;
		dispatch(approvedJobsRequest(formdata));
		setOpenModalApproved(false);
	};

	const handleCancelJobbyEditor = () => {
		const formdata = {};
		formdata.id = rowdata?.data?.id_system;
		formdata.index = rowdata?.data?.index - 1;
		dispatch(cancelJobsbyEditorRequest(formdata));
		setOpenModalApproved(false);
	};

	const handleDeleteJobs = () => {
		const formdata = {};
		formdata.id = rowdata?.data?.id_system;
		formdata.index = rowdata?.data?.index - 1;
		socket.emit('reset-notifications', NAME_ROOM.GROUP + '-' + rowdata?.data?.id_system, currentUser?.id_system);

		dispatch(deleteJobsRequest(formdata));
		setOpenModal(false);
	};

	const copyText = (text) => {
		copy(text);
		successToast(TOAST_MESSAGE.COPY);
	};

	const onSubmit = (data) => {
		const formDataPut = {};

		Object.keys(data).forEach((item) => {
			if (data[item] !== rowdata?.data[item]) {
				Object.assign(formDataPut, { [item]: data[item] });
			}
		});
		if (data?.fixed_link) {
			formDataPut.fixed_link = data?.fixed_link;
		}
		if (Object.keys(formDataPut).length > 0) {
			if ((data.editor_cost && data.id_editor) || roleUser !== UserRules.ROLE.ADMIN) {
				Object.assign(formDataPut, { id_system: rowdata?.data?.id_system });
				const formData = {
					data: data,
					result: formDataPut,
					index: rowdata?.data?.index - 1,
				};

				if (formData?.result?.status_jobs) {
					dispatch(doneJobsRequest(formData));
				} else {
					if (roleUser === UserRules.ROLE.EDITOR || roleUser === UserRules.ROLE.LEADER_EDITOR) {
						dispatch(editorEditJobsRequest(formData));
					} else {
						dispatch(editJobsRequest(formData));
					}
				}
			} else {
				errorToast(EDITOR_INVAILD);
			}
		} else {
			errorToast(NOTIFICATION_ERROR_INPUT_VAILD);
		}
	};

	useEffect(() => {
		if (rowdata?.data) {
			setValue('org_link', rowdata?.data?.org_link);
			setValue('quality_img', rowdata?.data?.quality_img);
			setValue('total_cost', rowdata?.data?.total_cost);
			setValue('editor_cost', rowdata?.data?.editor_cost);
			setValue('work_notes', rowdata?.data?.work_notes);
			setValue('request_content', rowdata?.data?.request_content);
			setValue('detail_content', rowdata?.data?.detail_content);
			setValue('type_pay', rowdata?.data?.type_pay);
			setValue('finished_link', rowdata?.data?.finished_link);
			if (rowdata?.data?.end_day) {
				setValue('end_day', timeISOToDate(rowdata?.data?.end_day));
			}
		}
	}, [rowdata, setValue]);

	const ShowButtonRedirect = (link) => {
		if (link && link.match(LINK_REGEX)) {
			return (
				<button
					className='btn__redirect'
					type='button'
					onClick={() => {
						window.open(link, '_blank');
					}}
				>
					<img src='images/dialog/link_url.svg' alt='' />
				</button>
			);
		}
		return <></>;
	};

	const ShowButtonCoppy = (link) => {
		if (link && link.match(LINK_REGEX)) {
			return (
				<img
					src='images/copy.svg'
					alt='org_link'
					label='Bottom Right'
					onClick={() => copyText(link)}
					className='copy__icon absolute copy__name'
					style={{ right: '50px' }}
				/>
			);
		}
		return <></>;
	};

	const styleCopyBtn = (link) => {
		if (link && link.match(LINK_REGEX)) {
			return { borderRadius: '5px 0 0px 5px' };
		}
		return {};
	};

	return (
		<>
			<Modal
				title='Xoá Công việc'
				content='Bạn có đồng ý xóa công việc này không?'
				isOpen={openModal}
				setIsOpen={setOpenModal}
				nameCancel='Hủy bỏ'
				nameConfirm='Đồng ý'
				handleSubmit={handleDeleteJobs}
				handleCancel={() => setOpenModal(false)}
			/>
			<Modal
				title='Đồng ý nhận Công việc'
				content='Bạn có đồng ý nhận công việc này không?'
				isOpen={openModalApproved}
				setIsOpen={setOpenModalApproved}
				nameCancel='Từ chối'
				nameConfirm='Đồng ý'
				handleSubmit={handleApprovedJob}
				handleCancel={() => {
					handleCancelJobbyEditor();
				}}
				ishiddenCancelButton={true}
			/>
			<Sidebar
				visible={!openModalApproved && isOpenInformationJob}
				position='right'
				onHide={() => {
					handleCloseModal();
				}}
				className='create__job'
			>
				<div className='creat__job'>
					<div
						className='create__job--title flex justify-content-between align-items-center'
						onClick={handleCloseModal}
					>
						<span className='flex align-items-center'>
							<img src='/images/mobile/left.svg' className='modal__back' alt='' />
							<div>
								<h2 className='flex align-items-center'>Thông tin công việc</h2>
								<span className='id_job'>{rowdata?.data?.id_system}</span>
							</div>
						</span>
						<div className='flex align-items-center '>
							{roleUser !== UserRules.ROLE.EDITOR &&
								roleUser !== UserRules.ROLE.LEADER_EDITOR &&
								rowdata?.data &&
								Object?.keys(rowdata?.data)?.length > 0 && (
									<img
										src='images/trash.svg'
										alt=''
										className='image__trash'
										onClick={() => setOpenModal(true)}
									/>
								)}
						</div>
					</div>
					<form className=' grid modal__creat--job no_flex pr-2' onSubmit={handleSubmit(onSubmit)}>
						{rowdata?.data && Object?.keys(rowdata?.data).length === 0 ? (
							<span className='notfound'>Thông tin công việc không tồn tại.</span>
						) : (
							<div className='field col-12 md:col-12 grid pr-0'>
								<div className='field col-12 md:col-6 create__job--calendar'>
									<span htmlFor='start_day' className='no_warning'>
										Ngày tạo công việc
									</span>
									<span className='p-float-label cursor__normal font-bold'>
										<Calendar
											value={new Date(rowdata?.data?.start_day)}
											disabled={true}
											className='disable'
										/>
									</span>
								</div>
								<div className='field col-12 md:col-6 create__job--calendar'>
									<span htmlFor='end_day'>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
											roleUser !== UserRules.ROLE.EDITOR && <span className='warning'>*</span>}
										Deadline
									</span>
									<span className={'p-float-label font-bold relative'}>
										<Calendar
											placeholder={`${timeISOToDate(rowdata?.data?.end_day)}`}
											value={watch('end_day')}
											minDate={minDate}
											onChange={(e) => setValue('end_day', e.value)}
											className={`	
												${roleUser === UserRules.ROLE.LEADER_EDITOR || (roleUser === UserRules.ROLE.EDITOR && 'disable')}
												`}
											disabled={
												roleUser === UserRules.ROLE.LEADER_EDITOR ||
												roleUser === UserRules.ROLE.EDITOR
													? true
													: false
											}
										/>
										<img
											src='/images/calendar.svg'
											alt=''
											className='calendar__image calendar__image--information'
										/>
									</span>
								</div>
								<div className='field col-12 md:col-6 '>
									<span htmlFor='quality_img'>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
											roleUser !== UserRules.ROLE.EDITOR && <span className='warning'>*</span>}
										Số lượng
									</span>
									<span className='p-float-label'>
										<Controller
											name='quality_img'
											control={control}
											rules={{
												required:
													roleUser !== UserRules.ROLE.EDITOR &&
													roleUser !== UserRules.ROLE.LEADER_EDITOR
														? 'Vui lòng nhập số lượng.'
														: false,
											}}
											render={({ field, fieldState }) => (
												<InputNumber
													value={rowdata?.data?.quality_img}
													onChange={(e) => field.onChange(e.value)}
													mode='decimal'
													min={1}
													className={classNames(
														{ 'p-invalid': fieldState.invalid },
														roleUser === UserRules.ROLE.LEADER_EDITOR && 'disable',
														roleUser === UserRules.ROLE.EDITOR && 'disable'
													)}
													disabled={
														roleUser === UserRules.ROLE.LEADER_EDITOR ||
														roleUser === UserRules.ROLE.EDITOR
															? true
															: false
													}
													placeholder='Số lượng'
												/>
											)}
										/>
									</span>
									{errors?.quality_img && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.quality_img.message}
										</span>
									)}
								</div>
								<div className='field col-12 md:col-6 '>
									<span htmlFor='type_models'>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
											roleUser !== UserRules.ROLE.EDITOR && <span className='warning'>*</span>}
										Loại ảnh
									</span>
									<span className={'p-float-label '}>
										<Dropdown
											options={setting.data?.type_img}
											defaultValue={typeModels}
											value={typeModels}
											onChange={(e) => {
												setTypeModels(e.value);
												setValue('type_models', e.value);
											}}
											className={`
													w-full
													${errors?.type_models ? 'p-invalid' : ''}
													${roleUser === UserRules.ROLE.LEADER_EDITOR && 'disable'}
													${roleUser === UserRules.ROLE.EDITOR && 'disable'}
												`}
											disabled={
												roleUser === UserRules.ROLE.LEADER_EDITOR ||
												roleUser === UserRules.ROLE.EDITOR
													? true
													: false
											}
											placeholder='Chọn ảnh'
										/>
									</span>
								</div>

								<div className='field col-12 md:col-6'>
									<span htmlFor='photo_types'>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
											roleUser !== UserRules.ROLE.EDITOR && <span className='warning'>*</span>}
										Định dạng file
									</span>
									<span>
										<Dropdown
											options={setting.data?.type_define_image}
											defaultValue={typeFile}
											value={typeFile}
											onChange={(e) => {
												setTypeFile(e.value);
												setValue('photo_types', e.value);
											}}
											className={`
												${roleUser === UserRules.ROLE.LEADER_EDITOR && 'disable'}
												${roleUser === UserRules.ROLE.EDITOR && 'disable'}
											`}
											disabled={
												roleUser === UserRules.ROLE.EDITOR ||
												roleUser === UserRules.ROLE.LEADER_EDITOR
													? true
													: false
											}
										/>
									</span>
								</div>
								{roleUser === UserRules.ROLE.ADMIN && (
									<div className='field col-12 md:col-6'>
										<span htmlFor='id_editor'>
											<span className='warning'>*</span>Editor
										</span>
										<span className={' p-float-label'}>
											<Dropdown
												options={employees.data}
												optionLabel='fullname'
												value={selectEditor}
												itemTemplate={itemUserTemplate}
												onChange={(e) => {
													setValue('id_editor', e.value?.id_system);
													setSelectEditor(e.value);
												}}
												className={`
													w-full
													${errors?.id_editor ? 'p-invalid' : ''}
												`}
												disabled={
													roleUser === UserRules.ROLE.EDITOR ||
													roleUser === UserRules.ROLE.LEADER_EDITOR
														? true
														: false
												}
											/>
										</span>
									</div>
								)}
								<div className='field create__job--calendar col-12 md:col-6 '>
									<span htmlFor='org_link'>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
											roleUser !== UserRules.ROLE.EDITOR && <span className='warning'>*</span>}
										Link ảnh gốc
									</span>
									<span className='flex'>
										<Controller
											control={control}
											name='org_link'
											rules={{
												required:
													roleUser !== UserRules.ROLE.EDITOR ||
													roleUser !== UserRules.ROLE.LEADER_EDITOR
														? 'Vui lòng nhập đường dẫn hoàn thành'
														: false,
												validate,
												pattern: { value: LINK_REGEX, message: 'Link ảnh gốc không hợp lệ.' },
											}}
											render={({ field: { onChange, onBlur, value } }) => (
												<>
													<InputText
														onBlur={onBlur}
														onChange={onChange}
														value={value}
														disabled={
															roleUser === UserRules.ROLE.LEADER_EDITOR ||
															roleUser === UserRules.ROLE.EDITOR
																? true
																: false
														}
														style={styleCopyBtn(rowdata?.data?.org_link)}
													/>
												</>
											)}
										/>
										{ShowButtonCoppy(rowdata?.data?.org_link)}
										{ShowButtonRedirect(rowdata?.data?.org_link)}
									</span>

									{errors?.org_link && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.org_link.message}
										</span>
									)}
								</div>
								<div
									className={`
									field create__job--calendar 
									${roleUser !== UserRules.ROLE.ADMIN ? 'col-12 md:col-12' : 'col-12 md:col-6'}
									`}
								>
									<span
										htmlFor='finished_link'
										className={
											roleUser !== UserRules.ROLE.EDITOR &&
											roleUser !== UserRules.ROLE.LEADER_EDITOR
												? 'no_warning'
												: ''
										}
									>
										{(roleUser === UserRules.ROLE.EDITOR ||
											roleUser === UserRules.ROLE.LEADER_EDITOR) && (
											<span className='warning'>*</span>
										)}
										Link ảnh hoàn thành
									</span>
									<span className='flex'>
										<Controller
											name='finished_link'
											control={control}
											rules={{
												required:
													roleUser === UserRules.ROLE.EDITOR ||
													roleUser === UserRules.ROLE.LEADER_EDITOR
														? 'Vui lòng nhập đường dẫn hoàn thành'
														: false,
												validate,
												pattern: {
													value: rowdata?.data?.finished_link !== NOT_SET_ADMIN && LINK_REGEX,
													message: 'Link ảnh hoàn thành không hợp lệ.',
												},
											}}
											render={({ field }) => (
												<>
													<InputText
														id={field.name}
														onChange={(e) => {
															field.onChange(
																e.target.value ? e.target.value : NOT_SET_ADMIN
															);
														}}
														className={errors?.finished_link && 'p-invalid'}
														placeholder='Nhập đường dẫn hoàn thành'
														defaultValue={
															rowdata?.data?.finished_link === NOT_SET_ADMIN
																? ''
																: rowdata?.data?.finished_link
														}
														disabled={
															roleUser !== UserRules.ROLE.EDITOR &&
															roleUser !== UserRules.ROLE.LEADER_EDITOR
																? true
																: false
														}
														style={styleCopyBtn(rowdata?.data?.finished_link)}
													/>
													{ShowButtonCoppy(rowdata?.data?.finished_link)}
													{ShowButtonRedirect(rowdata?.data?.finished_link)}
												</>
											)}
										/>
									</span>
									{errors?.finished_link && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.finished_link.message}
										</span>
									)}
								</div>
								{roleUser !== UserRules.ROLE.LEADER_EDITOR && roleUser !== UserRules.ROLE.EDITOR && (
									<>
										<div className='field col-12 md:col-6'>
											<span className='mb-1'>
												<span className='warning'>*</span>Chi phí ban đầu ($)
											</span>
											<span className={'p-float-label'}>
												<Controller
													name='total_cost'
													control={control}
													rules={{
														required:
															roleUser === UserRules.ROLE.ADMIN
																? 'Vui lòng nhập chi phí ban đầu.'
																: false,
													}}
													render={({ field, fieldState }) => (
														<InputNumber
															value={rowdata?.data?.total_cost}
															onChange={(e) => field.onChange(e.value)}
															mode='decimal'
															min={1}
															minFractionDigits={2}
															maxFractionDigits={5}
															className={classNames(
																{ 'p-invalid': fieldState.invalid },
																'w-full',
																errors?.total_cost && 'p-invalid'
															)}
															disabled={
																roleUser === UserRules.ROLE.LEADER_EDITOR ||
																roleUser === UserRules.ROLE.EDITOR
																	? true
																	: false
															}
															placeholder='Nhập chi phí'
														/>
													)}
												/>
											</span>
										</div>
										{errors?.total_cost && (
											<span className='warning' style={{ fontSize: '12px' }}>
												{errors?.total_cost.message}
											</span>
										)}
									</>
								)}
								{roleUser !== UserRules.ROLE.SALER && (
									<div
										className={`
									field 
									${
										roleUser === UserRules.ROLE.LEADER_EDITOR || roleUser === UserRules.ROLE.EDITOR
											? 'col-12 md:col-12'
											: 'col-12 md:col-6'
									}
									`}
									>
										<span className='mb-1'>
											{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
												roleUser !== UserRules.ROLE.EDITOR && (
													<span className='warning'>*</span>
												)}
											Chi phí Editor (VND)
										</span>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
										roleUser !== UserRules.ROLE.EDITOR ? (
											<span className={'p-float-label '}>
												<Controller
													name='editor_cost'
													control={control}
													rules={{
														required:
															rowdata?.data?.id_editor !== NOT_SET_ADMIN &&
															roleUser !== UserRules.ROLE.EDITOR &&
															roleUser !== UserRules.ROLE.LEADER_EDITOR
																? 'Vui lòng nhập chi phí cho Editor.'
																: false,
													}}
													render={({ field, fieldState }) => (
														<InputNumber
															value={rowdata?.data?.editor_cost}
															onChange={(e) => field.onChange(e.value)}
															mode='decimal'
															min={1}
															locale='en-US'
															className={classNames(
																{ 'p-invalid': fieldState.invalid },
																'w-full',
																errors?.editor_cost && 'p-invalid'
															)}
															disabled={
																roleUser === UserRules.ROLE.LEADER_EDITOR ||
																roleUser === UserRules.ROLE.EDITOR
																	? true
																	: false
															}
															placeholder='Nhập chi phí'
														/>
													)}
												/>
												{errors?.editor_cost && (
													<span className='warning' style={{ fontSize: '12px' }}>
														{errors?.editor_cost.message}
													</span>
												)}
											</span>
										) : (
											<span className='p-float-label mt-3 cursor__normal'>
												<span className='font-bold'>
													{formatVND(rowdata?.data?.editor_cost ?? 0)}
												</span>
											</span>
										)}
									</div>
								)}
								{roleUser !== UserRules.ROLE.LEADER_EDITOR && roleUser !== UserRules.ROLE.EDITOR && (
									<div className='field col-12 md:col-6'>
										<span htmlFor='saler_cost'>Chi phí Saler ($)</span>
										<span className='p-float-label mt-3 cursor__normal'>
											<span className='font-bold'>
												{rowdata?.data?.total_cost &&
													calPriceJob(rowdata?.data, UserRules.ROLE.SALER)}
											</span>
										</span>
									</div>
								)}
								{roleUser === UserRules.ROLE.ADMIN && (
									<div className='field col-12 md:col-6'>
										<span htmlFor='saler_cost'>Lợi nhuận ($)</span>
										<span className='p-float-label mt-3 cursor__normal'>
											<span className='font-bold'>
												{rowdata?.data?.total_cost &&
													calPriceJob(rowdata?.data, UserRules.ROLE.ADMIN)}
											</span>
										</span>
									</div>
								)}
								{roleUser !== UserRules.ROLE.LEADER_EDITOR && roleUser !== UserRules.ROLE.EDITOR && (
									<div className='field col-12 md:col-6'>
										<span htmlFor='type_pay' className='no_warning'>
											Tiến trình thanh toán KH
										</span>
										<span className='p-float-label '>
											<Dropdown
												options={setting.data?.type_pay}
												defaultValue={typePay}
												value={typePay}
												onChange={(e) => {
													setTypePay(e.value);
													setValue('type_pay', e.value);
												}}
												placeholder='Chọn TTTT'
												disabled={
													roleUser === UserRules.ROLE.EDITOR &&
													roleUser === UserRules.ROLE.LEADER_EDITOR
														? true
														: false
												}
											/>
										</span>
									</div>
								)}

								{roleUser !== UserRules.ROLE.LEADER_EDITOR && roleUser !== UserRules.ROLE.EDITOR && (
									<div className='field col-12 md:col-6'>
										<span htmlFor='status_job' className='no_warning'>
											Trạng thái công việc
										</span>
										<span className='p-float-label '>
											<Dropdown
												options={type_status_jobs}
												optionLabel={'name'}
												value={selectStatus}
												onChange={(e) => {
													setValue('status_jobs', e.value.code);
													setSelectStatus(e.value);
												}}
												placeholder='Trạng thái công việc'
												disabled={
													rowdata?.data?.finished_link === NOT_SET_ADMIN ||
													(roleUser === UserRules.ROLE.LEADER_EDITOR &&
														roleUser === UserRules.ROLE.EDITOR)
														? true
														: false
												}
											/>
										</span>
									</div>
								)}

								{(roleUser === UserRules.ROLE.ADMIN || roleUser === UserRules.ROLE.SALER) && (
									<div className='field col-12 md:col-12 mb-2'>
										<span htmlFor='request_content ' className='pb-2'>
											{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
												roleUser !== UserRules.ROLE.EDITOR && (
													<span className='warning'>*</span>
												)}
											Nội dung yêu cầu
										</span>
										<Controller
											name='request_content'
											control={control}
											rules={{
												required:
													roleUser !== UserRules.ROLE.EDITOR &&
													roleUser !== UserRules.ROLE.LEADER_EDITOR
														? 'Vui lòng nhập nội dung yêu cầu.'
														: false,
												validate,
											}}
											render={({ field, fieldState }) => (
												<InputTextarea
													autoResize
													id={field.name}
													onChange={(e) => {
														field.onChange(e.target.value);
													}}
													className={classNames(
														{ 'p-invalid': fieldState.invalid },
														'create__job_area',
														'overflow-y-auto',
														roleUser === UserRules.ROLE.LEADER_EDITOR && 'disable',
														roleUser === UserRules.ROLE.EDITOR && 'disable'
													)}
													placeholder='Nhập nội dung yêu cầu'
													defaultValue={rowdata?.data?.request_content}
												/>
											)}
										/>
										{errors?.request_content && (
											<span className='warning' style={{ fontSize: '12px' }}>
												{errors?.request_content.message}
											</span>
										)}
									</div>
								)}

								{(roleUser === UserRules.ROLE.ADMIN ||
									roleUser === UserRules.ROLE.EDITOR ||
									roleUser === UserRules.ROLE.LEADER_EDITOR) && (
									<div className='field col-12 md:col-12 mb-2'>
										<span htmlFor='detail_content ' className='pb-2'>
											Nội dung chi tiết
										</span>
										<Controller
											name='detail_content'
											control={control}
											render={({ field, fieldState }) => (
												<InputTextarea
													autoResize
													id={field.name}
													onChange={(e) => {
														field.onChange(e.target.value);
													}}
													className={classNames(
														{ 'p-invalid': fieldState.invalid },
														'create__job_area',
														'overflow-y-auto',
														roleUser === UserRules.ROLE.LEADER_EDITOR ||
															(roleUser === UserRules.ROLE.EDITOR && 'highlight__note')
													)}
													placeholder='Nhập nội dung chi tiết'
													defaultValue={rowdata?.data?.detail_content}
													disabled={roleUser === UserRules.ROLE.ADMIN ? false : true}
												/>
											)}
										/>
									</div>
								)}
								<div className='field col-12 md:col-12 mb-2'>
									<span htmlFor='work_notes' className='pb-2'>
										{roleUser !== UserRules.ROLE.LEADER_EDITOR &&
											roleUser !== UserRules.ROLE.EDITOR && <span className='warning'>*</span>}
										Yêu cầu của khách hàng
									</span>
									<Controller
										name='work_notes'
										control={control}
										rules={{
											required:
												roleUser !== UserRules.ROLE.EDITOR &&
												roleUser !== UserRules.ROLE.LEADER_EDITOR
													? 'Vui lòng nhập lưu ý của khách hàng.'
													: false,
											validate,
										}}
										render={({ field, fieldState }) => (
											<InputTextarea
												autoResize
												id={field.name}
												defaultValue={rowdata?.data?.work_notes}
												className={classNames(
													{ 'p-invalid': fieldState.invalid },
													'create__job_area',
													'overflow-y-auto',
													roleUser === UserRules.ROLE.LEADER_EDITOR ||
														(roleUser === UserRules.ROLE.EDITOR && 'highlight__note')
												)}
												placeholder='Nhập ghi chú của khách hàng'
												onChange={(e) => {
													field.onChange(e.target.value);
												}}
												disabled={
													roleUser === UserRules.ROLE.LEADER_EDITOR ||
													roleUser === UserRules.ROLE.EDITOR
														? true
														: false
												}
											/>
										)}
									/>
									{errors?.work_notes && (
										<span className='warning' style={{ fontSize: '12px' }}>
											{errors?.work_notes.message}
										</span>
									)}
								</div>
								{rowdata?.data?.finished_link !== NOT_SET_ADMIN && (
									<div className='field col-12 md:col-12 '>
										{rowdata?.data?.status_editor_fix?.length > 0 && (
											<div>
												<span htmlFor='status_job' className='no_warning'>
													Lịch sử Fixed
												</span>
												{rowdata?.data?.status_editor_fix?.map((item, index) => {
													return (
														<div key={index}>
															<span
																htmlFor='status_job'
																className='no_warning title_child mt-2 mb-1'
															>
																{(item?.link || item?.link !== NOT_SET_ADMIN) &&
																	(roleUser === UserRules.ROLE.LEADER_EDITOR ||
																		roleUser === UserRules.ROLE.EDITOR) && (
																		<span className='warning'>*</span>
																	)}
																Link Fixed {index + 1}
															</span>
															<div className='relative flex'>
																<Controller
																	name='fixed_link'
																	control={control}
																	rules={{
																		required:
																			roleUser === UserRules.ROLE.EDITOR ||
																			roleUser === UserRules.ROLE.LEADER_EDITOR
																				? 'Vui lòng nhập link fix.'
																				: false,
																		validate,
																		pattern: {
																			value: LINK_REGEX,
																			message: 'Link ảnh fix không hợp lệ.',
																		},
																	}}
																	render={({ fieldState }) => (
																		<>
																			<InputText
																				onChange={(e) => {
																					setValue(
																						'fixed_link',
																						e.target.value
																					);
																				}}
																				defaultValue={
																					item?.link === NOT_SET_ADMIN
																						? ''
																						: item?.link
																				}
																				className={classNames(
																					{
																						'p-invalid':
																							fieldState.invalid &&
																							(!item?.link ||
																								item?.link ===
																									NOT_SET_ADMIN) &&
																							(roleUser ===
																								UserRules.ROLE
																									.LEADER_EDITOR ||
																								roleUser ===
																									UserRules.ROLE
																										.EDITOR),
																					},
																					`${
																						roleUser !==
																							UserRules.ROLE
																								.LEADER_EDITOR &&
																						roleUser !==
																							UserRules.ROLE.EDITOR &&
																						'highlight__note'
																					}`
																				)}
																				placeholder='Nhập đường dẫn fixed'
																				disabled={
																					roleUser !==
																						UserRules.ROLE.EDITOR &&
																					roleUser !==
																						UserRules.ROLE.LEADER_EDITOR
																						? true
																						: item?.link &&
																						  item?.link !== NOT_SET_ADMIN
																						? true
																						: false
																				}
																				style={styleCopyBtn(item?.link)}
																			/>
																			{ShowButtonCoppy(item?.link)}
																			{ShowButtonRedirect(item?.link)}
																		</>
																	)}
																/>
															</div>
															{errors?.fixed_link &&
																(!item?.link || item?.link === NOT_SET_ADMIN) && (
																	<span
																		className='warning'
																		style={{ fontSize: '12px' }}
																	>
																		{errors?.fixed_link.message}
																	</span>
																)}
															<span
																htmlFor='status_job'
																className='no_warning title_child mt-3 mb-1'
															>
																Yêu cầu Fixed {index + 1}
															</span>
															<div>
																<InputTextarea
																	autoResize
																	defaultValue={item.note}
																	className={classNames(
																		'create__job_area',
																		roleUser === UserRules.ROLE.LEADER_EDITOR ||
																			(roleUser === UserRules.ROLE.EDITOR &&
																				'highlight__note'),
																		'reject__note',
																		'overflow-y-auto'
																	)}
																	placeholder='Nhập ghi chú của khách hàng'
																	disabled={
																		roleUser === UserRules.ROLE.LEADER_EDITOR ||
																		roleUser === UserRules.ROLE.EDITOR
																			? true
																			: false
																	}
																/>
															</div>
														</div>
													);
												})}
											</div>
										)}
										{roleUser !== UserRules.ROLE.EDITOR &&
											roleUser !== UserRules.ROLE.LEADER_EDITOR && (
												<div>
													<span
														htmlFor='status_job'
														className='no_warning title_child mt-2 mb-2'
													>
														Trạng thái editor
													</span>
													<Dropdown
														options={dropdownStatusUpdate}
														value={selectStatusUpdate}
														onChange={(e) => {
															setValue(
																'status_jobs_update',
																e.value === JobRules.STATUS_JOBS_NAME.COMPLETE
																	? JobRules.STATUS_JOBS.COMPLETE
																	: e.value
															);
															setSelectStatusUpdate(e.value);
															if (e.value === 'Fix') {
																setisOpenNoteEditor(true);
															} else {
																setisOpenNoteEditor(false);
															}
														}}
														placeholder='Trạng thái công việc'
														disabled={
															rowdata?.data?.finished_link === NOT_SET_ADMIN ||
															(roleUser === UserRules.ROLE.LEADER_EDITOR &&
																roleUser === UserRules.ROLE.EDITOR)
																? true
																: false
														}
													/>
													{isOpenNoteEditor && (
														<>
															<span
																htmlFor='status_job'
																className='no_warning title_child mt-3 mb-1 no_warning'
															>
																Yêu cầu cho Editor
															</span>
															<Controller
																name='note_fixed'
																control={control}
																rules={{
																	required: false,
																}}
																render={({ fieldState }) => (
																	<InputTextarea
																		autoResize
																		className={classNames(
																			{ 'p-invalid': fieldState.invalid },
																			'create__job_area',
																			roleUser === UserRules.ROLE.LEADER_EDITOR ||
																				(roleUser === UserRules.ROLE.EDITOR &&
																					'highlight__note')
																		)}
																		placeholder='Nhập ghi chú fixed cho Editor'
																		onChange={(e) => {
																			setValue('note_fixed', e.target.value);
																		}}
																		disabled={
																			roleUser === UserRules.ROLE.LEADER_EDITOR ||
																			roleUser === UserRules.ROLE.EDITOR
																				? true
																				: false
																		}
																	/>
																)}
															/>
														</>
													)}
												</div>
											)}
									</div>
								)}
							</div>
						)}
						<div className='btn_modal field col-12 md:col-12 grid position_bottom'>
							<div className={`field col-6 md:col-6 `}>
								<span className='p-float-label'>
									<Button
										label='Hủy bỏ'
										className='p-button-outlined cancel--btn'
										type='button'
										onClick={handleCloseModal}
									/>
								</span>
							</div>
							<div className='field col-6 md:col-6 pr-0'>
								<span className='p-float-label'>
									<Button
										label='Cập nhật'
										className='p-button-outlined p-button-secondary confirm--btn'
										type='submit'
									/>
								</span>
							</div>
						</div>
					</form>
				</div>
			</Sidebar>
		</>
	);
};

export default InformationJobs;
